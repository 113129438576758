import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import "./popover";
import { DatePicker } from "./date-picker";
import { DateString } from "@pentacode/openapi/src/units";

@customElement("ptc-date-input")
export class DateInput extends LitElement {
    readonly type = "date";

    @property()
    name: string;

    @property({ type: Boolean, reflect: true })
    disabled: boolean;

    @property({ type: Boolean, reflect: true })
    readonly: boolean;

    @property()
    icon: string;

    @property({ attribute: false })
    min?: DateString;

    @property({ attribute: false })
    max?: DateString;

    @property({ type: Boolean })
    required: boolean;

    @property({ attribute: "disable-year", type: Boolean })
    disableYear = false;

    @property({ attribute: "disable-month", type: Boolean })
    disableMonth = false;

    @property()
    datePicker: "inline" | "popover" | "none" = "none";

    @property()
    displayRangeWith?: string;

    @property({ attribute: false })
    get value(): DateString | null {
        return (this._valueInput && (this._valueInput.value as DateString)) || null;
    }
    set value(val: DateString | null) {
        (async () => {
            if (!this._valueInput) {
                await this.updateComplete;
            }

            if (val) {
                const [year, month, day] = val.split("-");
                this._dayInput.value = day;
                this._monthInput.value = month;
                this._yearInput.value = year;
            } else {
                this._dayInput.value = this._monthInput.value = this._yearInput.value = "";
            }

            this._lastValue = val;
            this._updateValue();
        })();
    }

    @property({ type: Number })
    get day() {
        if (!this._dayInput || !this._dayInput.value) {
            return null;
        }
        const day = Number(this._dayInput.value);
        return isNaN(day) ? null : day;
    }
    set day(day: number | null) {
        this._dayInput.value = day === null ? "" : day.toString().padStart(2, "0");
    }

    @property({ type: Number })
    get month() {
        if (!this._monthInput || !this._monthInput.value) {
            return null;
        }
        const month = Number(this._monthInput.value);
        return isNaN(month) ? null : month;
    }
    set month(month: number | null) {
        this._monthInput.value = month === null ? "" : month.toString().padStart(2, "0");
    }

    @property({ type: Number })
    get year() {
        if (!this._yearInput || !this._yearInput.value) {
            return null;
        }
        const year = Number(this._yearInput.value);
        return isNaN(year) ? null : year;
    }
    set year(year: number | null) {
        this._yearInput.value = year === null ? "" : year.toString().padStart(2, "0");
    }

    @query("input[type='date']")
    private _valueInput: HTMLInputElement;

    @query("input.date-input-day")
    private _dayInput: HTMLInputElement;

    @query("input.date-input-month")
    private _monthInput: HTMLInputElement;

    @query("input.date-input-year")
    private _yearInput: HTMLInputElement;

    @query("ptc-date-picker")
    private _datePicker: DatePicker;

    private get _maxDay() {
        if (!this.year || !this.month) {
            return 31;
        }
        return new Date(this.year, this.month, 0).getDate();
    }

    private _clickHandler = (e: Event) => {
        if (!this._dayInput.value) {
            this._dayInput.focus();
            e.preventDefault();
        }
    };

    private _changeTimeout: ReturnType<typeof setTimeout>;

    private _focusoutHandler = () => {
        clearTimeout(this._changeTimeout);
        this._changeTimeout = setTimeout(() => {
            if (typeof this._lastValue === "undefined" || this._lastValue !== this.value) {
                this.dispatchEvent(new CustomEvent("change", { bubbles: true, composed: true }));
                this._lastValue = this.value;
            }
        }, 50);
    };

    private _focusinHandler = () => {
        clearTimeout(this._changeTimeout);
    };

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("click", this._clickHandler);
        this.addEventListener("focusout", this._focusoutHandler);
        this.addEventListener("focusin", this._focusinHandler);
    }

    disconnectedCallback() {
        super.connectedCallback();
        this.removeEventListener("click", this._clickHandler);
        this.removeEventListener("focusout", this._focusoutHandler);
        this.removeEventListener("focusin", this._focusinHandler);
    }

    focus() {
        this.focusDay();
    }

    async focusDay() {
        if (!this._dayInput) {
            await this.updateComplete;
        }
        this._dayInput.focus();
    }

    async focusMonth() {
        if (!this._monthInput) {
            await this.updateComplete;
        }
        this._monthInput.focus();
    }

    async focusYear() {
        if (!this._yearInput) {
            await this.updateComplete;
        }
        this._yearInput.focus();
    }

    blur() {
        this._dayInput.blur();
        this._monthInput.blur();
        this._yearInput.blur();
    }

    setCustomValidity(val: string) {
        this._valueInput.setCustomValidity(val);
    }

    reportValidity() {
        return this._valueInput.reportValidity();
    }

    private _blurTimeout: number;
    private _lastValue?: string | null;

    private _dayChanged(e: Event) {
        e.stopPropagation();

        if (this.day !== null && (this.day > 3 || this._dayInput.value.length > 1)) {
            this._monthInput.focus();
        }

        if (this.day !== null && this.day > this._maxDay) {
            this._dayInput.value = this._dayInput.value.slice(0, 1);
        }

        this._updateValue();

        this.dispatchEvent(new CustomEvent("input"));
    }

    private _monthChanged(e: Event) {
        e.stopPropagation();

        if (this.month !== null && (this.month > 1 || this._monthInput.value.length > 1)) {
            this._yearInput.focus();
        }

        if (this.month !== null && this.month > 12) {
            this._monthInput.value = this._monthInput.value.slice(0, 1);
        }

        if (this.day !== null && this.day > this._maxDay) {
            this.day = this._maxDay;
        }

        this._updateValue();

        this.dispatchEvent(new CustomEvent("input"));
    }

    private _yearChanged(e: Event) {
        e.stopPropagation();

        if ((this.year !== null && this.year > 3000) || this._yearInput.value.length > 4) {
            this._yearInput.value = this._yearInput.value.slice(0, 4);
        }

        if (this.day !== null && this.day > this._maxDay) {
            this.day = this._maxDay;
        }

        this._updateValue();

        this.dispatchEvent(new CustomEvent("input"));
    }

    private _updateValue() {
        const year = this.year;
        const value =
            this.day === null || this.month === null || year === null || year < 1900 || year > 2100
                ? null
                : (`${year.toString().padStart(4, "0")}-${this.month.toString().padStart(2, "0")}-${this.day
                      .toString()
                      .padStart(2, "0")}` as DateString);
        this._valueInput.value = value || "";
        if (this._datePicker) {
            this._datePicker.value = value;
        }
    }

    private _focus(e: FocusEvent) {
        const input = e.target as HTMLInputElement;

        try {
            input.select();
        } catch (e) {
            input.setSelectionRange(0, input.value.length);
        }

        this.classList.add("focus");
        window.clearTimeout(this._blurTimeout);
    }

    private _blur(e: Event) {
        e.stopPropagation();

        const input = e.target as HTMLInputElement;
        if (input.value === "" || isNaN(Number(input.value))) {
            input.value = "";
        } else {
            if (input === this._yearInput) {
                input.value = input.value.padStart(4, Number(input.value) > 50 ? "1900" : "2000");
            } else {
                input.value = input.value.padStart(2, "0");
            }
        }

        this._updateValue();

        this.dispatchEvent(new CustomEvent("input"));

        this.classList.remove("focus");

        this._blurTimeout = window.setTimeout(() => this.dispatchEvent(new CustomEvent("blur")), 100);
    }

    private _keydown(e: KeyboardEvent) {
        const input = e.target as HTMLInputElement;

        if (["e", ".", ",", "-"].includes(e.key)) {
            e.preventDefault();
            return;
        }

        if (e.key === "Backspace") {
            if (input === this._yearInput && input.value === "") {
                this._monthInput.focus();
                e.preventDefault();
            } else if (input === this._monthInput && input.value === "") {
                this._dayInput.focus();
                e.preventDefault();
            } else if (input === this._dayInput && input.value === "") {
                this.dispatchEvent(new CustomEvent("delete"));
                e.preventDefault();
            }
        }

        if (e.key === "Enter") {
            this.dispatchEvent(new CustomEvent("enter"));
        }

        if (e.key === "Escape") {
            this.dispatchEvent(new CustomEvent("escape"));
        }
    }

    private _datePicked(e: CustomEvent<{ value: DateString }>) {
        this.value = e.detail.value;
        this.dispatchEvent(new CustomEvent("input"));
    }

    static styles = css`
        ptc-date-input {
            display: inline-block;
            border-radius: var(--border-radius);
            border: solid 1px var(--shade-2);
            background: var(--color-bg);
            position: relative;
        }

        ptc-date-input .date-input-inner {
            padding: var(--date-input-padding, 0.6em);
        }

        ptc-date-input.focus {
            border-color: var(--color-primary);
        }

        ptc-date-input input {
            padding: 0;
            border: none;
            background: transparent;
        }

        ptc-date-input .date-input-day {
            text-align: right;
            width: 1.4em;
        }

        ptc-date-input .date-input-month {
            text-align: center;
            width: 1.4em;
        }

        ptc-date-input .date-input-year {
            text-align: left;
            width: 2.6em;
        }

        ptc-date-input i {
            position: relative;
            top: 1px;
        }

        ptc-date-input input[type="date"] {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            pointer-events: none;
        }
    `;

    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            <div class="centering horizontal layout date-input-inner">
                ${this.icon ? html` <i class="${this.icon}"></i> ` : ""}

                <input
                    type="number"
                    min="1"
                    max="31"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    maxlength="2"
                    class="date-input-day"
                    placeholder=" -- "
                    @input=${this._dayChanged}
                    @focus=${this._focus}
                    @blur=${this._blur}
                    @keydown=${this._keydown}
                    @change=${(e: Event) => e.stopPropagation()}
                    ?disabled=${this.disabled}
                    ?readonly=${this.readonly}
                />

                <div>.</div>

                <input
                    type="number"
                    min="1"
                    max="12"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    maxlength="2"
                    class="date-input-month"
                    placeholder=" -- "
                    @input=${this._monthChanged}
                    @focus=${this._focus}
                    @blur=${this._blur}
                    @keydown=${this._keydown}
                    @change=${(e: Event) => e.stopPropagation()}
                    ?disabled=${this.disabled || this.disableMonth}
                    ?readonly=${this.readonly}
                />

                <div>.</div>

                <input
                    type="number"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    maxlength="4"
                    class="date-input-year"
                    placeholder=" ---- "
                    max="2100"
                    @input=${this._yearChanged}
                    @focus=${this._focus}
                    @blur=${this._blur}
                    @keydown=${this._keydown}
                    @change=${(e: Event) => e.stopPropagation()}
                    ?disabled=${this.disabled || this.disableYear}
                    ?readonly=${this.readonly}
                />

                <input
                    type="date"
                    .name=${this.name}
                    .min=${this.min || "1900-01-01"}
                    .max=${this.max || "2100-01-01"}
                    ?required=${this.required}
                    tabindex="-1"
                />
            </div>

            ${this.datePicker === "inline"
                ? html`
                      <ptc-date-picker
                          class="smaller border-top"
                          @change=${this._datePicked}
                          @click=${(e: Event) => e.stopPropagation()}
                          .min=${this.min}
                          .max=${this.max}
                          .displayRangeWith=${this.displayRangeWith}
                      ></ptc-date-picker>
                  `
                : this.datePicker === "popover"
                  ? html`
                        <ptc-popover trigger="focus" style="--popover-hover-buffer: 0px;">
                            <ptc-date-picker
                                class="smaller"
                                @change=${this._datePicked}
                                @click=${(e: Event) => e.stopPropagation()}
                                .min=${this.min}
                                .max=${this.max}
                                .displayRangeWith=${this.displayRangeWith}
                            ></ptc-date-picker>
                        </ptc-popover>
                    `
                  : ""}
        `;
    }
}
