import { Storage } from "@pentacode/core/src/storage";
import { Err, ErrorCode } from "@pentacode/core/src/error";
// @ts-ignore
import localStorage from "localforage/src/localforage";

export class LocalStorage extends Storage {
    async _set(key: string, value: string) {
        await localStorage.setItem(key, value);
    }

    async _get(key: string) {
        const data = (await localStorage.getItem(key)) as string;
        if (!data) {
            throw new Err(ErrorCode.NOT_FOUND);
        }
        return data;
    }

    async _delete(key: string) {
        await localStorage.removeItem(key);
    }

    async clear() {
        await localStorage.clear();
    }
}
