import { html, css } from "lit";
import { customElement } from "lit/decorators.js";
import "emoji-picker-element";
import { EmojiClickEvent } from "emoji-picker-element/shared";
import { Popover } from "./popover";

export { EmojiClickEvent };

const emojiI18N = {
    categories: {
        custom: "Custom",
        "smileys-emotion": "Smileys and emoticons",
        "people-body": "People and body",
        "animals-nature": "Animals and nature",
        "food-drink": "Food and drink",
        "travel-places": "Travel and places",
        activities: "Activities",
        objects: "Objects",
        symbols: "Symbols",
        flags: "Flags",
    },
    categoriesLabel: "Categories",
    emojiUnsupportedMessage: "Your browser does not support color emoji.",
    favoritesLabel: "Favorites",
    loadingMessage: "Loading…",
    networkErrorMessage: "Could not load emoji. Try refreshing.",
    regionLabel: "Emoji picker",
    searchDescription: "When search results are available, press up or down to select and enter to choose.",
    searchLabel: "Suchen...",
    searchResultsLabel: "Suchergebnisse",
    skinToneDescription: "When expanded, press up or down to select and enter to choose.",
    skinToneLabel: "Choose a skin tone (currently {skinTone})",
    skinTones: ["Default", "Light", "Medium-Light", "Medium", "Medium-Dark", "Dark"],
    skinTonesLabel: "Skin tones",
};

@customElement("ptc-emoji-picker")
export class EmojiPicker extends Popover {
    inputElement: HTMLInputElement | HTMLTextAreaElement;

    // @query("emoji-picker-element")
    // private _picker: Picker;

    // private _emojiSelected(e: EmojiClickEvent) {
    //     if (e.detail.unicode) {
    //         insertAtCursor(this._textarea, e.detail.unicode);
    //         this._textarea.dispatchEvent(new Event("input", { bubbles: true, composed: true }));
    //         this._textarea.dispatchEvent(new Event("change", { bubbles: true, composed: true }));
    //     }
    //     this._textarea.focus();
    // }

    static styles = [
        ...Popover.styles,
        css`
            emoji-picker {
                --border-size: 0;
                --emoji-padding: 0.3em;
                --num-columns: 7;
                width: 20em;
                height: 20em;
            }

            :host {
                --hover-buffer: 0;
                padding: 0.5em;
            }
        `,
    ];

    renderContent() {
        return html`
            <emoji-picker
                .locale=${"de"}
                .dataSource=${"https://cdn.jsdelivr.net/npm/emoji-picker-element-data@1.2.0/de/cldr/data.json"}
                .i18n=${emojiI18N}
            ></emoji-picker>
        `;
    }
}
