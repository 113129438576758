const singletons: Record<string, HTMLElement> = {};
let container: HTMLElement;

export function getSingleton(elName: string, lightDom = false) {
    if (!container) {
        container = document.querySelector("[singleton-container]") as HTMLElement;
    }

    let el = singletons[elName];

    if (!el) {
        singletons[elName] = el = document.createElement(elName);
        if (lightDom) {
            container.appendChild(el);
        } else {
            container.shadowRoot!.appendChild(el);
        }
    }

    return el;
}

export function singleton(name: string, lightDom = false) {
    return (prototype: object, propertyName: string) => {
        Object.defineProperty(prototype, propertyName, {
            get() {
                return getSingleton(name, lightDom);
            },
            enumerable: true,
            configurable: true,
        });
    };
}
