import * as marked from "marked";

export interface VersionInfo {
    version: string;
    changes: string;
}

export function getVersion() {
    const rawChangelog = process.env.PTC_CHANGELOG;
    const matchVersion = rawChangelog && rawChangelog.match(/## (\d+\.\d+\.\d+)/);
    return (matchVersion && matchVersion[1]) || "";
}

export const version = getVersion();
export function getChanges(since?: string) {
    const rawChangelog = process.env.PTC_CHANGELOG;

    if (!rawChangelog) {
        return "";
    }
    const changes = rawChangelog.substring(
        rawChangelog.indexOf(`## ${version}`),
        since ? rawChangelog.indexOf(`## ${since}`) : rawChangelog.length
    );

    return marked.parse(changes, { async: false });
}

export function getVersionInfo(since?: string): VersionInfo {
    return {
        version: getVersion(),
        changes: getChanges(since),
    };
}

export const changelog = getChanges();

export function compareVersions(v1: string, v2: string) {
    const [major1, minor1, patch1] = v1.split(".");
    const [major2, minor2, patch2] = v2.split(".");
    return Number(major1) - Number(major2) || Number(minor1) - Number(minor2) || Number(patch1) - Number(patch2);
}
