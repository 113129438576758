import { Location } from "@pentacode/core/src/model";
import { ClientInfo } from "@pentacode/core/src/transport";
import { App } from "@pentacode/core/src/app";
import { dateAdd } from "@pentacode/core/src/util";
import { DateString } from "@pentacode/openapi";

const loaded: Map<string, Promise<any>> = new Map<string, Promise<any>>();

export function loadScript(src: string, global?: string): Promise<any> {
    if (loaded.has(src)) {
        return loaded.get(src)!;
    }

    const s = document.createElement("script");
    s.src = src;
    s.type = "text/javascript";
    const p = new Promise((resolve, reject) => {
        s.onload = () => resolve(global ? window[global as keyof typeof window] : undefined);
        s.onerror = (e: Event) => reject(e);
        document.head.appendChild(s);
    });

    loaded.set(src, p);
    return p;
}

export function saveFile(name: string, type: string, contents: string) {
    return downloadFromUrl(`data:${type};base64,${btoa(unescape(encodeURIComponent(contents)))}`, name);
}

export function downloadFromUrl(url: string, name: string) {
    const a = document.createElement("a");
    a.href = url;
    a.download = name;
    a.rel = "noopener";
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

let clipboardTextArea: HTMLTextAreaElement;

export async function setClipboard(text: string): Promise<void> {
    clipboardTextArea = clipboardTextArea || document.createElement("textarea");
    clipboardTextArea.contentEditable = "true";
    clipboardTextArea.readOnly = false;
    clipboardTextArea.value = text;
    document.body.appendChild(clipboardTextArea);
    const range = document.createRange();
    range.selectNodeContents(clipboardTextArea);

    const s = window.getSelection();
    s!.removeAllRanges();
    s!.addRange(range);
    clipboardTextArea.select();

    clipboardTextArea.setSelectionRange(0, clipboardTextArea.value.length); // A big number, to cover anything that could be inside the element.

    document.execCommand("cut");
    document.body.removeChild(clipboardTextArea);
}

export async function getClipboard(): Promise<string> {
    clipboardTextArea = clipboardTextArea || document.createElement("textarea");
    document.body.appendChild(clipboardTextArea);
    clipboardTextArea.value = "";
    clipboardTextArea.select();
    document.execCommand("paste");
    document.body.removeChild(clipboardTextArea);
    return clipboardTextArea.value;
}

export function getActiveElement(root: DocumentOrShadowRoot = document): Element | null {
    const active = root.activeElement;
    return active?.shadowRoot ? getActiveElement(active.shadowRoot) : active;
}

export function isCursorInInput() {
    const activeElement = getActiveElement();
    return activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement;
}

export function insertAtCursor(input: HTMLInputElement | HTMLTextAreaElement, text: string) {
    if (input.selectionStart || input.selectionStart === 0) {
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        input.value =
            input.value.substring(0, startPos) + text + input.value.substring(endPos || startPos, input.value.length);
        input.selectionStart = startPos + text.length;
        input.selectionEnd = startPos + text.length;
    } else {
        input.value += text;
    }
}

export const isSafari = /apple/i.test(navigator.vendor);

export function readFileAsDataURL(blob: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result as string);
        };

        reader.onerror = (e) => {
            reader.abort();
            reject(e);
        };

        reader.readAsDataURL(blob);
    });
}

export function readFileAsText(blob: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result as string);
        };

        reader.onerror = (e) => {
            reader.abort();
            reject(e);
        };

        reader.readAsText(blob);
    });
}

export function selectFile() {
    return new Promise<File | null>((resolve) => {
        const input = document.createElement("input");
        input.style.opacity = "0";
        input.style.position = "absolute";
        input.type = "file";
        document.body.appendChild(input);
        input.addEventListener(
            "change",
            () => {
                resolve(input.files?.[0] || null);
            },
            { once: true }
        );
        input.click();
        document.body.removeChild(input);
    });
}

export function formatFileSize(size: number = 0) {
    return size < 1e6 ? Math.ceil(size / 10) / 100 + " KB" : Math.ceil(size / 10000) / 100 + " MB";
}

export function getBoundingClientRectWithoutTransforms(el: HTMLElement) {
    let left = 0;
    let top = 0;
    const width = el.offsetWidth;
    const height = el.offsetHeight;

    while (el) {
        left += el.offsetLeft;
        top += el.offsetTop;

        el = el.offsetParent as HTMLElement;
    }

    return { left, top, width, height };
}

export async function unzip(file: ArrayBuffer | Uint8Array | Blob): Promise<ArrayBuffer[]> {
    const JSZip = await (await import("jszip")).default;
    const zip = await JSZip.loadAsync(file);
    const files = Promise.all(
        Object.values(zip.files)
            .filter((file) => !file.dir)
            .map((file) => file.async("arraybuffer"))
    );
    return files;
}

export async function asSinglePDF(files: ArrayBuffer[]) {
    const { PDFDocument } = await import("pdf-lib");

    const pdf = await PDFDocument.create();

    for (const file of files) {
        try {
            const source = await PDFDocument.load(file, { ignoreEncryption: true });
            const pages = await pdf.copyPages(source, source.getPageIndices());
            pages.forEach((page) => pdf.addPage(page));
        } catch (e) {
            console.log(e);
        }
    }

    return pdf.save({});
}

export async function getLocation() {
    return new Promise<Location>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            ({ coords: { latitude, longitude, accuracy } }) => {
                resolve({ latitude, longitude, accuracy });
            },
            (e) => reject(e),
            {
                enableHighAccuracy: true,
            }
        );
    });
}

export async function getLocationStatus(): Promise<ClientInfo["locationStatus"]> {
    if (!("geolocation" in navigator) || !("permissions" in navigator)) {
        return "unavailable";
    }

    const res = await navigator.permissions.query({ name: "geolocation" });

    switch (res.state) {
        case "granted":
        case "denied":
            return res.state;
        default:
            return "available";
    }
}

export function getMonthsForCommittingTimes(app: App, currentMonth: DateString) {
    let initialMonth = app.account?.admin
        ? dateAdd(currentMonth, { years: -2 })
        : app.company?.settings.commitTimeEntriesBefore || dateAdd(currentMonth, { years: -1 });

    const months: DateString[] = [];

    while (initialMonth < currentMonth) {
        initialMonth = dateAdd(initialMonth, { months: 1 });
        months.push(initialMonth);
    }
    return months;
}

/**
 * Triggers a reflow (layout recalculation) on the given element.
 */
export function triggerReflow(el: HTMLElement) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    el.offsetHeight; // accessing offsetHeight triggers a reflow, see https://gist.github.com/paulirish/5d52fb081b3570c81e3a
}
