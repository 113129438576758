import { TimeEntryType } from "./model";

const GermanLocale = {
    states: [
        { name: "Baden-Württemberg", code: "BW" },
        { name: "Bayern", code: "BY" },
        { name: "Augsburg, Bayern", code: "AB" },
        { name: "Berlin", code: "BE" },
        { name: "Brandenburg", code: "BB" },
        { name: "Bremen", code: "HB" },
        { name: "Hamburg", code: "HH" },
        { name: "Hessen", code: "HE" },
        { name: "Mecklenburg-Vorpommern", code: "MV" },
        { name: "Niedersachsen", code: "NI" },
        { name: "Nordrhein-Westfalen", code: "NW" },
        { name: "Rheinland-Pfalz", code: "RP" },
        { name: "Saarland", code: "SL" },
        { name: "Sachsen", code: "SN" },
        { name: "Sachsen-Anhalt", code: "ST" },
        { name: "Schleswig-Holstein", code: "SH" },
        { name: "Thüringen", code: "TH" },
    ] as const,
    timeEntryTypeLabel(type: TimeEntryType): string {
        switch (type) {
            case TimeEntryType.Work:
                return "Arbeit";
            case TimeEntryType.Vacation:
                return "Urlaub";
            case TimeEntryType.Sick:
                return "Krank";
            case TimeEntryType.ChildSick:
                return "Kind Krank";
            case TimeEntryType.SickInKUG:
                return "Krank in KUG";
            case TimeEntryType.School:
                return "Schule";
            case TimeEntryType.HourAdjustment:
                return "Zeitbuchung";
            case TimeEntryType.VacationAdjustment:
                return "Urlaubsbuchung";
            case TimeEntryType.Free:
                return "Frei";
            case TimeEntryType.CompDay:
                return "Guttag";
            default:
                return "";
        }
    },
    timeEntryTypeIcon(type: TimeEntryType): string {
        switch (type) {
            case TimeEntryType.Work:
                return "digging";
            case TimeEntryType.Vacation:
                return "island-tropical";
            case TimeEntryType.Sick:
            case TimeEntryType.SickInKUG:
                return "face-thermometer";
            case TimeEntryType.ChildSick:
                return "baby";
            case TimeEntryType.HourAdjustment:
            case TimeEntryType.VacationAdjustment:
                return "plus-minus";
            case TimeEntryType.Free:
                return "couch";
            case TimeEntryType.CompDay:
                return "gift";
            default:
                return "";
        }
    },
};

const AustrianLocale = {
    states: [
        { name: "Burgenland", code: "AT-1" },
        { name: "Kärnten", code: "AT-2" },
        { name: "Niederösterreich", code: "AT-3" },
        { name: "Oberösterreich", code: "AT-4" },
        { name: "Salzburg", code: "AT-5" },
        { name: "Steiermark", code: "AT-6" },
        { name: "Tirol", code: "AT-7" },
        { name: "Vorarlberg", code: "AT-8" },
        { name: "Wien", code: "AT-9" },
    ] as const,
    timeEntryTypeLabel(type: TimeEntryType): string {
        switch (type) {
            case TimeEntryType.ChildSick:
                return "Pflegeurlaub";
            default:
                return GermanLocale.timeEntryTypeLabel(type);
        }
    },
    timeEntryTypeIcon(type: TimeEntryType): string {
        switch (type) {
            case TimeEntryType.ChildSick:
                return "hand-holding-medical";
            default:
                return GermanLocale.timeEntryTypeIcon(type);
        }
    },
};

export const Localized = {
    DE: GermanLocale,
    AT: AustrianLocale,
};

export type StateCode = (typeof Localized)["DE" | "AT"]["states"][number]["code"];
export type Country = keyof typeof Localized;
