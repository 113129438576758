export const colors: Record<string, string> = {
    red: "#db2828",
    orange: "#f2711c",
    yellow: "#fbbd08",
    olive: "#b5cc18",
    green: "#21ba45",
    teal: "#00b5ad",
    blue: "#2185d0",
    violet: "#6435c9",
    purple: "#a333c8",
    pink: "#e03997",
    brown: "#a5673f",
    grey: "#767676",
    black: "#1b1c1d",
    aqua: "#1cd0bf",
};

export function randomColor(exclude: string[] = []) {
    return (
        Object.values(colors)
            .sort(() => 0.5 - Math.random())
            .find((color) => !exclude.includes(color)) || ""
    );
}

export function getHexColor(color: string | undefined): string | undefined {
    return (color && colors[color]) || color;
}
