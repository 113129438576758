import { css } from "lit";
import { hover, click, ellipsis } from "./mixins";

export const base = css`
    :host {
        font-family: var(--font-family);
        font-size: var(--font-size-default);
        color: var(--color-fg);
    }

    strong {
        font-weight: bold;
    }

    button,
    .button {
        background: var(--color-highlight-bg, var(--shade-2));
        color: var(--color-highlight, currentColor);
        border-radius: var(--border-radius);
        padding: 0.8em;
        box-sizing: border-box;
        font-weight: 600;
        touch-action: manipulation;
        position: relative;
    }

    button:focus-visible,
    .button.focus-visible {
        box-shadow: inset 0 0 0 0.1em var(--color-highlight, var(--color-primary));
    }

    .highlight-fg {
        color: var(--color-highlight);
    }

    .highlight {
        background: var(--color-highlight-bg);
        color: var(--color-highlight);
    }

    a.button {
        display: inline-block;
        text-decoration: none;
    }

    ${hover("button")}
    ${click("button")}

    ${hover(".button")}
    ${click(".button")}

    ${hover(".click")}
    ${click(".click")}

    ${hover(".hover")}

    button.primary,
    .button.primary {
        background: var(--color-highlight, var(--color-primary));
        color: var(--color-fg-dark);
    }

    button.positive,
    .button.positive {
        background: var(--color-positive);
        color: var(--color-fg-dark);
    }

    button.negative,
    .button.negative {
        background: var(--color-negative);
        color: var(--color-fg-dark);
    }

    button.transparent,
    .button.transparent {
        background: transparent;
    }

    button.ghost,
    .button.ghost {
        background: transparent;
        border: solid 1px var(--shade-2);
    }

    button.subtle,
    .button.subtle {
        border: solid 1px;
        padding: 0.5em;
        color: rgba(0, 0, 0, 0.5);
        background: transparent;
    }

    button.subtle:not([active]):hover,
    .button.subtle:not([active]):hover {
        box-shadow: none;
        color: var(--color-highlight, var(--color-primary));
    }

    button[active],
    .button[active] {
        background: var(--color-highlight, var(--color-primary));
        border-color: transparent;
        color: var(--color-bg);
    }

    button.subtle.borderless,
    .button.subtle.borderless {
        border: none;
    }

    button.semislim,
    .button.semislim {
        padding: 0.6em;
    }

    button.slim,
    .button.slim {
        padding: 0.5em;
    }

    button.skinny,
    .button.skinny {
        padding: 0.3em;
    }

    button > i,
    .button > i {
        height: 1em;
        line-height: 1em;
    }

    button.icon,
    .button.icon {
        border-radius: 100%;
        padding: 0.4em;
    }

    input,
    select,
    textarea {
        border-radius: var(--border-radius);
        background: var(--color-bg);
        padding: 0.8em;
        border: solid 1px var(--shade-2);
        padding: 0.6em 0.8em;
        line-height: 1.5em;
        box-sizing: border-box;
        font: inherit;
    }

    select {
        ${ellipsis()};
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        cursor: pointer;
    }

    select.slim,
    input.slim {
        padding: 0.5em;
    }

    select.skinny,
    input.skinny {
        padding: 0.3em;
    }

    select:not(.plain) {
        padding-right: 2em;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='20px'%3E%3Ctext x='0' y='10' %3E%E2%96%BE%3C/text%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 1.5em 1em;
        background-position: right center;
        background-clip: border-box;
        -moz-background-clip: border-box;
        -webkit-background-clip: border-box;
    }

    select.slim:not(.plain) {
        padding-right: 1.7em;
    }

    select.transparent {
        border: none;
        background-color: transparent;
    }

    select:invalid {
        color: #b8b8b8;
    }

    input::placeholder {
        color: currentColor;
        opacity: 0.4;
    }

    input:focus,
    select:focus,
    textarea:focus {
        border-color: var(--color-primary);
    }

    input:focus::placeholder {
        opacity: 0.6;
    }

    input.plain {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
    }

    .input {
        position: relative;
    }

    .input input {
        width: 100%;
    }

    .icon.input > i,
    .icon.input > button {
        top: 0;
        bottom: 0;
        margin: auto 0;
        opacity: 0.9;
        font-size: 90%;
    }

    .icon.input > button {
        height: 2.1em;
    }

    .icon.input i:not(.click) {
        pointer-events: none;
    }

    .left.icon.input input {
        padding-left: 2em;
    }

    .left.icon.input > i,
    .left.icon.input > button {
        position: absolute;
        left: 0.5em;
    }

    .right.icon.input input {
        padding-right: 2em;
    }

    .right.icon.input > i,
    .right.icon.input > button {
        position: absolute;
        right: 0.5em;
    }

    .input.warn,
    .input.negative {
        color: var(--color-negative);
    }

    .input.positive {
        color: var(--color-positive);
    }

    .input.warn input:not(:focus) {
        border-color: var(--color-negative);
    }

    [hidden],
    .hidden {
        display: none !important;
    }

    [invisible],
    .invisible {
        opacity: 0;
        transform: scale(0);
        pointer-events: none;
    }

    .bouncy {
        transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    }

    [disabled] {
        opacity: 0.5;
        pointer-events: none;
    }

    [concealed],
    .concealed {
        visibility: hidden;
        pointer-events: none;
        position: absolute;
    }

    a {
        cursor: pointer;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 1em 0.25em 0.5em 0.25em;
    }

    h1 {
        font-size: var(--font-size-huge);
        font-weight: 300;
    }

    h2 {
        font-size: 1.4em;
        font-weight: 600;
    }

    h3 {
        font-size: 1.3em;
        font-weight: 600;
    }

    h4 {
        font-size: 1.2em;
        font-weight: 600;
    }

    h5 {
        font-size: 1.1em;
        font-weight: 600;
    }

    pre {
        text-align: left;
        white-space: pre-wrap;
        line-height: 1.2em;
    }

    label {
        display: block;
        font-weight: 600;
        padding: 0.5em;
        line-height: 1.5em;
    }

    form .field {
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }

    form .inline.field {
        flex-direction: row;
        align-items: center;
    }

    form .inline.field > label {
        margin-right: 0.5em;
    }

    form .actions {
        margin-top: 1em;
    }

    form .actions.grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 0.5em;
    }

    form .field .description {
        flex: 1;
        width: 0;
        margin: 0em 2em 0.2em 2em;
        max-width: 15em;
        font-size: var(--font-size-small);
        opacity: 0.8;
    }

    form.stacked .field {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-items: flex-start;
    }

    form.stacked .field > label {
        width: var(--form-label-width, 14em);
        text-align: right;
        margin-right: 1.2em;
    }

    form.stacked .actions {
        padding-left: calc(var(--form-label-width, 14em) + 2.8em);
        margin-top: 1.4em;
    }

    form.stacked .actions > button {
        margin-right: 0.5em;
    }

    input[type="range"] {
        -webkit-appearance: none;
        padding: 0.8em;
    }

    input[type="range"]:focus {
        outline: none;
    }

    input[type="range"]::-moz-range-track {
        height: 0.2em;
        background: var(--shade-2);
        border-radius: 0.2em;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        height: 0.2em;
        background: var(--shade-2);
        border-radius: 0.2em;
    }

    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 1.4em;
        width: 1.4em;
        margin-top: -0.6em;
        border-radius: 100%;
        background: var(--color-highlight, var(--color-primary));
        cursor: pointer;
        border: none;
        box-sizing: border-box;
        background-clip: content-box;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 1.4em;
        width: 1.4em;
        margin-top: -0.6em;
        border-radius: 100%;
        background: var(--color-highlight, var(--color-primary));
        cursor: pointer;
        border: none;
        box-sizing: border-box;
        background-clip: content-box;
    }

    .red {
        --color-highlight: var(--red);
        --color-highlight-bg: var(--red-bg);
    }

    .orange {
        --color-highlight: var(--orange);
        --color-highlight-bg: var(--orange-bg);
    }

    .yellow {
        --color-highlight: var(--yellow);
        --color-highlight-bg: var(--yellow-bg);
    }

    .olive {
        --color-highlight: var(--olive);
        --color-highlight-bg: var(--olive-bg);
    }

    .green {
        --color-highlight: var(--green);
        --color-highlight-bg: var(--green-bg);
    }

    .teal {
        --color-highlight: var(--teal);
        --color-highlight-bg: var(--teal-bg);
    }

    .blue {
        --color-highlight: var(--blue);
        --color-highlight-bg: var(--blue-bg);
    }

    .violet {
        --color-highlight: var(--violet);
        --color-highlight-bg: var(--violet-bg);
    }

    .purple {
        --color-highlight: var(--purple);
        --color-highlight-bg: var(--purple-bg);
    }

    .pink {
        --color-highlight: var(--pink);
        --color-highlight-bg: var(--pink-bg);
    }

    .brown {
        --color-highlight: var(--brown);
        --color-highlight-bg: var(--brown-bg);
    }

    .grey {
        --color-highlight: var(--grey);
        --color-highlight-bg: var(--grey-bg);
    }

    .black {
        --color-highlight: var(--black);
        --color-highlight-bg: var(--black-bg);
    }

    .aqua {
        --color-highlight: var(--aqua);
        --color-highlight-bg: var(--aqua-bg);
    }

    .white {
        --color-highlight: var(--white);
        --color-highlight-bg: var(--shade-5);
    }

    .colored-text {
        color: var(--color-highlight);
    }

    .background {
        background: var(--color-bg);
    }

    sup {
        vertical-align: super;
    }

    sub {
        vertical-align: sub;
    }
`;
