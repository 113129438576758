import { config, shared, animations } from "@pentacode/app/src/styles";
import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import "@pentacode/app/src/elements/scroller";
import { fullbleed } from "@pentacode/app/src/styles/mixins";
import "@pentacode/app/src/elements/avatar";
import { toTimeString } from "@pentacode/core/src/util";
import "./pad";
import type { AttendanceRecorderPad as TimeLogPad } from "./pad";
import { singleton } from "@pentacode/app/src/lib/singleton";
import { OptionsDialog } from "./options-dialog";
import Logo from "@pentacode/app/src/img/logo.svg";
import { TimeLogController } from "../controllers/time-log";
import { alert } from "@pentacode/app/src/elements/alert-dialog";
import { version } from "@pentacode/core/src/version";
import { TimeScheduleDialog } from "./time-schedule-dialog";
import { CompletionDialog } from "./completion-dialog";
import { applyAutoBreaks, getEndFinal, getStartFinal } from "@pentacode/core/src/time";
import { timeEntryAPISchemaToModel } from "@pentacode/core/src/rest/transform";
import { TimeEntry } from "@pentacode/core/src/model";

@customElement("ptc-time-log-app")
export class TimeLogApp extends LitElement {
    @property({ type: Boolean, reflect: true, attribute: "singleton-container" })
    readonly singletonContainer = true;

    @singleton("ptc-time-log-options-dialog")
    private _optionsDialog: OptionsDialog;

    @singleton("ptc-time-schedule-dialog")
    private _timeScheduleDialog: TimeScheduleDialog;

    @singleton("ptc-time-log-completion-dialog")
    private _shiftCompletionDialog: CompletionDialog;

    @query("ptc-time-log-pad")
    private _pad: TimeLogPad;

    @query("input[name='connectionCode']")
    private _codeInput: HTMLInputElement;

    private _controller = new TimeLogController(this);

    async connectedCallback() {
        super.connectedCallback();
        const params = new URLSearchParams(location.search);
        const code = params.get("cc");
        if (code) {
            await this.updateComplete;
            this._codeInput.value = code;
        }
    }

    private async _submitConnectForm(e: Event) {
        e.preventDefault();
        const data = new FormData(e.target as HTMLFormElement);
        const code = data.get("connectionCode") as string;
        try {
            await this._controller.logger.connect(code);
        } catch (e) {
            await alert("Verbindung fehlgeschlagen! Bitte versuchen sie es erneut. " + e.message, { type: "warning" });
        }
    }

    private async _pinEntered(e: CustomEvent<{ pin: string }>) {
        const pin = e.detail.pin;
        const option = this._controller.logger.getOption({ pin });
        if (option) {
            const choice = await this._optionsDialog.show(option);
            if (choice) {
                await this._controller.logger.performAction(choice);
                if (choice.action === "startShift" && choice.timeEntryId) {
                    const shift = option?.scheduled.find((s) => s.timeEntry.id === choice.timeEntryId);
                    if (shift && shift.settings.displayShiftDetails) {
                        const timeEntry = shift.timeEntry;
                        timeEntry.startFinal = getStartFinal(
                            new TimeEntry(timeEntryAPISchemaToModel(timeEntry)),
                            new Date(),
                            shift.settings.useActualTimeAtShiftStart,
                            shift.settings.shiftStartRounding
                        ).toISOString();
                    }
                } else if (choice.action === "endShift" && choice.timeEntryId) {
                    const shift = option?.scheduled.find((s) => s.timeEntry.id === choice.timeEntryId);
                    if (shift && shift.settings.displayShiftDetails) {
                        const timeEntry = shift.timeEntry;

                        timeEntry.endFinal = getEndFinal(
                            new TimeEntry(timeEntryAPISchemaToModel(timeEntry)),
                            new Date(),
                            shift.settings.useActualTimeAtShiftEnd,
                            shift.settings.shiftEndRounding
                        ).toISOString();

                        const displayEntry = new TimeEntry(
                            timeEntryAPISchemaToModel({
                                ...timeEntry,
                                comment: choice.comment,
                            })
                        );

                        applyAutoBreaks(displayEntry, shift.settings.breakMode, shift.settings.autoBreaks);

                        await this._shiftCompletionDialog.show({
                            timeEntry: displayEntry,
                            employee: option.employee,
                        });
                    }
                }
                this.requestUpdate();
            }
        } else {
            await alert("PIN nicht erkannt!");
        }
        this._pad.clearPin();
    }

    static styles = [
        config,
        shared,
        animations,
        css`
            .main-layout {
                ${fullbleed()};
                display: grid;
                grid-template: "upcoming pinpad active" 100% / 1fr 25em 1fr;
                justify-content: center;
                gap: 1em;
                padding: 1em 1em 3.5em 1em;
                max-height: 50em;
                max-width: 75em;
                margin: auto;
            }

            .footer {
                position: absolute;
                bottom: 0.5em;
                left: 0;
                right: 0;
                z-index: 1;
            }

            .connection-status {
                overflow: visible;
            }

            .connection-status.online {
                color: var(--color-positive);
            }

            .connection-status.offline {
                color: var(--color-negative);
            }

            .pinpad {
                grid-area: pinpad;
            }

            .upcoming {
                grid-area: upcoming;
            }

            .active {
                grid-area: active;
            }

            .connect-form {
                z-index: 10;
            }

            .display-not-supported {
                background: var(--color-bg);
                z-index: 100;
            }

            .schedule-item .pill {
                font-size: 0.7em;
            }

            .schedule-item ptc-avatar {
                font-size: 1.15em;
            }

            .schedule-item .time-display {
                font-size: 1.3em;
            }

            @media (max-width: 900px) {
                .main-layout {
                    grid-template: "pinpad upcoming" minmax(10em, 1fr) "pinpad active" minmax(10em, 1fr) / 1fr 1fr;
                    max-height: 100%;
                }
            }

            @media (max-width: 700px) {
                .main-layout {
                    grid-template: "pinpad upcoming" 1fr "pinpad active" 1fr / 1fr 0;
                    grid-gap: 0;
                }

                .upcoming,
                .active {
                    display: none;
                }
            }

            @media (max-height: 700px) {
                .main-layout,
                .pinpad {
                    font-size: var(--font-size-small);
                }
            }

            @media (max-height: 600px) {
                .main-layout,
                .pinpad {
                    font-size: 0.7rem;
                }
            }

            @media (max-height: 550px) {
                .main-layout,
                .pinpad {
                    font-size: 0.65rem;
                }
            }

            @media (max-height: 500px) {
                .main-layout,
                .pinpad {
                    font-size: 0.58rem;
                }
            }

            @media (max-height: 450px) {
                .main-layout,
                .pinpad {
                    font-size: 0.5rem;
                }
            }

            @media (min-height: 400px) {
                .display-not-supported {
                    display: none;
                }
            }
        `,
    ];

    private _renderFooter() {
        return html`
            <div class="smaller footer" @click=${() => this._controller.logger.poll()}>
                <div class="centering spacing horizontal layout">
                    <img style="height: 1.5em" src=${Logo} />
                </div>
                <div class="centering horizontal layout">
                    <div class="semibold">Stempeluhr v${version}</div>
                    <div class="connection-status bold ${this._controller.state.offline ? "offline" : "online"}">
                        ${this._controller.state.synching
                            ? html` <i class="refresh spin"></i> `
                            : html`
                                      <i class="circle blink"></i>${this._controller.state.offline ? "offline" : ""}
                                  </div>
                              `}
                    </div>
                </div>
            </div>
        `;
    }

    render() {
        return html`
            ${this._controller.state.connection
                ? html`
                      <div class="main-layout">
                          <div
                              class="vertical layout upcoming"
                              ?hidden=${!this._controller.state.device?.displayUpcoming}
                          >
                              <div class="padded subtle semibold text-centering"><i class="calendar"></i> Geplant</div>
                              ${this._controller.logger.upcomingShifts.length
                                  ? html`
                                        <ptc-scroller class="stretch">
                                            <div class="padded">
                                                ${this._controller.logger.upcomingShifts.map(
                                                    (option) =>
                                                        option.timeEntry &&
                                                        html`
                                                            <div class="padded spacing horizontal layout schedule-item">
                                                                <ptc-avatar .employee=${option.employee}></ptc-avatar>
                                                                <div class="stretch collapse">
                                                                    <div class="ellipsis semibold">
                                                                        ${option.employee.firstName}
                                                                        ${option.employee.lastName}
                                                                    </div>
                                                                    <div
                                                                        class="top-margined pill"
                                                                        style="--color-highlight: ${option.timeEntry
                                                                            .position?.color}"
                                                                    >
                                                                        ${option.timeEntry.position?.name}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="time-display bold colored-text ${new Date(
                                                                        option.timeEntry.startPlanned! // this could still be null, which is unhandled
                                                                    ).getTime() < Date.now()
                                                                        ? "red"
                                                                        : "blue"}"
                                                                >
                                                                    ${toTimeString(
                                                                        new Date(option.timeEntry.startPlanned!)
                                                                    )}
                                                                </div>
                                                            </div>
                                                        `
                                                )}
                                            </div>
                                        </ptc-scroller>
                                    `
                                  : html`
                                        <div class="centering layout stretch">
                                            <div class="faded text-centering">
                                                Keine geplanten<br />Schichten gefunden.
                                            </div>
                                        </div>
                                    `}
                          </div>

                          <ptc-time-log-pad
                              class="pinpad relative"
                              @pin-entered=${this._pinEntered}
                              @show-schedule=${() => this._timeScheduleDialog.show()}
                          ></ptc-time-log-pad>

                          <div class="vertical layout active" ?hidden=${!this._controller.state.device?.displayActive}>
                              <div class="padded subtle semibold text-centering"><i class="timer"></i> Aktiv</div>
                              ${this._controller.logger.activeShifts.length
                                  ? html`
                                        <ptc-scroller class="stretch">
                                            <div class="padded">
                                                ${this._controller.logger.activeShifts.map(
                                                    (option) =>
                                                        option.timeEntry &&
                                                        html`
                                                            <div class="padded spacing horizontal layout schedule-item">
                                                                <ptc-avatar .employee=${option.employee}></ptc-avatar>
                                                                <div class="stretch collapse">
                                                                    <div class="ellipsis semibold">
                                                                        ${option.employee.firstName}
                                                                        ${option.employee.lastName}
                                                                    </div>
                                                                    <div
                                                                        class="top-margined pill"
                                                                        style="--color-highlight: ${option.timeEntry
                                                                            ?.position?.color}"
                                                                    >
                                                                        ${option.timeEntry?.position?.name}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    ${option.timeEntry.currentBreakStart
                                                                        ? html`
                                                                              <div class="inverted orange pill">
                                                                                  <i class="coffee"></i>
                                                                                  ${Math.ceil(
                                                                                      (Date.now() -
                                                                                          new Date(
                                                                                              option.timeEntry.currentBreakStart
                                                                                          ).getTime()) /
                                                                                          60000
                                                                                  )}
                                                                                  min
                                                                              </div>
                                                                          `
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                        `
                                                )}
                                            </div>
                                        </ptc-scroller>
                                    `
                                  : html`
                                        <div class="centering layout stretch">
                                            <div class="faded text-centering">
                                                Keine aktiven<br />Schichten gefunden.
                                            </div>
                                        </div>
                                    `}
                          </div>
                          ${this._renderFooter()}
                      </div>
                  `
                : html`
                      <div class="fullbleed spacing centering vertical layout connect-form">
                          <img src=${Logo} style="width: 18em" />
                          <div class="subtle semibold large" style="margin-top: -0.5em;">Stempeluhr</div>
                          <div class="padded subtle text-centering" style="width: 24em">
                              Willkommen bei der digitalen Stempeluhr von Pentacode! Bitte geben Sie den
                              <strong>Verbindungscode</strong> ein, der Ihnen im Verwaltungsportal angezeigt wird.
                          </div>
                          <form @submit=${this._submitConnectForm} class="vertical spacing layout" style="width: 20em;">
                              <input
                                  name="connectionCode"
                                  placeholder="Verbindungscode"
                                  class="text-centering"
                                  required
                              />
                              <button class="primary">Verbinden</button>
                          </form>
                      </div>
                  `}

            <div class="fullbleed centering vertical layout display-not-supported">
                <i class="giant subtle display-slash"></i>
                <div class="padded text-centering" style="max-width: 20em">
                    Das aktuelle Displayformat wird nicht unterstützt. Bitten vergrößern die das Fenster oder ändern Sie
                    die Orientierung des Gerätes.
                </div>
            </div>
        `;
    }
}
