export class Router {
    history: string[] = [];

    private _eventDispatcher = document.createElement("div");

    private _forceNextUpdate = false;

    addEventListener(name: string, handler: (e: Event) => void) {
        this._eventDispatcher.addEventListener(name, handler);
    }

    removeEventListener(name: string, handler: (e: Event) => void) {
        this._eventDispatcher.removeEventListener(name, handler);
    }

    constructor(public basePath = "/") {
        window.addEventListener("popstate", () => {
            this._pathChanged();
        });
        this._pathChanged();
    }

    private _pathChanged() {
        const index = (history.state && history.state.historyIndex) || 0;
        const path = this.path;
        const direction = this.history.length - 1 < index ? "forward" : "backward";

        if (this.history.length === index) {
            this.history.push(path);
        } else
            while (this.history.length - 1 > index) {
                this.history.pop();
            }

        const canceled =
            !this._forceNextUpdate &&
            !this._eventDispatcher.dispatchEvent(
                new CustomEvent("before-route-changed", { detail: { path, direction }, cancelable: true })
            );

        if (canceled) {
            this._forceNextUpdate = true;
            direction === "forward" ? this.back() : this.forward();
            return;
        } else {
            this._forceNextUpdate = false;
        }

        this._eventDispatcher.dispatchEvent(new CustomEvent("route-changed", { detail: { path, direction } }));
    }

    get path() {
        return window.location.pathname.replace(new RegExp("^" + this.basePath), "");
    }

    get params() {
        const params: Record<string, string> = {};
        for (const [key, value] of new URLSearchParams(window.location.search)) {
            params[key] = value;
        }
        return params;
    }

    set params(params: { [prop: string]: string }) {
        history.replaceState(
            { historyIndex: this.history.length - 1 },
            "",
            this.basePath + this.path + "?" + new URLSearchParams(params).toString()
        );
    }

    get canGoBack() {
        return this.history.length > 1;
    }

    go(path: string = this.path, params?: { [prop: string]: string }, replace = false) {
        const queryString = new URLSearchParams(params || {}).toString();

        if (path !== this.path || queryString !== window.location.search) {
            let url = this.basePath + path;
            if (queryString) {
                url += "?" + queryString;
            }
            if (replace) {
                history.replaceState({ historyIndex: this.history.length - 1 }, "", url);
            } else {
                history.pushState({ historyIndex: this.history.length }, "", url);
            }
            this._pathChanged();
        }
    }

    redirect(path: string) {
        return this.go(path, undefined, true);
    }

    forward() {
        history.forward();
    }

    back() {
        if (this.canGoBack) {
            history.back();
            return true;
        }
        return false;
    }
}
