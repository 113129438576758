import { shared } from "@pentacode/app/src/styles";
import { LitElement, css, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { formatDate, toTimeString } from "@pentacode/core/src/util";
import { TimeLogController } from "../controllers/time-log";
import { popover } from "@pentacode/app/src/directives/popover";
import { confirm } from "@pentacode/app/src/elements/alert-dialog";
import { renderFilters } from "../lib/helpers";
import "@pentacode/app/src/elements/qr-code";

@customElement("ptc-time-log-pad")
export class AttendanceRecorderPad extends LitElement {
    @property({ type: Boolean, reflect: true, attribute: "singleton-container" })
    readonly singletonContainer = true;

    @query("#pinInput")
    private _pinInput: HTMLInputElement;

    private _controller = new TimeLogController(this);

    private get _pin() {
        return this._pinInput?.value || "";
    }

    clearPin() {
        this._pinInput.value = "";
        this.requestUpdate();
    }

    connectedCallback() {
        super.connectedCallback();
        // Update UI once per minute to keep clock up to date
        setInterval(() => this.requestUpdate(), 1000 * 60);
    }

    private _enterDigit(e: MouseEvent) {
        this._pinInput.value += (e.target as HTMLButtonElement).dataset.digit;

        if (this._pinInput.value.length === 4) {
            this.dispatchEvent(new CustomEvent("pin-entered", { detail: { pin: this._pinInput.value } }));
        }

        this.requestUpdate();
    }

    static styles = [
        shared,
        css`
            .pinpad {
                display: grid;
                grid-template: repeat(4, 7em) / repeat(3, 7.5em);
                /* grid-gap: 0.8em 0.6em; */
            }

            .pinpad > button {
                background: var(--color-bg);
                /* border: solid 1px var(--shade-2); */
                font-size: 2em;
                border-radius: 0;
                border: solid 1px var(--shade-1);
                /* border-radius: 100%;
                box-shadow: var(--shade-2) 0 2px 0 0; */
            }

            .pinpad > button:active {
                /* box-shadow: var(--shade-2) 0 1px 0 0;
                transform: translateY(2px); */
                color: var(--color-primary);
            }

            .pin-input > * {
                font-size: 1.7em;
            }

            .pin-input,
            .qr-container {
                height: 4.5em;
                margin: 1em;
            }

            :host {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        `,
    ];

    private _renderPinpad() {
        return html`
            <div class="centering double-spacing vertical layout">
                <div
                    class="focus-container pin-input horizontal center-aligning layout fill-horizontally"
                    ?hidden=${!this._pin.length}
                >
                    <input type="password" class="borderless stretch collapse" readonly id="pinInput" />
                    <button
                        class="skinny transparent"
                        style="margin-right: 0.2em"
                        @click=${this.clearPin}
                        ?hidden=${!this._pin.length}
                    >
                        <i class="backspace"></i>
                    </button>
                </div>

                <div class="horizontal centering layout qr-container fill-horizontally" ?hidden=${this._pin.length}>
                    <div class="stretch collapse">
                        <i class="camera"></i> Jetzt kontaktlos mit der Pentacode Mitarbeiter-App anmelden!
                    </div>
                    <i class="large arrow-right double-margined"></i>
                    <ptc-qr-code
                        .value=${this._controller.state.company?.employeeAppTimeLogUrl || ""}
                        ${popover(html`
                            <ptc-qr-code
                                class="massive"
                                .value=${this._controller.state.company?.employeeAppTimeLogUrl || ""}
                            ></ptc-qr-code>
                        `)}
                    ></ptc-qr-code>
                </div>

                <div class="pinpad" ?disabled=${this._pin.length >= 4}>
                    <button @click=${this._enterDigit} data-digit="1" style="border-width: 0 0 0 0">1</button>
                    <button @click=${this._enterDigit} data-digit="2" style="border-width: 0 1px 0 1px">2</button>
                    <button @click=${this._enterDigit} data-digit="3" style="border-width: 0 0 0 0">3</button>
                    <button @click=${this._enterDigit} data-digit="4" style="border-width: 1px 0 1px 0">4</button>
                    <button @click=${this._enterDigit} data-digit="5" style="border-width: 1px 1px 1px 1px">5</button>
                    <button @click=${this._enterDigit} data-digit="6" style="border-width: 1px 0 1px 0">6</button>
                    <button @click=${this._enterDigit} data-digit="7" style="border-width: 0 0 1px 0">7</button>
                    <button @click=${this._enterDigit} data-digit="8" style="border-width: 0 1px 1px 1px">8</button>
                    <button @click=${this._enterDigit} data-digit="9" style="border-width: 0 0 1px 0">9</button>
                    <button
                        style="border-width: 0 0 0 0"
                        @click=${() => this.dispatchEvent(new CustomEvent("show-schedule"))}
                        ?invisible=${!this._controller.state.device?.displaySchedule}
                    >
                        <i class="smaller thin table"></i>
                    </button>
                    <button @click=${this._enterDigit} data-digit="0" style="border-width: 0 1px 0 1px">0</button>
                    <button
                        style="border-width: 0 0 0 0"
                        ${popover(
                            html`<button @click=${() => this._disconnect()}>
                                <i class="plug-circle-xmark"></i> Verbindung Trennen
                            </button>`,
                            {
                                class: "popover-menu",
                                hideOnClick: true,
                            }
                        )}
                    >
                        <i class="smaller thin sliders"></i>
                    </button>
                </div>
            </div>
        `;
    }

    private _renderDateAndTime() {
        const now = new Date();
        return html`
            <div class="vertical center-aligning center-justifying layout clock">
                <div class="horizontal centering layout">
                    ${this._controller.state.company?.name} - ${this._controller.state.device?.description}
                </div>
                <div style="line-height: 1em; font-size: 4em;">${toTimeString(now)}</div>
                <div>${formatDate(now)}</div>
            </div>
        `;
    }

    private async _disconnect() {
        if (
            await confirm(
                "Sind Sie sicher, dass Sie dieses Gerät als Stempeluhr trennen wollen? " +
                    "Sie können dann keine weiteren Zeiten erfassen, bevor Sie das Gerät erneut verbinden.",
                "Stempeluhr Trennen",
                "Abbrechen",
                {
                    type: "destructive",
                    icon: "plug-circle-xmark",
                }
            )
        ) {
            await this._controller.logger.disconnect();
        }
    }

    render() {
        if (!this._controller.state.company) {
            return;
        }
        return html`
            <div class="spacing vertical layout">
                ${this._renderDateAndTime()}
                <div class="smaller text-centering">
                    ${renderFilters(this._controller.state.company, this._controller.state.device?.filters || [])}
                </div>
                ${this._renderPinpad()}
            </div>
        `;
    }
}
