export enum ErrorCode {
    FAILED_CONNECTION = "failed_connection",
    BAD_REQUEST = "bad_request",
    INVALID_SESSION = "invalid_session",
    SESSION_EXPIRED = "session_expired",
    INSUFFICIENT_PERMISSIONS = "insufficient_permissions",
    RATE_LIMIT_EXCEEDED = "rate_limit_exceeded",
    ACCOUNT_EXISTS = "account_exists",
    INVALID_RESPONSE = "invalid_response",
    INVALID_REQUEST = "invalid_request",
    NOT_FOUND = "not_found",
    SERVER_ERROR = "server_error",
    AUTHENTICATION_FAILED = "authentication_failed",
    ENCODING_ERROR = "encoding_error",
    CONFLICT = "conflict",
    NOT_SUPPORTED = "not_supported",
    NOT_ALLOWED = "not_allowed",
    FEATURE_UNAVAILABLE = "feature_unavailable",
    INVALID_TEMP_AUTH_TOKEN = "invalid_temp_auth_token",
    COMPANY_SUSPENDED = "company_suspended",
}

export class Err extends Error {
    code: ErrorCode;
    report: boolean;
    display: boolean;
    originalError?: Error;

    constructor(
        code: ErrorCode,
        message?: string,
        opts: { report?: boolean; display?: boolean; status?: number; error?: Error } = {}
    ) {
        super(message || (opts.error && opts.error.message) || "");
        this.code = code;
        this.report = opts.report || false;
        this.display = opts.display || false;
        this.originalError = opts.error;
    }

    toString() {
        return `${this.code}: ${this.message}`;
    }
}
