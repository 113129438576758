import { ElementPart, nothing, TemplateResult } from "lit";
import { Directive, directive, PartInfo, PartType } from "lit/directive.js";
import { getSingleton } from "../lib/singleton";
import type { Popover } from "../elements/popover";
import "../elements/popover";

let popoverEl: Popover | undefined = undefined;

type PopoverOptions = {
    trigger?: Popover["trigger"];
    alignment?: Popover["alignment"];
    preferAlignment?: Popover["preferAlignment"];
    hideOnClick?: boolean;
    class?: string;
    style?: string;
};

class PopoverDirective extends Directive {
    private _content?: TemplateResult;

    private _element?: HTMLElement;

    private _options?: PopoverOptions;

    private _showPopover = async () => {
        if (!this._content || !this._element) {
            return;
        }
        if (!popoverEl) {
            popoverEl = getSingleton("ptc-popover") as Popover;
        }

        if (popoverEl.anchor === this._element && popoverEl.showing) {
            return;
        }

        popoverEl.content = this._content;
        popoverEl.trigger = this._options?.trigger || "click";
        popoverEl.alignment = this._options?.alignment || "auto";
        popoverEl.preferAlignment = this._options?.preferAlignment || "auto";
        popoverEl.hideOnClick = this._options?.hideOnClick || false;
        popoverEl.className = this._options?.class || "";
        popoverEl.style.cssText = this._options?.style || "";
        await popoverEl.updateComplete;
        popoverEl.showAt(this._element, popoverEl.trigger === "click");
    };

    // private _hidePopover = () => popoverEl?.hide();

    constructor(part: PartInfo) {
        super(part);
        if (part.type !== PartType.ELEMENT) {
            throw new Error("The `popover` directive must be used in element position.");
        }
    }

    render(_content: TemplateResult, _options?: PopoverOptions) {
        return nothing;
    }

    update(part: ElementPart, [content, options]: Parameters<this["render"]>) {
        this._content = content;
        this._options = options;
        if (this._element !== part.element) {
            this._elementUpdated(part.element as HTMLElement);
        }
    }

    private _elementUpdated(el: HTMLElement) {
        const event =
            this._options?.trigger === "focus" ? "focus" : this._options?.trigger === "hover" ? "mouseenter" : "click";
        if (this._element) {
            this._element.removeEventListener(event, this._showPopover);
        }

        this._element = el;
        this._element.addEventListener(event, this._showPopover);
    }
}

export const popover = directive(PopoverDirective);
