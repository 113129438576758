import { Type } from "class-transformer";
import { hour } from "./hours";
import { Employee, TimeEntry, TimeEntryType, Company, BreakMode } from "./model";
import { dateAdd } from "./util";
import { DateRange, getAutomaticBreak, getStatutoryBreak } from "./time";
import { DateString, Euros, Hours, Rate } from "@pentacode/openapi";

export enum IssueType {
    OverlappingShifts,
    ExcessiveWorkDay,
    DeficientIdlePeriod,
    DeficientBreak,
    DeficientSalary,
    ExcessiveSalary,
    MissingShiftStart,
    MissingShiftEnd,
    MissingContract,
    PendingDailyRevenues,
    UnbalancedDailyCash,
}

export function getIssueMessage(type: IssueType) {
    switch (type) {
        case IssueType.OverlappingShifts:
            return "Schichtüberlappung";
        case IssueType.ExcessiveWorkDay:
            return "Arbeitszeit > 10 Std.";
        case IssueType.DeficientIdlePeriod:
            return "Ruhezeit < 11 Std.";
        case IssueType.DeficientBreak:
            return "Unterschreitung ges. Pause";
        case IssueType.DeficientSalary:
            return "Mindestlohnunterschr.";
        case IssueType.ExcessiveSalary:
            return "Überschr. Lohngrenze";
        case IssueType.MissingShiftStart:
            return "Schicht Nicht Angetr.";
        case IssueType.MissingShiftEnd:
            return "Schicht Nicht Beendet";
        case IssueType.MissingContract:
            return "Außerh. Vertragszeitr.";
        case IssueType.PendingDailyRevenues:
            return "Tagesabrechnung nicht abgeschlossen";
        case IssueType.UnbalancedDailyCash:
            return "Fehlerhafte Tagesabrechnung";
    }
}

type IssueAdditionalInfo<T extends IssueType> = T extends IssueType.DeficientSalary
    ? {
          hours: Hours;
          salary: Euros;
          effectiveHourlyRate: Rate<Euros, Hours>;
          minHourlyRate: Rate<Euros, Hours>;
      }
    : T extends IssueType.ExcessiveSalary
      ? {
            maxSalary: Euros;
            actualSalary: Rate<Euros, Hours>;
        }
      : T extends IssueType.PendingDailyRevenues
        ? { venue: number }
        : T extends IssueType.UnbalancedDailyCash
          ? { venue: number; actual: Euros; nominal: Euros }
          : undefined;

export class Issue<TIssueType extends IssueType = IssueType> {
    constructor(vals: Partial<Issue<TIssueType>> = {}) {
        Object.assign(this, vals);
    }

    type: TIssueType;

    @Type(() => TimeEntry)
    timeEntries: TimeEntry[] = [];

    employee?: number;

    date: DateString;

    additionalInfo: IssueAdditionalInfo<TIssueType> | undefined = undefined;

    ignored: boolean = false;

    get message() {
        return getIssueMessage(this.type);
    }

    /**
     * Checks if the issue is of a specific type. This is useful for type narrowing.
     */
    isType<T extends TIssueType>(type: T): this is Issue<T> {
        return this.type === type;
    }

    /**
     * Checks if the issue has an employee and therefore narrows the type to employee IssueTypes.
     */
    isEmployeeTypeIssue(): this is Issue<
        IssueType.DeficientSalary | IssueType.ExcessiveSalary | IssueType.PendingDailyRevenues
    > {
        return !!this.employee;
    }
}

export function getIssues(
    employee: Employee,
    company: Company,
    timeEntries: TimeEntry[],
    { from, to }: DateRange
): Issue[] {
    const issues: Issue[] = [];
    const now = new Date();
    const commitBefore = company.settings.commitTimeEntriesBefore;
    if (commitBefore && commitBefore > from) {
        from = commitBefore;
    }

    timeEntries = timeEntries
        .filter(
            (entry) =>
                !entry.deleted &&
                entry.employeeId === employee.id &&
                entry.type === TimeEntryType.Work &&
                (entry.planned || entry.final) &&
                // Include time entries from the last day of the previous month to check for shift overlaps
                entry.date >= dateAdd(from, { days: -1 }) &&
                entry.date < to
        )
        .sort((a, b) => Number(a.start) - Number(b.start));

    for (let i = 0, dayEntries: TimeEntry[] = []; i < timeEntries.length; i++) {
        const curr = timeEntries[i];
        const next = timeEntries[i + 1];

        dayEntries.push(curr);

        // Last shift of the day
        if (!next || next.start.toDateString() !== curr.start.toDateString()) {
            // Check if total work duration on this day exceeds 10 hours
            const totalDur = dayEntries.reduce((total, timeEntry) => {
                const timeSettings = company.getTimeSettings({ timeEntry });
                const brk = timeEntry.final
                    ? timeEntry.break || 0
                    : [BreakMode.Planned, BreakMode.PlannedPlusManual].includes(timeSettings.breakMode)
                      ? timeEntry.breakPlanned || 0
                      : getAutomaticBreak(timeEntry.duration, timeSettings);
                return total + (timeEntry.end.getTime() - timeEntry.start.getTime()) - brk * hour;
            }, 0);

            // Employees may not work more than 10 hours a day
            if (totalDur > 10 * hour) {
                issues.push(
                    new Issue({
                        type: IssueType.ExcessiveWorkDay,
                        timeEntries: dayEntries,
                        employee: curr.employeeId!,
                        date: dayEntries[0].date,
                    })
                );
            }

            if (next && next.start.getTime() - curr.end.getTime() < 11 * hour) {
                issues.push(
                    new Issue({
                        type: IssueType.DeficientIdlePeriod,
                        timeEntries: [curr, next],
                        employee: curr.employeeId!,
                        date: next.date,
                    })
                );
            }

            dayEntries = [];
        }

        if (now > curr.end && (!curr.startFinal || !curr.endFinal)) {
            issues.push(
                new Issue({
                    type: !curr.startFinal ? IssueType.MissingShiftStart : IssueType.MissingShiftEnd,
                    timeEntries: [curr],
                    employee: curr.employeeId!,
                    date: curr.date,
                })
            );
        }

        if (next && curr.end! > next.start!) {
            issues.push(
                new Issue({
                    type: IssueType.OverlappingShifts,
                    timeEntries: [curr, next],
                    employee: curr.employeeId!,
                    date: next.date,
                })
            );
        }

        if (curr.final && (curr.break || 0) < getStatutoryBreak(curr.duration, company.settings)) {
            issues.push(
                new Issue({
                    type: IssueType.DeficientBreak,
                    timeEntries: [curr],
                    employee: curr.employeeId!,
                    date: curr.date,
                })
            );
        }

        if (!employee.getContractForDate(curr.date)) {
            issues.push(
                new Issue({
                    type: IssueType.MissingContract,
                    timeEntries: [curr],
                    employee: curr.employeeId!,
                    date: curr.date,
                })
            );
        }
    }

    issues.forEach(
        (issue) =>
            (issue.ignored = issue.timeEntries.some((e) => e.ignoreIssues && e.ignoreIssues.includes(issue.type)))
    );

    return issues.filter((issue) => issue.date >= from);
}
