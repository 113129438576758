import { StateCode } from "./localized";
import { toDateString, parseDateString } from "./util";

export interface Holiday {
    name: string;
    date: Date;
    states: StateCode[];
    special?: true;
    noRealHoliday?: true;
}

const cached = {
    DE: new Map<number, Map<string, Holiday>>(),
    AT: new Map<number, Map<string, Holiday>>(),
};

type Options = { holidays?: HolidayName[]; country?: "DE" | "AT" };

export function getHolidayForDate(date: string | Date, opts: Options) {
    if (!(date instanceof Date)) {
        date = parseDateString(date)!;
    }

    if (!date) {
        return null;
    }

    return getHoliday(date.getFullYear(), date.getMonth(), date.getDate(), opts);
}

export function getHoliday(year: number, month: number, date: number, { holidays, country }: Options) {
    const holiday = getHolidaysInYear(year, country).get(toDateString(new Date(year, month, date)));
    return holiday && (!holidays || holidays.includes(holiday.name)) ? holiday : null;
}

export function getHolidaysInYear(year: number, country: "DE" | "AT" = "DE") {
    if (!cached[country].has(year)) {
        const holidays = Object.values(HOLIDAYS[country])
            .filter((t) => !t.since || t.since <= year)
            .map(({ name, states, date, special, noRealHoliday }) => ({
                name,
                states,
                date: date(year),
                special,
                noRealHoliday,
            }));

        cached[country].set(
            year,
            new Map<string, Holiday>(holidays.map((holiday) => [toDateString(holiday.date), holiday]))
        );
    }

    return cached[country].get(year)!;
}

export function getHolidaysInRange(from: string, to: string, options: Options) {
    const fromDate = parseDateString(from)!;
    const toDate = parseDateString(to)!;

    const holidays: Holiday[] = [];

    for (let year = fromDate.getFullYear(); year <= toDate.getFullYear(); year++) {
        holidays.push(
            ...[...getHolidaysInYear(year, options.country).values()].filter(
                (h) =>
                    !h.noRealHoliday &&
                    (!options.holidays || options.holidays.includes(h.name)) &&
                    toDateString(h.date) >= from &&
                    toDateString(h.date) < to
            )
        );
    }

    return holidays;
}

export function isHoliday(year: number, month: number, date: number, options: Options) {
    const holiday = getHoliday(year, month, date, options);
    return holiday && !holiday.noRealHoliday;
}

export function isSpecial(year: number, month: number, date: number, options: Options) {
    const holiday = getHoliday(year, month, date, options);
    return !!holiday && !!holiday.special;
}

export function isChristmasEve(_year: number, month: number, date: number) {
    return month === 11 && date === 24;
}

export function isNewYearsEve(_year: number, month: number, date: number) {
    return month === 11 && date === 31;
}

/**
 * Calculate date of easter sunday through "Gaussche Osterformel"
 * See https://de.wikipedia.org/wiki/Gau%C3%9Fsche_Osterformel
 */
function getEasterSunday(year: number) {
    const a = year % 19;
    const d = (19 * a + 24) % 30;
    let day = d + ((2 * (year % 4) + 4 * (year % 7) + 6 * d + 5) % 7);
    if (day == 35 || (day == 34 && d == 28 && a > 10)) {
        day -= 7;
    }

    return new Date(year, 2, 22 + day);
}

function getRelativeHoliday(year: number, offset: number) {
    const date = getEasterSunday(year);
    date.setDate(date.getDate() + offset);
    return date;
}

function getPrayerDay(year: number) {
    const date = new Date(year, 10, 16);
    const offset = (10 - date.getDay()) % 7;
    date.setDate(date.getDate() + offset);
    return date;
}

interface HolidayTemplate {
    name: string;
    states: StateCode[];
    date: (year: number) => Date;
    since?: number;
    special?: true;
    noRealHoliday?: true;
}

export const HOLIDAYS: { [country: string]: HolidayTemplate[] } = {
    DE: [
        {
            date: (year) => new Date(year, 0, 1),
            name: "Neujahr",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
        },
        {
            date: (year) => new Date(year, 0, 6),
            name: "Heilige 3 Könige",
            states: ["BW", "BY", "ST", "AB"],
        },
        {
            date: (year) => new Date(year, 2, 8),
            name: "Internationaler Frauentag",
            states: ["BE"],
            since: 2019,
        },
        {
            date: (year) => getRelativeHoliday(year, -2),
            name: "Karfreitag",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
        },
        {
            date: (year) => getEasterSunday(year),
            name: "Ostersonntag",
            states: [],
        },
        {
            date: (year) => getRelativeHoliday(year, 1),
            name: "Ostermontag",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
        },
        {
            date: (year) => new Date(year, 4, 1),
            name: "Tag der Arbeit",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
            special: true,
        },
        {
            date: (year) => getRelativeHoliday(year, 39),
            name: "Christi Himmelfahrt",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
        },
        {
            date: (year) => getRelativeHoliday(year, 49),
            name: "Pfingstsonntag",
            states: [],
        },
        {
            date: (year) => getRelativeHoliday(year, 50),
            name: "Pfingstmontag",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
        },
        {
            date: (year) => getRelativeHoliday(year, 60),
            name: "Fronleichnam",
            states: ["BW", "BY", "HE", "NW", "RP", "TH", "AB"],
        },
        {
            date: (year) => new Date(year, 7, 8),
            name: "Friedenstag",
            states: ["AB"],
        },
        {
            date: (year) => new Date(year, 7, 15),
            name: "Mariä Himmelfahrt",
            states: ["BY", "SL"],
        },
        {
            date: (year) => new Date(year, 8, 20),
            name: "Weltkindertag",
            states: ["TH"],
            since: 2019,
        },
        {
            date: (year) => new Date(year, 9, 3),
            name: "Tag der Dt. Einheit",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
        },
        {
            date: (year) => new Date(year, 9, 31),
            name: "Reformationstag",
            states: ["BB", "MV", "NI", "SN", "ST", "SH", "TH", "HB", "HH"],
        },
        {
            date: (year) => new Date(year, 10, 1),
            name: "Allerheiligen",
            states: ["BW", "BY", "NW", "RP", "SL", "AB"],
        },
        {
            date: (year) => getPrayerDay(year),
            name: "Buß- und Bettag",
            states: ["SN"],
        },
        {
            date: (year) => new Date(year, 11, 24),
            name: "Heilig Abend",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
            noRealHoliday: true,
        },
        {
            date: (year) => new Date(year, 11, 25),
            name: "1. Weihnachtsfeiertag",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
            special: true,
        },
        {
            date: (year) => new Date(year, 11, 26),
            name: "2. Weihnachtsfeiertag",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
            special: true,
        },
        {
            date: (year) => new Date(year, 11, 31),
            name: "31. Dezember",
            states: [
                "BW",
                "BY",
                "BE",
                "BB",
                "HB",
                "HH",
                "HE",
                "MV",
                "NI",
                "NW",
                "RP",
                "SL",
                "SN",
                "ST",
                "SH",
                "TH",
                "AB",
            ],
            noRealHoliday: true,
        },
    ],
    AT: [
        {
            date: (year: number) => new Date(year, 0, 1),
            name: "Neujahr",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year: number) => new Date(year, 0, 6),
            name: "Heilige Drei Könige",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year) => getRelativeHoliday(year, 1),
            name: "Ostermontag",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year: number) => new Date(year, 4, 1),
            name: "Staatsfeiertag",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year) => getRelativeHoliday(year, 39),
            name: "Christi Himmelfahrt",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year) => getRelativeHoliday(year, 50),
            name: "Pfingstmontag",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year) => getRelativeHoliday(year, 60),
            name: "Fronleichnam",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year: number) => new Date(year, 7, 15),
            name: "Maria Himmelfahrt",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year: number) => new Date(year, 9, 26),
            name: "Nationalfeiertag",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year: number) => new Date(year, 10, 1),
            name: "Allerheiligen",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year: number) => new Date(year, 11, 8),
            name: "Maria Empfängnis",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year: number) => new Date(year, 11, 25),
            name: "Christtag",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        {
            date: (year: number) => new Date(year, 11, 26),
            name: "Stefanitag",
            states: ["AT-1", "AT-2", "AT-3", "AT-4", "AT-5", "AT-6", "AT-7", "AT-8", "AT-9"],
        },
        // Regional holidays
        {
            date: (year: number) => new Date(year, 2, 19),
            name: "Josefstag",
            states: ["AT-2", "AT-6", "AT-7", "AT-8"],
        },
        {
            date: (year: number) => new Date(year, 8, 24),
            name: "Rupertitag",
            states: ["AT-5"],
        },
        {
            date: (year: number) => new Date(year, 9, 10),
            name: "Tag der Volksabstimmung",
            states: ["AT-2"],
        },
        {
            date: (year: number) => new Date(year, 10, 11),
            name: "Martinstag",
            states: ["AT-1"],
        },
        {
            date: (year: number) => new Date(year, 10, 15),
            name: "Leopolditag",
            states: ["AT-3", "AT-9"],
        },
    ],
};

export type HolidayName = (typeof HOLIDAYS)["DE" | "AT"][number]["name"];
