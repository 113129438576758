import { css, unsafeCSS } from "lit";

export const fullbleed = () => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
`;

export const scroll = () => css`
    overflow: auto;
    -webkit-overflow-scrolling: touch;
`;

export const ellipsis = () => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const centerContent = () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const hover = (selector: string) => css`
    ${unsafeCSS(selector)}:hover {
        box-shadow: inset var(--shade-1) 0 0 0 1000px;
    }
`;

export const click = (selector: string) => css`
    ${unsafeCSS(selector)} {
        cursor: pointer;
        user-select: none;
    }

    ${unsafeCSS(selector)}:active {
        box-shadow: inset var(--shade-2) 0 0 0 1000px;
    }
`;

export const customScrollbar = (selector: string = "") => css`
    ${unsafeCSS(selector)}::-webkit-scrollbar {
        width: var(--scrollbar-width, 0.8em);
        height: var(--scrollbar-width, 0.8em);
    }

    ${unsafeCSS(selector)}::-webkit-scrollbar-track {
        background-color: var(--shade-1);
        border-radius: var(--scrollbar-width, 0.8em);
        border: solid var(--scrollbar-margin, 0.2em) transparent;
        background-clip: padding-box;
    }

    ${unsafeCSS(selector)}::-webkit-scrollbar-thumb {
        background-color: var(--shade-2);
        border-radius: var(--scrollbar-width, 0.8em);
        border: solid var(--scrollbar-margin, 0.2em) transparent;
        background-clip: padding-box;
        transition: all 0.5s;
    }

    ${unsafeCSS(selector)}::-webkit-scrollbar-thumb:hover {
        border-width: 1px;
        background-color: var(--shade-4);
    }
`;

export const hideScrollbar = (selector: string = "") => css`
    ${unsafeCSS(selector)}::-webkit-scrollbar {
        display: none;
    }
`;
