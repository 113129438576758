import { formatNumber } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
@customElement("ptc-balance")
export class Balance extends LitElement {
    @property({ type: Number })
    value = 0;

    @property({ type: Number })
    decimals = 2;

    @property()
    icons: "plus-minus" | "up-down" = "plus-minus";

    @property({ attribute: false })
    format?: (value: number) => string;

    @property()
    icon = "";

    createRenderRoot() {
        return this;
    }

    static styles = css`
        ptc-balance {
            display: inline-flex;
            align-items: center;
        }

        ptc-balance.negative {
            color: var(--color-negative);
        }

        ptc-balance.positive {
            color: var(--color-positive);
        }

        ptc-balance i {
            font-size: 0.7em;
            position: relative;
        }
    `;

    updated() {
        this.classList.toggle("negative", this.value < 0);
        this.classList.toggle("positive", this.value > 0);
    }

    render() {
        return html`
            ${this.value
                ? html`<i
                      class="${this.icons === "plus-minus"
                          ? this.value < 0
                              ? "minus"
                              : this.value > 0
                                ? "plus"
                                : "circle"
                          : this.value < 0
                            ? "arrow-down"
                            : this.value > 0
                              ? "arrow-up"
                              : "arrow-right"}"
                  ></i>`
                : ""}
            <span> ${this.format?.(Math.abs(this.value)) || formatNumber(Math.abs(this.value), this.decimals)} </span>
            ${this.icon ? html` <i class="${this.icon}"></i> ` : ""}
        `;
    }
}
