import { API, Constructor } from "./api";
import { Session } from "./model";
import { Sender, Request, Response, ClientInfo } from "./transport";
import { Err, ErrorCode } from "./error";
import { serialize, deserialize, deserializeArray } from "./encoding";
import { CryptoProvider } from "./crypto";

export class Client extends API {
    constructor(
        private sender: Sender,
        private crypto: CryptoProvider,
        private getClientInfo?: () => ClientInfo | undefined
    ) {
        super();

        for (const { method, output } of this.handlerDefinitions) {
            (this as unknown as Record<string, Function>)[method] = (input: Constructor | undefined) => {
                const promise = this.call(method, input ? [serialize(input)] : input).then((res) => {
                    return res.result && output
                        ? Array.isArray(res.result)
                            ? deserializeArray(output, res.result)
                            : deserialize(output, res.result)
                        : res.result;
                });
                return promise;
            };
        }
    }

    hook?: (req: Request, res: Response | null, err: Err | null) => void;

    session: Session | null;

    async call(method: string, params?: Record<string, unknown>[]) {
        const { session } = this;

        const req: Request = { method, params, id: await this.crypto.uuid() };

        req.clientInfo = this.getClientInfo?.();

        if (session) {
            req.auth = { session: session.id, token: session.token };
        }

        let res;

        try {
            res = await this.sender.send(req);
        } catch (e) {
            this.hook && this.hook(req, null, e);
            throw e;
        }

        if (res.error) {
            const err = new Err(res.error.code as unknown as ErrorCode, res.error.message);
            this.hook && this.hook(req, null, err);
            throw err;
        }

        this.hook && this.hook(req, res, null);

        return res;
    }
}
