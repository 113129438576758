import { getMonday, toDateString, parseDateString, getRange, dateAdd } from "@pentacode/core/src/util";
import { classMap } from "lit/directives/class-map.js";
import { LitElement, html, css, TemplateResult } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { shared, mixins } from "../styles";
import { MonthPicker } from "./month-picker";
import { DateString } from "@pentacode/openapi/src/units";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

@customElement("ptc-date-picker")
export class DatePicker extends LitElement {
    @property({ reflect: true })
    mode: "day" | "week" | "month" = "day";

    @property({ type: Boolean })
    displayCurrentWeekOnly = false;

    @property({ type: Boolean })
    displayCurrentMonthOnly = false;

    @property({ type: Number })
    minYear: number = currentYear - 1;

    @property({ type: Number })
    maxYear: number = currentYear + 1;

    @property({ type: Number })
    year: number = currentYear;

    @property({ type: Number })
    month: number = currentMonth;

    @property({ type: Number })
    day: number | null = null;

    @property({ attribute: false })
    min?: DateString;

    @property({ attribute: false })
    max?: DateString;

    @property()
    displayRangeWith?: string;

    @property()
    badge?: (date: string) => { class?: string; icon?: string; text?: string } | undefined;

    @property({ attribute: false })
    get date() {
        return this.day ? new Date(this.year || 0, this.month || 0, this.day) : null;
    }
    set date(date: Date | null) {
        const oldValue = this.date;
        if (date) {
            this.year = date.getFullYear();
            this.month = date.getMonth();
            this.day = date.getDate();
        } else {
            let date = toDateString(new Date());
            if (this.min && date < this.min) {
                date = this.min;
            } else if (this.max && date > this.max) {
                date = this.max;
            }
            const d = parseDateString(date)!;
            this.year = d?.getFullYear();
            this.month = d?.getMonth();
            this.day = null;
        }
        this.requestUpdate("date", oldValue);
    }

    @property({ attribute: false })
    get value(): DateString | null {
        return this.date ? toDateString(this.date) : null;
    }
    set value(str: DateString | null) {
        const oldValue = this.value;
        this.date = str ? parseDateString(str) : null;
        this.requestUpdate("value", oldValue);
    }

    get range() {
        return this.value ? getRange(this.value, this.mode) : null;
    }

    @query("ptc-month-picker")
    private _monthPicker: MonthPicker;

    private _fireChange() {
        this.dispatchEvent(
            new CustomEvent("change", {
                detail: {
                    date: this.date,
                    range: this.range,
                    value: this.value,
                },
            })
        );
    }

    private _selectDate(year: number, month: number, day?: number, e?: MouseEvent) {
        e && e.stopPropagation();
        const backward = year * 12 + month < this.year * 12 + this.month;
        const lastOfMonth = parseDateString(
            dateAdd(getRange(new Date(year, month), "month").to, { days: -1 })
        )!.getDate();
        day = day || (backward ? lastOfMonth : 1);

        if (this.min && toDateString(new Date(year, month, day)) < this.min) {
            const date = new Date(this.min);
            year = date.getFullYear();
            month = date.getMonth();
            day = date.getDate();
        }
        if (this.max && toDateString(new Date(year, month, day)) > this.max) {
            const date = new Date(this.max);
            year = date.getFullYear();
            month = date.getMonth();
            day = date.getDate();
        }

        this.day = day;
        this.month = month;
        this.year = year;
        this._fireChange();
    }

    private _monthSelected(e: Event) {
        e && e.stopPropagation();
        this._selectDate(this._monthPicker.year, this._monthPicker.month);
    }

    updated(changes: Map<string, any>) {
        if (changes.has("month") || changes.has("year")) {
            this._monthPicker.month = this.month;
            this._monthPicker.year = this.year;
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                position: relative;
                text-align: left;
                display: flex;
                justify-content: center;
            }

            .week,
            .week-header {
                display: flex;
            }

            .month-header button {
                padding: 0.2em 0.1em;
            }

            .month-header {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2em;
            }

            .month-select {
                font-weight: 600;
            }

            .month-select {
                padding: 0.2em 0.5em;
                border: none;
                text-align: center;
                text-align-last: center;
            }

            .day {
                position: relative;
            }

            .day,
            .week-header > * {
                display: inline-block;
                width: 2em;
                height: 2em;
                line-height: 2em;
                text-align: center;
                font-size: 1em;
            }

            .day.today {
                font-weight: bold;
            }

            .day.othermonth {
                opacity: 0.5;
            }

            .day.range::before,
            .day.rangeStart::before,
            .day.rangeEnd::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 0.1em;
                bottom: 0.1em;
                background: var(--color-primary);
                opacity: 0.2;
            }

            .day.rangeStart::before {
                border-radius: 0.5em 0 0 0.5em;
            }

            .day.rangeEnd::before {
                border-radius: 0 0.5em 0.5em 0;
            }

            .week-header {
                font-weight: 600;
                color: var(--color-primary);
            }

            .month,
            .week,
            .day {
                border-radius: var(--border-radius);
            }

            .month {
                border: solid 2px transparent;
                scroll-snap-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            ${mixins.click(':host([mode="month"]) .month')}
            ${mixins.click(':host([mode="week"]) .week')}
            ${mixins.click(':host([mode="day"]) .day')}
            ${mixins.hover(':host([mode="month"]) .month')}
            ${mixins.hover(':host([mode="week"]) .week')}
            ${mixins.hover(':host([mode="day"]) .day')}

            :host([mode="week"]) .week.selected,
            :host([mode="day"]) .day.selected,
            :host([mode="month"]) .month.selected {
                background: var(--color-primary);
                color: var(--color-bg);
            }

            :host([mode="month"]) .month.selected .week-header {
                color: inherit;
                font-weight: bold;
            }

            .badge {
                border-radius: calc(2 * var(--border-radius));
                background: var(--color-highlight);
                color: var(--color-bg);
                position: absolute;
                right: -0.2em;
                bottom: -0.2em;
                padding: 0.2em;
                line-height: normal;
                font-size: 0.6em;
            }

            @media (hover: hover) {
                .month:not(:hover) .month-header button {
                    visibility: hidden;
                }
            }
        `,
    ];

    private _renderMonth(year: number, month: number) {
        const day = parseDateString(getMonday(toDateString(new Date(year, month, 1))))!;
        const lastMonday = parseDateString(getMonday(toDateString(new Date(year, month + 1, 0))))!;
        const weeks: TemplateResult[] = [];
        const today = new Date();
        const monthSelected = year === this.year && month === this.month;

        while (day <= lastMonday) {
            const weekSelected = !!this.value && getMonday(toDateString(day)) === getMonday(this.value);
            const days: TemplateResult[] = [];

            for (let i = 0; i < 7; i++) {
                const y = day.getFullYear();
                const m = day.getMonth();
                const d = day.getDate();
                const date = toDateString(day);
                const badge = this.badge && this.badge(date);
                const isInRange =
                    !!this.displayRangeWith &&
                    !!this.value &&
                    (this.displayRangeWith > this.value
                        ? date > this.value && date <= this.displayRangeWith
                        : date < this.value && date >= this.displayRangeWith);
                const isRangeStart =
                    !!this.displayRangeWith &&
                    !!this.value &&
                    (this.displayRangeWith > this.value ? date === this.value : date === this.displayRangeWith);
                const isRangeEnd =
                    !!this.displayRangeWith &&
                    !!this.value &&
                    (this.displayRangeWith < this.value ? date === this.value : date === this.displayRangeWith);
                days.push(html`
                    <div
                        class="day ${classMap({
                            today: y === currentYear && m === currentMonth && d === today.getDate(),
                            othermonth: m !== month,
                            selected: monthSelected && y === this.year && m === this.month && d === this.day,
                            range: isInRange,
                            rangeStart: isRangeStart,
                            rangeEnd: isRangeEnd,
                        })}"
                        ?disabled=${(this.displayCurrentMonthOnly && m !== month) ||
                        (!!this.min && date < this.min) ||
                        (!!this.max && date > this.max)}
                        @click=${(e: MouseEvent) => this._selectDate(y, m, d, e)}
                    >
                        ${d}
                        ${badge
                            ? html`
                                  <div class="badge ${badge.class}">
                                      ${badge.icon ? html` <i class="icon ${badge.icon}"></i> ` : ""} ${badge.text}
                                  </div>
                              `
                            : ""}
                    </div>
                `);
                day.setDate(day.getDate() + 1);
            }

            weeks.push(html`
                <div
                    class="week ${classMap({ selected: monthSelected && weekSelected })}"
                    ?hidden=${this.displayCurrentWeekOnly && !weekSelected}
                >
                    ${days}
                </div>
            `);
        }

        const currYear = new Date().getFullYear();
        const years: number[] = [];
        for (let year = currYear - 10; year <= currYear + 1; year++) {
            years.push(year);
        }

        return html`
            <div
                id="month-${year}-${month}"
                class="month ${classMap({
                    selected: monthSelected,
                })}"
            >
                <ptc-month-picker
                    @change=${this._monthSelected}
                    ?hidden=${this.displayCurrentWeekOnly || this.displayCurrentMonthOnly}
                    .min=${this.min}
                    .max=${this.max}
                ></ptc-month-picker>

                <div class="week-header">
                    ${["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"].map((d) => html` <div>${d}</div> `)}
                </div>
                ${weeks}
            </div>
        `;
    }

    render() {
        return html` ${this._renderMonth(this.year, this.month)} `;
    }
}
