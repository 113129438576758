import { bytesToBase32, bytesToHex } from "./encoding";

export abstract class CryptoProvider {
    abstract randomBytes(n: number): Promise<Uint8Array>;
    abstract pbkdf2(pwd: string, salt: Uint8Array, iter: number): Promise<Uint8Array>;
    abstract toBase64(bytes: Uint8Array | string, urlSafe?: boolean): string;
    abstract fromBase64(str: string): string;

    abstract hmac(key: string, input: string): Promise<string>;

    /** Generates a random UUID v4 */
    async uuid(): Promise<string> {
        const bytes = await this.randomBytes(16);

        // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
        bytes[6] = (bytes[6] & 0x0f) | 0x40;
        bytes[8] = (bytes[8] & 0x3f) | 0x80;

        // Canonical representation
        // XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
        return [
            bytesToHex(bytes.slice(0, 4)),
            "-",
            bytesToHex(bytes.slice(4, 6)),
            "-",
            bytesToHex(bytes.slice(6, 8)),
            "-",
            bytesToHex(bytes.slice(8, 10)),
            "-",
            bytesToHex(bytes.slice(10, 16)),
        ].join("");
    }

    async randomHex(n: number) {
        return bytesToHex(await this.randomBytes(n));
    }

    async randomBase32(n: number) {
        return bytesToBase32(await this.randomBytes(n));
    }
}
