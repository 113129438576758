import { monthNames, parseDateString, toDateString } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { shared } from "../styles";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

@customElement("ptc-month-picker")
export class MonthPicker extends LitElement {
    @property({ type: Number })
    minYear: number = currentYear - 5;

    @property({ type: Number })
    maxYear: number = currentYear + 1;

    @property({ type: Number })
    year: number = currentYear;

    @property({ type: Number })
    month: number = currentMonth;

    @property()
    min?: string;

    @property()
    max?: string;

    @property()
    get date() {
        return toDateString(new Date(this.year, this.month, 1));
    }
    set date(d: string) {
        const date = parseDateString(d)!;
        this.year = date.getFullYear();
        this.month = date.getMonth();
    }

    private _fireChange() {
        this.dispatchEvent(
            new CustomEvent("change", {
                detail: {
                    year: this.year,
                    month: this.month,
                    date: this.date,
                },
            })
        );
    }

    private _selectMonth(year: number, month: number) {
        this.year = year;
        this.month = month;
        this._fireChange();
    }

    private _getPreviousMonth(
        { year, month }: { year: number; month: number } = { year: this.year, month: this.month }
    ) {
        return month === 0 ? { year: year - 1, month: 11 } : { year, month: month - 1 };
    }

    private _getNextMonth({ year, month }: { year: number; month: number } = { year: this.year, month: this.month }) {
        return month === 11 ? { year: year + 1, month: 0 } : { year, month: month + 1 };
    }

    private _monthSelected(e: Event) {
        const select = e.target as HTMLSelectElement;
        const [year, month] = select.value.split("-");
        this._selectMonth(Number(year), Number(month));
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                display: inline-flex;
                align-items: center;
                font-size: inherit;
            }

            button {
                padding: 0.2em 0.1em;
            }

            .month-select {
                font-weight: 600;
                flex: 1;
                min-width: 0;
            }

            .month-select {
                padding: 0.4em 0.5em;
                border: none;
                text-align: center;
                text-align-last: center;
            }
        `,
    ];

    render() {
        const years: number[] = [];
        for (let year = currentYear - 5; year <= currentYear + 1; year++) {
            years.push(year);
        }
        const previousMonth = this._getPreviousMonth();
        const nextMonth = this._getNextMonth();
        return html`
            <button
                class="transparent icon"
                @click=${() => this._selectMonth(previousMonth.year, previousMonth.month)}
                ?disabled=${!!this.min &&
                toDateString(new Date(previousMonth.year, previousMonth.month + 1, 0)) < this.min}
            >
                <i class="angle-left"></i>
            </button>
            <select
                class="month-select plain click"
                @change=${this._monthSelected}
                .value="${this.year}-${this.month.toString().padStart(2, "0")}"
            >
                ${years
                    .map((year) =>
                        monthNames.map(
                            (name, month) => html`
                                <option
                                    .value="${year}-${month.toString().padStart(2, "0")}"
                                    ?selected=${this.year === year && this.month === month}
                                    ?disabled=${(!!this.min && toDateString(new Date(year, month + 1, 0)) < this.min) ||
                                    (!!this.max && toDateString(new Date(year, month, 1)) > this.max)}
                                >
                                    ${name} ${year}
                                </option>
                            `
                        )
                    )
                    .flat()}
            </select>
            <button
                class="transparent icon"
                @click=${() => this._selectMonth(nextMonth.year, nextMonth.month)}
                ?disabled=${!!this.max && toDateString(new Date(previousMonth.year, previousMonth.month, 1)) > this.max}
            >
                <i class="angle-right"></i>
            </button>
        `;
    }
}
