// import { Spec } from "./openapi/types";

import { BreakMode, EmployeeStatus, EmploymentType, Gender, TaxKey } from "../model";
import { Models } from "@pentacode/openapi";

const api = {
    openapi: "3.0.1",
    info: {
        title: "Pentacode API",
        version: "1.0",
    },
    servers: [
        { url: "https://api.pentacode.app/rest/v1" },
        { url: "https://api.dev.pentacode.app/rest/v1" },
        { url: "http://localhost:3005/rest/v1" },
    ],
    security: [{ BearerAuth: [] }],
    components: {
        securitySchemes: {
            BearerAuth: {
                type: "http",
                scheme: "bearer",
            },
        },
        parameters: {
            DateFrom: {
                name: "from",
                in: "query",
                required: true,
                schema: {
                    type: "string",
                    format: "date",
                },
                description:
                    "Lower bound for the date range to fetch data for. " +
                    "This is value is **inclusive**, meaning items with dates " +
                    "**equal or higher** to this value will be included.",
            },
            DateTo: {
                name: "to",
                in: "query",
                required: true,
                schema: {
                    type: "string",
                    format: "date",
                },
                description:
                    "Upper bound for the date range to fetch data for. " +
                    "This is value is **exclusive**, meaning only items with dates " +
                    "**lower** than this value will be included.",
            },
            TimeFrom: {
                name: "from",
                in: "query",
                required: true,
                schema: {
                    type: "string",
                    format: "date-time",
                },
                description: "Lower bound for the time range to fetch data for.",
            },
            TimeTo: {
                name: "to",
                in: "query",
                required: true,
                schema: {
                    type: "string",
                    format: "date-time",
                },
                description: "Upper bound for the time-range to fetch data for.",
            },
            ListOffset: {
                name: "offset",
                in: "query",
                required: false,
                schema: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                },
                description:
                    "Number of items to skip when fetching lists. In combination " +
                    "with the `limit` property, this can be used for pagination.",
            },
            ListLimit: {
                name: "limit",
                in: "query",
                required: false,
                schema: {
                    type: "integer",
                    maximum: 1000,
                    default: 100,
                },
                description:
                    "Maximum number of items to return when fetching lists. In combination " +
                    "with the `offset` property, this can be used for pagination.",
            },
            Venue: {
                name: "venueId",
                in: "query",
                schema: { type: "integer" },
                required: true,
                description: "The id of the venue to fetch data for.",
            },
            Filters: {
                name: "filters",
                in: "query",
                content: {
                    "application/json": {
                        schema: { type: "array", items: { $ref: "#/components/schemas/EntityFilter" } },
                    },
                },
            },
            Resolution: {
                name: "resolution",
                in: "query",
                schema: { type: "string", enum: ["year", "month", "week", "day"], default: "month" },
                description: "The resolution of the time periods returned.",
            },
        },
        schemas: {
            EntityFilter: {
                type: "object",
                description: "An object representing a filter which can be applied to an employee and or shift.",
                oneOf: [
                    {
                        properties: {
                            type: {
                                enum: ["venue", "department", "position", "employeeId", "staffNumber", "employeeTag"],
                            },
                            value: { type: "integer" },
                        },
                        required: ["type", "value"],
                        additionalProperties: false,
                    },
                    {
                        properties: {
                            type: { enum: ["employmentType"] },
                            value: {
                                enum: [
                                    EmploymentType.Regular,
                                    EmploymentType.Marginal,
                                    EmploymentType.Independent,
                                    EmploymentType.ShortTerm,
                                    EmploymentType.Trainee,
                                    EmploymentType.MidiJob,
                                    EmploymentType.WorkingStudent,
                                    EmploymentType.DualStudent,
                                    EmploymentType.Intern,
                                ],
                            },
                        },
                        required: ["type", "value"],
                        additionalProperties: false,
                    },
                    {
                        properties: {
                            type: { enum: ["employeeStatus"] },
                            value: { $ref: "#/components/schemas/EmployeeStatus" },
                        },
                        required: ["type", "value"],
                        additionalProperties: false,
                    },
                    {
                        properties: {
                            type: { enum: ["costCenter"] },
                            value: { type: "string" },
                        },
                        required: ["type", "value"],
                        additionalProperties: false,
                    },
                ],
            },
            Scope: {
                type: "string",
                description:
                    "A security scope. Scopes are used to specify which endpoints and permissions are available " +
                    "to a given API client and can also be used to restrict access for a particular auth token.",
            },
            AuthToken: {
                type: "object",
                properties: {
                    id: { type: "string", format: "uuid" },
                    token: { type: "string" },
                    expires: { type: "string", format: "date-time", nullable: true },
                    scopes: { type: "array", items: { $ref: "#/components/schemas/Scope" } },
                    clientId: { type: "string", format: "uuid" },
                    companyId: { type: "integer" },
                },
                required: ["id", "token", "expires"],
                description:
                    "A secret token used for authentication and authorization. Every auth token is restricted " +
                    "to a single company. Further access restrictions can be applied using the `scopes` parameter.",
            },
            Color: {
                type: "string",
                pattern: "^#([0-9A-Fa-f]{6})$",
                nullable: true,
                description: "The hexadecimal color code to denote the color of a department or position.",
            },
            Holidays: {
                type: "array",
                items: {
                    type: "string",
                },
            },
            Position: {
                type: "object",
                properties: {
                    id: { type: "integer" },
                    name: { type: "string", minLength: 1 },
                    color: { $ref: "#/components/schemas/Color" },
                },
                required: ["id", "name"],
                additionalProperties: false,
                description: "An object representing a specific position within a department.",
            },
            Department: {
                type: "object",
                properties: {
                    id: { type: "integer" },
                    name: { type: "string", minLength: 1 },
                    color: {
                        type: "string",
                        pattern: "^#([0-9A-Fa-f]{6})$",
                        description: "The hexadecimal color code to denote the color of a department or position.",
                    },
                    positions: { type: "array", items: { $ref: "#/components/schemas/Position" } },
                    timeScheduleEmployeeOrder: { type: "array", items: { type: "integer" } },
                },
                required: ["id", "name", "positions", "timeScheduleEmployeeOrder"],
                additionalProperties: false,
                description: "An object representing a department within a venue.",
            },
            Venue: {
                type: "object",
                properties: {
                    id: { type: "integer" },
                    name: { type: "string", minLength: 1 },
                    venueNumber: { type: "integer", nullable: true },
                    departments: { type: "array", items: { $ref: "#/components/schemas/Department" } },
                    holidays: {
                        type: "array",
                        items: { type: "string" },
                    },
                },
                required: ["id", "name", "departments", "holidays"],
                additionalProperties: false,
                description: "An object representing a venue within a company.",
            },
            EmployeeStatus: {
                type: "string",
                enum: [EmployeeStatus.Active, EmployeeStatus.Probation, EmployeeStatus.Retired],
            },
            Employee: {
                type: "object",
                properties: {
                    id: { type: "integer" },
                    firstName: { type: "string", minLength: 1 },
                    lastName: { type: "string", minLength: 1 },
                    callName: { type: "string", nullable: true },
                    address: { type: "string", nullable: true },
                    city: { type: "string", nullable: true },
                    postalCode: { type: "string", nullable: true },
                    email: {
                        type: "string",
                        format: "email",
                        nullable: true,
                    },
                    phone: { type: "string", nullable: true },
                    phone2: { type: "string", nullable: true },
                    birthday: { type: "string", format: "date", nullable: true },
                    birthName: { type: "string", nullable: true },
                    birthCity: { type: "string", nullable: true },
                    birthCountry: { type: "string", nullable: true },
                    nationality: { type: "string", nullable: true },
                    gender: { $ref: "#/components/schemas/Gender" },
                    taxId: { type: "string", nullable: true },
                    socialSecurityNumber: { type: "string", nullable: true },
                    notes: { type: "string", nullable: true },
                    staffNumber: { type: "string", pattern: "[0-9]{1,9}", nullable: true },
                    avatar: { type: "string", nullable: true },
                    positions: {
                        type: "array",
                        items: { $ref: "#/components/schemas/Position" },
                    },
                    timeLogPin: {
                        type: "string",
                        nullable: true,
                        pattern: "[0-9]{4}",
                        description:
                            "A 4-digit PIN code used by the employee to authenticate and identify " +
                            "themselves when triggering time tracking actions(start shift, end shift etc.).",
                    },
                    status: { $ref: "#/components/schemas/EmployeeStatus" },
                },
                required: ["id", "firstName", "lastName"],
                additionalProperties: false,
                description: "An object representing an employee within a company.",
            },
            Gender: {
                enum: [Gender.Female, Gender.Male, Gender.NonBinary, Gender.Undetermined],
            },
            BreakMode: {
                enum: [
                    BreakMode.Auto,
                    BreakMode.Manual,
                    BreakMode.AutoPlusManual,
                    BreakMode.Planned,
                    BreakMode.PlannedPlusManual,
                    BreakMode.AutoOrManual,
                    BreakMode.PlannedOrManual,
                ],
            },
            AutoBreak: {
                type: "object",
                properties: {
                    duration: { type: "number", unit: "hours" },
                    amount: { type: "number", unit: "hours" },
                },
                required: ["duration", "amount"],
                additionalProperties: false,
                description:
                    "An object representing an automatic break rule, that applies a certain amount of break time after a specified duration.",
            },
            Company: {
                type: "object",
                properties: {
                    id: { type: "integer" },
                    name: { type: "string", minLength: 1 },
                    venues: { type: "array", items: { $ref: "#/components/schemas/Venue" } },
                    employeeAppTimeLogUrl: { type: "string", format: "url", nullable: true },
                },
                required: ["id", "name", "venues"],
                additionalProperties: false,
                description: "An object representing a company",
            },
            TimeEntryType: {
                enum: ["work", "sick", "child_sick", "sick_in_kug", "vacation", "compday", "free"],
                description:
                    "The type of a time entry, specifying whether it represents a work shift " +
                    "or a specific kind of absence day.",
            },
            TimeEntry: {
                type: "object",
                properties: {
                    id: {
                        type: "string",
                        format: "uuid",
                    },
                    date: {
                        type: "string",
                        format: "date",
                    },
                    startPlanned: { type: "string", format: "date-time", nullable: true },
                    endPlanned: { type: "string", format: "date-time", nullable: true },
                    startLogged: { type: "string", format: "date-time", nullable: true },
                    endLogged: { type: "string", format: "date-time", nullable: true },
                    startFinal: { type: "string", format: "date-time", nullable: true },
                    endFinal: { type: "string", format: "date-time", nullable: true },
                    position: { $ref: "#/components/schemas/Position", nullable: true },
                    published: { $ref: "#/components/schemas/TimeEntryPublishedInfo", nullable: true },
                    seen: { $ref: "#/components/schemas/TimeEntryPublishedInfo", nullable: true },
                    status: { enum: ["scheduled", "ongoing", "paused", "completed"] },
                    currentBreakStart: { type: "string", format: "date-time", nullable: true },
                    type: { $ref: "#/components/schemas/TimeEntryType" },
                    employeeId: { type: "integer", nullable: true },
                    comment: { type: "string" },
                    breakPlanned: { type: "number", unit: "hours" },
                    breakLogged: { type: "number", unit: "hours" },
                    breakFinal: { type: "number", unit: "hours" },
                    result: { $ref: "#/components/schemas/TimeResult" },
                    mealsBreakfast: { type: "integer", unit: "meals" },
                    mealsLunch: { type: "integer", unit: "meals" },
                    mealsDinner: { type: "integer", unit: "meals" },
                },
                required: [
                    "id",
                    "date",
                    "type",
                    "startPlanned",
                    "endPlanned",
                    "startLogged",
                    "endLogged",
                    "startFinal",
                    "endFinal",
                    "status",
                    "currentBreakStart",
                    "employeeId",
                    "position",
                ],
                additionalProperties: false,
                description: "An object representing a shift or absence day.",
            },
            TimeEntryPublishedInfo: {
                type: "object",
                properties: {
                    time: { type: "string", format: "date-time" },
                    deleted: { type: "string", format: "date-time", nullable: true },
                    employeeId: { type: "integer", nullable: true },
                    date: { type: "string", format: "date" },
                    position: { $ref: "#/components/schemas/Position", nullable: true },
                    startPlanned: { type: "string", format: "date-time", nullable: true },
                    endPlanned: { type: "string", format: "date-time", nullable: true },
                    comment: { type: "string" },
                },
                required: ["time", "deleted", "employeeId", "date", "position", "startPlanned", "endPlanned"],
                additionalProperties: false,
                description:
                    "An object representing the published information about a time entry as opposed to edited time entry information",
            },
            CostCenter: {
                type: "object",
                properties: {
                    name: { type: "string" },
                    number: { type: "string" },
                },
                required: ["name", "number"],
                additionalProperties: false,
            },
            TimeSchedule: {
                type: "object",
                properties: {
                    id: { type: "string" },
                    companyId: { type: "integer" },
                    from: { type: "string", format: "date" },
                    to: { type: "string", format: "date" },
                    url: { type: "string", format: "url" },
                    types: { type: "array", items: { $ref: "#/components/schemas/TimeEntryType" } },
                    mirrorShifts: { type: "boolean" },
                    employees: { type: "array", items: { $ref: "#/components/schemas/Employee" } },
                    venues: { type: "array", items: { $ref: "#/components/schemas/Venue" } },
                    timeEntries: { type: "array", items: { $ref: "#/components/schemas/TimeEntry" } },
                    holidays: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                date: { type: "string", format: "date" },
                                name: { type: "string" },
                            },
                            required: ["date", "name"],
                            additionalProperties: false,
                        },
                    },
                    filters: {
                        type: "array",
                        items: { $ref: "#/components/schemas/EntityFilter" },
                    },
                },
                required: ["id", "companyId", "from", "to", "url"],
                additionalProperties: false,
            },
            TimeResult: {
                type: "object",
                description:
                    "Contains various 'results' like duration, bonuses and overall costs for a given time entry.",
                properties: {
                    base: {
                        type: "object",
                        properties: {
                            duration: {
                                type: "number",
                                unit: "hours",
                                description: "The base duration in hours.",
                            },
                            wages: {
                                type: "number",
                                unit: "euros",
                                description: "Base wages (hourly rate times base duration) in Euros.",
                            },
                            ancillaryCosts: {
                                type: "number",
                                unit: "euros",
                                description: "Ancillary costs (Lohnnebenkosten) in Euros.",
                            },
                            hourlyRate: {
                                type: "number",
                                unit: "euros/hour",
                                description: "The hourly rate used to calculate the base wages.",
                            },
                        },
                        required: ["duration", "wages", "ancillaryCosts", "hourlyRate"],
                        additionalProperties: false,
                    },
                    breaks: {
                        type: "object",
                        properties: {
                            duration: {
                                type: "number",
                                unit: "hours",
                                description: "The total break duration in hours.",
                            },
                            wages: {
                                type: "number",
                                unit: "euros",
                                description: "Wages paid for breaks (if any), in Euros.",
                            },
                            ancillaryCosts: {
                                type: "number",
                                unit: "euros",
                                description:
                                    "Ancillary costs (Lohnnebenkosten) with respect to paid breaks (if any), in Euros.",
                            },
                            paidDuration: {
                                type: "number",
                                unit: "hours",
                                description: "The total paid break duration in hours.",
                            },
                            hourlyRate: {
                                type: "number",
                                unit: "euros/hour",
                                description: "The hourly rate used to calculate the wages for breaks.",
                            },
                        },
                        required: ["duration", "wages", "ancillaryCosts", "paidDuration", "hourlyRate"],
                        additionalProperties: false,
                    },
                    bonuses: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                type: {
                                    type: "object",
                                    properties: {
                                        id: { type: "number" },
                                        name: { type: "string" },
                                    },
                                    required: ["id", "name"],
                                    additionalProperties: false,
                                },
                                duration: {
                                    type: "number",
                                    unit: "hours",
                                    description: "The duration spent in the bonus time window in hours.",
                                },
                                wages: {
                                    type: "number",
                                    unit: "euros",
                                    description:
                                        "Additional wages received for work in bonus-related time periods, in Euros.",
                                },
                                ancillaryCosts: {
                                    type: "number",
                                    unit: "euros",
                                    description:
                                        "Ancillary costs (Lohnnebenkosten) with respect to bonus wages (if any), in Euros.",
                                },
                                bonusId: { type: "number" },
                                taxFree: { type: "boolean" },
                            },
                            required: ["type", "duration", "wages", "ancillaryCosts", "bonusId", "taxFree"],
                            additionalProperties: false,
                        },
                    },
                    meals: {
                        type: "object",
                        properties: {
                            breakfast: {
                                type: "object",
                                properties: {
                                    count: {
                                        type: "integer",
                                        unit: "meals",
                                        description: "Number of meals received as part of this shift.",
                                    },
                                    value: {
                                        type: "number",
                                        unit: "euros/meal",
                                        description: "Cost per meal.",
                                    },
                                    costs: {
                                        type: "number",
                                        unit: "euros",
                                        description: "Associated costs with respect to meals received, in Euros.",
                                    },
                                },
                                required: ["count", "value", "costs"],
                                additionalProperties: false,
                            },
                            lunch: {
                                type: "object",
                                properties: {
                                    count: {
                                        type: "integer",
                                        unit: "meals",
                                        description: "Number of meals received as part of this shift.",
                                    },
                                    value: {
                                        type: "number",
                                        unit: "euros/meal",
                                        description: "Cost per meal.",
                                    },
                                    costs: {
                                        type: "number",
                                        unit: "euros",
                                        description: "Associated costs with respect to meals received, in Euros.",
                                    },
                                },
                                required: ["count", "value", "costs"],
                                additionalProperties: false,
                            },
                            dinner: {
                                type: "object",
                                properties: {
                                    count: {
                                        type: "integer",
                                        unit: "meals",
                                        description: "Number of meals received as part of this shift.",
                                    },
                                    value: {
                                        type: "number",
                                        unit: "euros/meal",
                                        description: "Cost per meal.",
                                    },
                                    costs: {
                                        type: "number",
                                        unit: "euros",
                                        description: "Associated costs with respect to meals received, in Euros.",
                                    },
                                },
                                required: ["count", "value", "costs"],
                                additionalProperties: false,
                            },
                        },
                        additionalProperties: false,
                    },
                    days: { type: "number", unit: "days" },
                    totalWages: { type: "number", unit: "euros" },
                    totalCosts: { type: "number", unit: "euros" },
                    costCenter: { $ref: "#/components/schemas/CostCenter" },
                },
                required: ["base", "breaks", "bonuses", "meals", "totalWages", "totalCosts", "days"],
                additionalProperties: false,
            },
            TimeLogDevice: {
                type: "object",
                properties: {
                    id: { type: "string", format: "uuid" },
                    description: { type: "string" },
                    image: { type: "string", format: "uri" },
                    filters: { type: "array", items: { $ref: "#/components/schemas/EntityFilter" } },
                    model: { type: "string", description: 'The device model (e.g.: "iPad")' },
                    lastActive: { type: "string", format: "date-time" },
                    connectionCode: {
                        type: "string",
                        description:
                            "Code which can be exchanged for an auth token and device id using the " +
                            "`/time/log/connect` endpoint.This code rotates after every use.",
                    },
                    displaySchedule: { type: "boolean" },
                    displayUpcoming: { type: "boolean" },
                    displayActive: { type: "boolean" },
                },
                required: ["id", "description", "displaySchedule", "displayUpcoming", "displayActive"],
                additionalProperties: false,
                description:
                    "A device or other kind of API client used to record attendance. Devices are used to " +
                    "make time log events more traceable by attributing them to a specific device. They can " +
                    "also be used to manage filters and other settings remotely without having to change these " +
                    "directly on the physical device.",
            },
            TimeLogSettings: {
                type: "object",
                properties: {
                    allowEarlyStart: {
                        type: "number",
                        unit: "hours",
                        description:
                            "Number of hours that the employee is allowed to start before the scheduled start.",
                    },
                    lockAfter: {
                        type: "number",
                        unit: "hours",
                        description:
                            "Duration in hours after which no more time tracking actions are allowed, measured " +
                            "from the beginning of the shift(actual, not planned).",
                    },
                    shiftStartRounding: {
                        type: "number",
                        unit: "minutes",
                        description:
                            "The rounding interval for the start of the shift. If positive it will round up, if negative it will round down.",
                    },
                    shiftEndRounding: {
                        type: "number",
                        unit: "minutes",
                        description:
                            "The rounding interval for the end of the shift. If positive it will round up, if negative it will round down.",
                    },
                    useActualTimeAtShiftStart: {
                        type: "boolean",
                        description:
                            "In case of an early shift start, if false, the planned shift start will be used, if true, the logged shift start will be used.",
                    },
                    useActualTimeAtShiftEnd: {
                        type: "boolean",
                        description:
                            "In case of an late shift end, if false, the planned shift end will be used, if true, the logged shift end will be used.",
                    },
                    manualBreaksAllowed: {
                        type: "boolean",
                    },
                    requirePhotoFor: {
                        type: "array",
                        items: { $ref: "#/components/schemas/TimeLogAction" },
                    },
                    requireLocation: {
                        type: "boolean",
                    },
                    breakMode: { $ref: "#/components/schemas/BreakMode" },
                    autoBreaks: {
                        type: "array",
                        items: { $ref: "#/components/schemas/AutoBreak" },
                    },
                    displayShiftDetails: {
                        type: "boolean",
                    },
                    meals: {
                        type: "object",
                        properties: {
                            breakfast: {
                                type: "object",
                                properties: {
                                    start: { type: "string", format: "time" },
                                    end: { type: "string", format: "time" },
                                    available: { type: "boolean" },
                                },
                                required: ["start", "end", "available"],
                                additionalProperties: false,
                            },
                            lunch: {
                                type: "object",
                                properties: {
                                    start: { type: "string", format: "time" },
                                    end: { type: "string", format: "time" },
                                    available: { type: "boolean" },
                                },
                                required: ["start", "end", "available"],
                                additionalProperties: false,
                            },
                            dinner: {
                                type: "object",
                                properties: {
                                    start: { type: "string", format: "time" },
                                    end: { type: "string", format: "time" },
                                    available: { type: "boolean" },
                                },
                                required: ["start", "end", "available"],
                                additionalProperties: false,
                            },
                            minDuration: {
                                type: "number",
                                unit: "hours",
                            },
                        },
                        additionalProperties: false,
                    },
                },
                required: [
                    "allowEarlyStart",
                    "lockAfter",
                    "shiftStartRounding",
                    "shiftEndRounding",
                    "useActualTimeAtShiftStart",
                    "useActualTimeAtShiftEnd",
                    "manualBreaksAllowed",
                    "requirePhotoFor",
                    "requireLocation",
                    "meals",
                    "breakMode",
                    "autoBreaks",
                    "displayShiftDetails",
                ],
                additionalProperties: false,
                description: "A set of attendance tracking rules applying to a certain shift or position.",
            },
            TimeLogAction: {
                enum: ["startShift", "endShift", "startBreak", "endBreak"],
                description:
                    "Various actions available for time logging. Options are:\n" +
                    "- `startShift`: Start a planned shift\n" +
                    "- `endShift`: End a (previously started) shift\n" +
                    "- `startBreak`: Start a break\n" +
                    "- `endBreak`: End a (previously started) break" +
                    "- `eatBreakfast`: Book a meal (breakfast)" +
                    "- `eatLunch`: Book a meal (lunch)" +
                    "- `eatDinner`: Book a meal (dinner)",
            },
            TimeLogOption: {
                type: "object",
                properties: {
                    employee: { $ref: "#/components/schemas/Employee" },
                    scheduled: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                settings: {
                                    $ref: "#/components/schemas/TimeLogSettings",
                                    description: "The settings and constraints that apply to this shift.",
                                },
                                timeEntry: {
                                    $ref: "#/components/schemas/TimeEntry",
                                    description:
                                        "The `TimeEntry` object corresponding the scheduled shift, if any. This property " +
                                        "is only present for scheduled shifts.",
                                },
                                availableActions: {
                                    type: "array",
                                    items: { $ref: "#/components/schemas/TimeLogAction" },
                                    description:
                                        "A list of available time tracking actions, **at the time of the request**. " +
                                        "Please note that available actions may change based on the time and any changes to " +
                                        "the settings or work schedule that may have occured since the time of the request.",
                                },
                            },
                            required: ["settings", "timeEntry", "availableActions"],
                            additionalProperties: false,
                        },
                    },
                    unscheduled: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                position: {
                                    $ref: "#/components/schemas/Position",
                                    description:
                                        "The position an unscheduled shift may be started for. Not present in " +
                                        "case of a scheduled shift.",
                                },
                                settings: {
                                    $ref: "#/components/schemas/TimeLogSettings",
                                    description: "The settings and constraints that apply to this position.",
                                },
                            },
                            required: ["position", "settings"],
                            additionalProperties: false,
                        },
                    },
                    displayStatistics: { type: "boolean" },
                },
                required: ["employee", "scheduled", "unscheduled", "displayStatistics"],
                additionalProperties: false,
            },
            Location: {
                type: "object",
                properties: {
                    latitude: { type: "number" },
                    longitude: { type: "number" },
                },
                required: ["latitude", "longitude"],
            },
            TimeLogEvent: {
                type: "object",
                properties: {
                    id: {
                        type: "string",
                    },
                    time: {
                        type: "string",
                        format: "date-time",
                        description: "The time at which the action happened.",
                    },
                    action: { $ref: "#/components/schemas/TimeLogAction" },
                    timeEntryId: {
                        type: "string",
                        format: "uuid",
                        description:
                            "The ID of the time entry this event was applied to. May be empty in case the event was rejected.",
                    },
                    employeeId: {
                        type: "integer",
                        description: "The employee id associated with this event.",
                    },
                    positionId: {
                        type: "integer",
                        description: "The position id associated with this event.",
                    },
                    status: {
                        enum: ["pending", "accepted", "rejected"],
                        description:
                            "The processing status of the event. Can be one of:\n" +
                            "- `pending`: The event has not been processed yet.\n" +
                            "- `accepted`: The event has not been processed and the corresponding changes applied successfully.\n" +
                            "- `rejected`: The event has not been processed but no changes where applied. Check the `rejectedReason` for why the event was rejected.\n",
                    },
                    rejectedReason: {
                        type: "string",
                        description:
                            "If the event was rejected (status `rejected`), this property contains the reason.",
                    },
                    deviceId: {
                        type: "string",
                        description: "The time logging device this event was sent from.",
                    },
                    location: {
                        $ref: "#/components/schemas/Location",
                        description: "The geographic location where the event occurred.",
                    },
                    image: {
                        type: "string",
                        description:
                            "An picture of the person at the moment of triggering the event. " +
                            "May be used to verify that the person was physically present.",
                    },
                    comment: {
                        type: "string",
                        description: "An optional comment by the employee.",
                    },
                    meals: {
                        type: "object",
                        properties: {
                            breakfast: { type: "boolean" },
                            lunch: { type: "boolean" },
                            dinner: { type: "boolean" },
                        },
                        additionalProperties: false,
                    },
                },
                required: ["id", "time", "action", "employeeId", "status"],
                additionalProperties: false,
                description:
                    "An object representing an event or action related to " +
                    "tracking work hours related to a specific shift.",
            },
            CreateTimeLogEventParams: {
                type: "object",
                properties: {
                    time: {
                        type: "string",
                        format: "date-time",
                        description:
                            "The date and time at which the action happened, in ISO 8601 format. " +
                            "**Important:** if no time zone info is provided, the time will be interpreted as UTC.",
                    },
                    action: { $ref: "#/components/schemas/TimeLogAction" },
                    timeEntryId: {
                        type: "string",
                        format: "uuid",
                        description:
                            "The ID of the time entry this action should apply to. If not provided, Pentacode will try to determine the appropriate shift automatically.",
                    },
                    employeeId: {
                        type: "integer",
                        description:
                            "The employee id to start a shift for. Only required if no `timeEntryId` is provided.",
                    },
                    staffNumber: {
                        description:
                            "The employees staff number, which can be used as a replacement for the `employeeId` if the latter is not known.",
                        oneOf: [
                            {
                                type: "string",
                                pattern: "[0-9]{1,9}",
                            },
                            { type: "integer" },
                        ],
                    },
                    positionId: {
                        type: "integer",
                        description:
                            "The position id to start a shift for. Only required  if no `timeEntryId` is provided.",
                    },
                    deviceId: {
                        type: "string",
                        description: "The time logging device this event was sent from.",
                    },
                    location: {
                        $ref: "#/components/schemas/Location",
                        description: "The geographic location where the event occurred.",
                    },
                    image: {
                        type: "string",
                        description:
                            "An picture of the person at the moment of triggering the event. " +
                            "May be used to verify that the person was physically present.",
                    },
                    comment: {
                        type: "string",
                        description: "An optional comment by the employee.",
                    },
                    meals: {
                        type: "object",
                        properties: {
                            breakfast: { type: "boolean" },
                            lunch: { type: "boolean" },
                            dinner: { type: "boolean" },
                        },
                        additionalProperties: false,
                    },
                },
                additionalProperties: false,
                required: ["time", "action", "deviceId"],
                oneOf: [{ required: ["employeeId"] }, { required: ["staffNumber"] }],
            },
            PostingKey: {
                type: "string",
                pattern: "[0-9]{1,4}",
                description:
                    "The 'posting key', as used in DATEV to describe how the entry should be treated tax-wise. For details, see: https://apps.datev.de/help-center/documents/1008613",
            },
            TaxKey: {
                enum: [
                    TaxKey.Free,
                    TaxKey.Input19,
                    TaxKey.Input7,
                    TaxKey.Input9_5,
                    TaxKey.None,
                    TaxKey.Sales19,
                    TaxKey.Sales7,
                ],
            },
            RevenueGroup: {
                type: "object",
                properties: {
                    id: { type: "integer" },
                    name: { type: "string" },
                    postingKey: { $ref: "#/components/schemas/PostingKey" },
                    taxKey: { $ref: "#/components/schemas/TaxKey" },
                    account: { type: "string", pattern: "[0-9]+" },
                    costCenter: { type: "string", pattern: "[0-9]+" },
                    venueId: { type: "integer" },
                },
                required: ["id", "name", "venueId"],
                additionalProperties: false,
                description:
                    "Used to group specific types of revenues, along with common values like the account number and cost center.",
            },
            RevenueEntry: {
                type: "object",
                properties: {
                    id: { type: "integer" },
                    description: { type: "string" },
                    date: { type: "string", format: "date" },
                    amount: { type: "number", unit: "euros" },
                    // taxKey: { $ref: "#/components/schemas/TaxKey" },
                    // postingKey: { $ref: "#/components/schemas/PostingKey" },
                    // account: { type: "string", pattern: "[0-9]+" },
                    // costCenter: { type: "string", pattern: "[0-9]+" },
                    venueId: { type: "integer" },
                    // receiptNumber: { type: "string" },
                    // invoiceNumber: { type: "string" },
                    groupId: { type: "integer" },
                },
                required: ["id", "description", "date", "amount", "venueId", "groupId"],
                additionalProperties: false,
            },
            RevenuePrediction: {
                type: "object",
                properties: {
                    date: { type: "string", format: "date" },
                    amount: { type: "number", unit: "euros", description: "The predicted value in Euros." },
                    groupId: { type: "integer" },
                    updated: { type: "string", format: "date-time" },
                },
                required: ["amount"],
                additionalProperties: false,
                description: "A prediction for the total revenue amount for a specific revenue group on a given day.",
            },
            TimeBalance: {
                type: "object",
                properties: {
                    employeeId: { type: "integer" },
                    from: { type: "string", format: "date" },
                    to: { type: "string", format: "date" },
                    carry: { type: "number", unit: "hours" },
                    reset: { type: "number", unit: "hours" },
                    work: { type: "number", unit: "hours" },
                    absences: { type: "number", unit: "hours" },
                    adjustments: { type: "number", unit: "hours" },
                    actual: { type: "number", unit: "hours" },
                    nominal: { type: "number", unit: "hours" },
                    difference: { type: "number", unit: "hours" },
                    balance: { type: "number", unit: "hours" },
                    subBalances: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                employeeId: { type: "integer" },
                                from: { type: "string", format: "date" },
                                to: { type: "string", format: "date" },
                                carry: { type: "number", unit: "hours" },
                                reset: { type: "number", unit: "hours" },
                                work: { type: "number", unit: "hours" },
                                absences: { type: "number", unit: "hours" },
                                adjustments: { type: "number", unit: "hours" },
                                actual: { type: "number", unit: "hours" },
                                nominal: { type: "number", unit: "hours" },
                                difference: { type: "number", unit: "hours" },
                                balance: { type: "number", unit: "hours" },
                            },
                            additionalProperties: false,
                            required: [
                                "employeeId",
                                "from",
                                "to",
                                "carry",
                                "work",
                                "absences",
                                "adjustments",
                                "actual",
                                "nominal",
                                "difference",
                                "balance",
                            ],
                        },
                    },
                },
                additionalProperties: false,
                required: [
                    "employeeId",
                    "from",
                    "to",
                    "carry",
                    "work",
                    "absences",
                    "adjustments",
                    "actual",
                    "nominal",
                    "difference",
                    "balance",
                ],
            },
            VacationBalance: {
                type: "object",
                properties: {
                    employeeId: { type: "integer" },
                    from: { type: "string", format: "date" },
                    to: { type: "string", format: "date" },
                    carry: { type: "number", unit: "days" },
                    reset: { type: "number", unit: "days" },
                    taken: { type: "number", unit: "days" },
                    adjustments: { type: "number", unit: "days" },
                    actual: { type: "number", unit: "days" },
                    nominal: { type: "number", unit: "days" },
                    difference: { type: "number", unit: "days" },
                    balance: { type: "number", unit: "days" },
                    subBalances: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                employeeId: { type: "integer" },
                                from: { type: "string", format: "date" },
                                to: { type: "string", format: "date" },
                                carry: { type: "number", unit: "days" },
                                reset: { type: "number", unit: "days" },
                                taken: { type: "number", unit: "days" },
                                adjustments: { type: "number", unit: "days" },
                                actual: { type: "number", unit: "days" },
                                nominal: { type: "number", unit: "days" },
                                difference: { type: "number", unit: "days" },
                                balance: { type: "number", unit: "days" },
                            },
                            additionalProperties: false,
                            required: [
                                "employeeId",
                                "from",
                                "to",
                                "carry",
                                "actual",
                                "taken",
                                "adjustments",
                                "nominal",
                                "difference",
                                "balance",
                            ],
                        },
                    },
                },
                additionalProperties: false,
                required: [
                    "employeeId",
                    "from",
                    "to",
                    "carry",
                    "actual",
                    "taken",
                    "adjustments",
                    "nominal",
                    "difference",
                    "balance",
                ],
            },
            BonusesBalance: {
                type: "object",
                properties: {
                    employeeId: { type: "integer" },
                    from: { type: "string", format: "date" },
                    to: { type: "string", format: "date" },
                    carry: { type: "number", unit: "euros" },
                    reset: { type: "number", unit: "euros" },
                    taxed: { type: "number", unit: "euros" },
                    untaxed: { type: "number", unit: "euros" },
                    actual: { type: "number", unit: "euros" },
                    nominal: { type: "number", unit: "euros" },
                    difference: { type: "number", unit: "euros" },
                    balance: { type: "number", unit: "euros" },
                    subBalances: {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                employeeId: { type: "integer" },
                                from: { type: "string", format: "date" },
                                to: { type: "string", format: "date" },
                                carry: { type: "number", unit: "euros" },
                                reset: { type: "number", unit: "euros" },
                                taxed: { type: "number", unit: "euros" },
                                untaxed: { type: "number", unit: "euros" },
                                actual: { type: "number", unit: "euros" },
                                nominal: { type: "number", unit: "euros" },
                                difference: { type: "number", unit: "euros" },
                                balance: { type: "number", unit: "euros" },
                            },
                            additionalProperties: false,
                            required: ["employeeId", "from", "to", "taxed", "untaxed", "actual"],
                        },
                    },
                },
                additionalProperties: false,
                required: ["employeeId", "from", "to", "taxed", "untaxed", "actual"],
            },
            Balances: {
                type: "object",
                properties: {
                    employeeId: { type: "integer" },
                    from: { type: "string", format: "date" },
                    to: { type: "string", format: "date" },
                    time: { $ref: "#/components/schemas/TimeBalance" },
                    vacation: { $ref: "#/components/schemas/VacationBalance" },
                    bonuses: { $ref: "#/components/schemas/BonusesBalance" },
                },
                required: ["employeeId", "from", "to", "time", "vacation", "bonuses"],
                additionalProperties: false,
            },
            Note: {
                type: "object",
                properties: {
                    id: { type: "integer" },
                    text: { type: "string" },
                    start: { type: "string", format: "date" },
                    end: { type: "string", format: "date" },
                    filters: { type: "array", items: { $ref: "#/components/schemas/EntityFilter" } },
                    color: { $ref: "#/components/schemas/Color" },
                },
                required: ["id", "text", "start", "end"],
                additionalProperties: false,
            },
        },
    },
    paths: {
        "/auth/token": {
            post: {
                operationId: "createAuthToken",
                summary: "Retrieve an auth token",
                description:
                    "Use this endpoint to retrieve an auth token using your client id and secret. " +
                    "Auth tokens are tied to a specific company, which needs to be specified when " +
                    "requesting the token. Additionally, scopes can be used to restrict permissions " +
                    "to a subset of the permissions granted to the API client. Please always try to " +
                    "request the minimum permissions required for a given task.",
                security: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    clientId: { type: "string", format: "uuid" },
                                    clientSecret: { type: "string" },
                                    companyId: { type: "integer" },
                                    scopes: {
                                        type: "array",
                                        items: { $ref: "#/components/schemas/Scope" },
                                        description:
                                            "An array of scopes which restrict the permissions of the auth token. " +
                                            "If omitted, the full list of available scopes will be used.",
                                    },
                                },
                                required: ["clientId", "clientSecret", "companyId"],
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "200": {
                        description: "Returns an access token for the given company with the given scopes.",
                        content: {
                            "application/json": {
                                schema: { $ref: "#/components/schemas/AuthToken" },
                            },
                        },
                    },
                },
            },
        },
        "/company": {
            get: {
                operationId: "getCompany",
                summary: "Get company info",
                description:
                    "This endpoint returns some basic information about the company associated with the " +
                    "current auth token.",
                responses: {
                    "200": {
                        description: "Returns basic information about the company.",
                        content: {
                            "application/json": {
                                schema: { $ref: "#/components/schemas/Company" },
                            },
                        },
                    },
                },
            },
        },
        "/departments": {
            get: {
                operationId: "getDepartments",
                summary: "Get list of departments",
                description:
                    "This endpoint returns a list of all departments of the company associated with the " +
                    "current auth token.",
                parameters: [
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                ],
                responses: {
                    "200": {
                        description: "Returns all departments of the company as a list",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: { $ref: "#/components/schemas/Department" },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
            post: {
                operationId: "createDepartment",
                summary: "Create a department",
                description: "Use this endpoint to create a new department.",
                parameters: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    venueId: { type: "integer" },
                                    name: { type: "string" },
                                    color: {
                                        type: "string",
                                        pattern: "^#([0-9A-Fa-f]{6})$",
                                        description:
                                            "The hexadecimal color code to denote the color of a department or position.",
                                    },
                                    timeScheduleEmployeeOrder: { type: "array", items: { type: "integer" } },
                                },
                                required: ["venueId", "name", "color"],
                                additionalProperties: false,
                                description: "An object representing a department within a venue.",
                            },
                        },
                    },
                },
                responses: {
                    "201": {
                        description: "Returns the successfully created department.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Department",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/departments/{departmentId}": {
            get: {
                operationId: "getDepartment",
                summary: "Get a single department",
                description: "Get the entire department object for a single department",
                parameters: [
                    {
                        name: "departmentId",
                        in: "path",
                        schema: { type: "integer" },
                        required: true,
                        description: "The department id.",
                    },
                ],
                responses: {
                    "200": {
                        description: "Returns the department object for the given department id.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Department",
                                },
                            },
                        },
                    },
                },
            },
            patch: {
                operationId: "updateDepartment",
                summary: "Update an existing department",
                description: "Update an existing department by supplying the information that should be updated.",
                parameters: [
                    {
                        name: "departmentId",
                        in: "path",
                        required: true,
                        description: "The id of the department to be updated.",
                        schema: {
                            type: "integer",
                        },
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    venueId: { type: "integer", nullable: true },
                                    name: { type: "string", nullable: true },
                                    color: {
                                        type: "string",
                                        pattern: "^#([0-9A-Fa-f]{6})$",
                                        description:
                                            "The hexadecimal color code to denote the color of a department or position.",
                                    },
                                    timeScheduleEmployeeOrder: { type: "array", items: { type: "integer" } },
                                },
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "200": {
                        description: "Returns the department with successfully updated details.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Department",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/employees": {
            get: {
                operationId: "getEmployees",
                summary: "Get list of employees",
                description:
                    "This endpoint returns a list of all employees of the company associated with the " +
                    "current auth token.",
                parameters: [
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                ],
                responses: {
                    "200": {
                        description: "Returns all employees of the company as a list",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: { $ref: "#/components/schemas/Employee" },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
            post: {
                operationId: "createEmployee",
                summary: "Create an employee",
                description: "Use this endpoint to create a new employee.",
                parameters: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    firstName: { type: "string" },
                                    lastName: { type: "string" },
                                    callName: { type: "string", nullable: true },
                                    address: { type: "string", nullable: true },
                                    city: { type: "string", nullable: true },
                                    postalCode: { type: "string", nullable: true },
                                    email: { type: "string", format: "email", nullable: true },
                                    phone: { type: "string", nullable: true },
                                    phone2: { type: "string", nullable: true },
                                    birthday: { type: "string", format: "date", nullable: true },
                                    birthName: { type: "string", nullable: true },
                                    birthCity: { type: "string", nullable: true },
                                    birthCountry: { type: "string", nullable: true },
                                    nationality: { type: "string", nullable: true },
                                    gender: { $ref: "#/components/schemas/Gender" },
                                    taxId: { type: "string", nullable: true },
                                    socialSecurityNumber: { type: "string", nullable: true },
                                    notes: { type: "string", nullable: true },
                                    staffNumber: { type: "string", pattern: "[0-9]{1,9}", nullable: true },
                                    avatar: { type: "string", nullable: true },
                                    timeLogPin: {
                                        type: "string",
                                        nullable: true,
                                        pattern: "[0-9]{4}",
                                        description:
                                            "A 4-digit PIN code used by the employee to authenticate and identify " +
                                            "themselves when triggering time tracking actions(start shift, end shift etc.).",
                                    },
                                },
                                required: ["firstName", "lastName"],
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "201": {
                        description: "Returns the successfully created employee.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Employee",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/employees/{employeeId}": {
            get: {
                operationId: "getEmployee",
                summary: "Get a single employee",
                description: "Get the entire employee object for a single employee",
                parameters: [
                    {
                        name: "employeeId",
                        in: "path",
                        schema: { type: "integer" },
                        required: true,
                        description: "The employee id.",
                    },
                ],
                responses: {
                    "200": {
                        description: "Returns the employee object for the given employee id.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Employee",
                                },
                            },
                        },
                    },
                },
            },
            patch: {
                operationId: "updateEmployee",
                summary: "Update an existing employee",
                description: "Update an existing employee by supplying the information that should be updated.",
                parameters: [
                    {
                        name: "employeeId",
                        in: "path",
                        required: true,
                        description: "The id of the employee to be updated.",
                        schema: {
                            type: "integer",
                        },
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    firstName: { type: "string", nullable: true },
                                    lastName: { type: "string", nullable: true },
                                    callName: { type: "string", nullable: true },
                                    address: { type: "string", nullable: true },
                                    city: { type: "string", nullable: true },
                                    postalCode: { type: "string", nullable: true },
                                    email: {
                                        type: "string",
                                        format: "email",
                                        nullable: true,
                                    },
                                    phone: { type: "string", nullable: true },
                                    phone2: { type: "string", nullable: true },
                                    birthday: { type: "string", format: "date", nullable: true },
                                    birthName: { type: "string", nullable: true },
                                    birthCity: { type: "string", nullable: true },
                                    birthCountry: { type: "string", nullable: true },
                                    nationality: { type: "string", nullable: true },
                                    gender: { $ref: "#/components/schemas/Gender" },
                                    taxId: { type: "string", nullable: true },
                                    socialSecurityNumber: { type: "string", nullable: true },
                                    notes: { type: "string", nullable: true },
                                    staffNumber: { type: "string", pattern: "[0-9]{1,9}", nullable: true },
                                    avatar: { type: "string", nullable: true },
                                    timeLogPin: {
                                        type: "string",
                                        nullable: true,
                                        pattern: "[0-9]{4}",
                                        description:
                                            "A 4-digit PIN code used by the employee to authenticate and identify " +
                                            "themselves when triggering time tracking actions(start shift, end shift etc.).",
                                    },
                                },
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "200": {
                        description: "Returns the employee with successfully updated details.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Employee",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/positions": {
            get: {
                operationId: "getPositions",
                summary: "Get list of positions",
                description:
                    "This endpoint returns a list of all positions of the company associated with the " +
                    "current auth token.",
                parameters: [
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                ],
                responses: {
                    "200": {
                        description: "Returns all positions of the company as a list",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: { $ref: "#/components/schemas/Position" },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
            post: {
                operationId: "createPosition",
                summary: "Create a position",
                description: "Use this endpoint to create a new position.",
                parameters: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    departmentId: { type: "integer" },
                                    name: { type: "string", minLength: 1 },
                                    color: {
                                        $ref: "#/components/schemas/Color",
                                    },
                                },
                                required: ["departmentId", "name"],
                                additionalProperties: false,
                                description: "An object representing a specific position within a department.",
                            },
                        },
                    },
                },
                responses: {
                    "201": {
                        description: "Returns the successfully created position.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Position",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/positions/{positionId}": {
            get: {
                operationId: "getPosition",
                summary: "Get a single position",
                description: "Get the entire position object for a single position",
                parameters: [
                    {
                        name: "positionId",
                        in: "path",
                        schema: { type: "integer" },
                        required: true,
                        description: "The position id.",
                    },
                ],
                responses: {
                    "200": {
                        description: "Returns the position object for the given position id.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Position",
                                },
                            },
                        },
                    },
                },
            },
            patch: {
                operationId: "updatePosition",
                summary: "Update an existing position",
                description: "Update an existing position by supplying the information that should be updated.",
                parameters: [
                    {
                        name: "positionId",
                        in: "path",
                        required: true,
                        description: "The id of the position to be updated.",
                        schema: {
                            type: "integer",
                        },
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    name: { type: "string", minLength: 1 },
                                    color: {
                                        $ref: "#/components/schemas/Color",
                                    },
                                },
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "200": {
                        description: "Returns the position with successfully updated details.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Position",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/time/log/devices": {
            post: {
                operationId: "createTimeLogDevice",
                summary: "Register a time logging device",
                description:
                    "Use this endpoint to register a new device used for tracking employee attendance. " +
                    "Devices are used to make time log events more traceable by attributing them to a specific " +
                    "device. They can also be used to manage filters and other settings remotely without having " +
                    "to change these directly on the physical device.\n\n" +
                    "After creating a time logging device, the next step is usually to connect the physical device " +
                    "using the `connectionCode` property with the `time/log/connect` endpoint.",
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    model: { type: "string" },
                                    description: { type: "string" },
                                    filters: { type: "array", items: { $ref: "#/components/schemas/EntityFilter" } },
                                    image: { type: "string" },
                                    displaySchedule: { type: "boolean", default: true },
                                    displayUpcoming: { type: "boolean", default: true },
                                    displayActive: { type: "boolean", default: true },
                                },
                                required: ["description"],
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "201": {
                        description: "Returns the created time log device.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/TimeLogDevice",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/time/log/devices/{id}": {
            get: {
                operationId: "getTimeLogDevice",
                summary: "Fetch a TimeLogDevice.",
                description: "Use this endpoint to get a TimeLogDevice by it's ID.",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        schema: { type: "string", format: "uuid" },
                        required: true,
                        description: "The id of the device.",
                    },
                ],
                responses: {
                    "200": {
                        description: "The prediction was created or updated.",
                        content: {
                            "application/json": {
                                schema: { $ref: "#/components/schemas/TimeLogDevice" },
                            },
                        },
                    },
                },
            },
            delete: {
                operationId: "deleteTimeLogDevice",
                summary: "Delete a TimeLogDevice",
                description: "Use this endpoint to delete a TimeLogDevice with a given ID.",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        schema: { type: "string", format: "uuid" },
                        required: true,
                        description: "The id of the device.",
                    },
                ],
                responses: {
                    "204": { description: "The device was successfully deleted." },
                },
            },
        },
        "/time/log/connect": {
            post: {
                operationId: "connectTimeLogDevice",
                summary: "Connect a time tracking device",
                description:
                    "This endpoint is used to exchange a connection code for an auth token " +
                    "plus the id of the associated time logging device. The connection code " +
                    "be found on the device object and rotates with every call to the connect " +
                    "endpoint.",
                security: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    code: { type: "string", minLength: 1 },
                                },
                                required: ["code"],
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "200": {
                        description:
                            "Returns a new auth token and the id of the device object which is being connected to.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        authToken: { type: "string" },
                                        deviceId: { type: "string", format: "uuid" },
                                        companyId: { type: "integer" },
                                    },
                                    required: ["authToken", "deviceId", "companyId"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        "/time/log/options": {
            get: {
                operationId: "getTimeLogOptions",
                summary: "Get available time logging options",
                description:
                    "Use this endpoint to retrieve a list of employees that are available for time tracking, along with scheduled shifts, rules and constraints. " +
                    "The information gained from this enpdoint is usually used to identify employees based on their time tracking pin and/or staff number " +
                    "and based on the available shifts, rules and constraints, determine the available actions for the employee at the current time.",
                parameters: [
                    { $ref: "#/components/parameters/TimeFrom" },
                    { $ref: "#/components/parameters/TimeTo" },
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    {
                        name: "deviceId",
                        in: "query",
                        schema: { type: "string", format: "uuid" },
                        required: true,
                        description:
                            "The id of the time logging device to get time logging options for. " +
                            "Time logging devices can be registered using the `time/log/devices` endpoint.",
                    },
                ],
                responses: {
                    "200": {
                        description: "Returns a list of employees along with available time logging options.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: { $ref: "#/components/schemas/TimeLogOption" },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        "/time/log/events": {
            post: {
                operationId: "createTimeLogEvent",
                summary: "Post a time log event",
                description:
                    "Use this endpoint to post a new time log event. If there are no conflicts or other problems " +
                    "this will also update the associated time entry based on the action (or create a new one in case of the " +
                    "`startUnscheduledShift` action). Note that successfully creating the time log action does *not* necessarily " +
                    "mean that the corresponding action has been applied. You can check the event status and, if applicable, the " +
                    "rejection reason on the response object.",
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: { $ref: "#/components/schemas/CreateTimeLogEventParams" },
                        },
                    },
                },
                responses: {
                    "201": {
                        description:
                            "Returns the created TimeLogEvent option, inluding the processing status and, if applicable, the rejection reason.",
                        content: {
                            "application/json": {
                                schema: { $ref: "#/components/schemas/TimeLogEvent" },
                            },
                        },
                    },
                },
            },
        },
        "/time/entries": {
            get: {
                operationId: "getTimeEntries",
                summary: "Get time entries",
                description:
                    "Provides information on recorded work times and absences, including start " +
                    "and end times(for work hours), durations, bonuses, costs and more.",
                parameters: [
                    { $ref: "#/components/parameters/DateFrom" },
                    { $ref: "#/components/parameters/DateTo" },
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                ],
                responses: {
                    "200": {
                        description: "Returns a list of [TimeEntry] objects.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: { type: "array", items: { $ref: "#/components/schemas/TimeEntry" } },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        "/time/schedule": {
            post: {
                operationId: "postTimeSchedule",
                summary: "Post a public time schedule",
                description:
                    "Use this endpoint to post a time public schedule for the given time range, filters and display settings. " +
                    "The resulting public schedule id/url can be used to fetch the associated data from the `getTimeSchedule` " +
                    "endpoint. The id/url is self-authenticating, which means it can be used as public link.",
                parameters: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    from: { type: "string", format: "date" },
                                    to: { type: "string", format: "date" },
                                    companyId: { type: "integer" },
                                    filters: { type: "array", items: { $ref: "#/components/schemas/EntityFilter" } },
                                    types: { type: "array", items: { $ref: "#/components/schemas/TimeEntryType" } },
                                    mirrorShifts: { type: "boolean" },
                                },
                                required: ["from", "to", "companyId"],
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "200": {
                        description: "A time schedule object.",
                        content: {
                            "application/json": {
                                schema: { $ref: "#/components/schemas/TimeSchedule" },
                            },
                        },
                    },
                },
            },
        },
        "/time/schedule/{id}": {
            get: {
                operationId: "getTimeSchedule",
                summary: "Get public time schedule for a given id.",
                description:
                    "Use this endpoint to get the public time schedule for a given id. This endpoint " +
                    "has an alternative format `text/html`, which can be selected via the `accept` header." +
                    "This endpoind does not require authentication, only a valid id.",
                security: [],
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        schema: { type: "string" },
                        required: true,
                        description: "The schedule token.",
                    },
                    {
                        name: "accept",
                        in: "header",
                        schema: {
                            type: "string",
                            default: "application/json",
                        },
                        description: "The output format.",
                    },
                ],
                responses: {
                    "200": {
                        description: "A time schedule was prepared",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/TimeSchedule",
                                },
                            },
                            "text/html": {
                                schema: { type: "string" },
                            },
                        },
                    },
                },
            },
        },
        "/revenue/groups": {
            get: {
                operationId: "getRevenueGroups",
                summary: "Get revenue groups",
                description: "Use this endpoint to get a list of revenue groups for a given venue.",
                parameters: [
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Venue" },
                ],
                responses: {
                    "200": {
                        description: "Returns all revenue groups for the given venue.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: { $ref: "#/components/schemas/RevenueGroup" },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
            post: {
                operationId: "createRevenueGroup",
                summary: "Create a revenue group",
                description: "Use this endpoint to create a new revenue group.",
                parameters: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    name: { type: "string" },
                                    postingKey: { $ref: "#/components/schemas/PostingKey" },
                                    taxKey: { $ref: "#/components/schemas/TaxKey" },
                                    account: { type: "string", pattern: "[0-9]+" },
                                    costCenter: { type: "string", pattern: "[0-9]+" },
                                    venueId: { type: "integer" },
                                },
                                required: ["name", "venueId"],
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "201": {
                        description: "Returns the successfully created revenue group.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/RevenueGroup",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/revenue/entries": {
            get: {
                operationId: "getRevenueEntries",
                summary: "Get revenue entries",
                description:
                    "Use this endpoint to get a list of all revenue entries for the given venue within the specified dates.",
                parameters: [
                    { $ref: "#/components/parameters/DateFrom" },
                    { $ref: "#/components/parameters/DateTo" },
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Venue" },
                    {
                        name: "groupId",
                        in: "query",
                        schema: { type: "integer" },
                        description: "If provided, will only return revenue entries with the given group id.",
                    },
                ],
                responses: {
                    "200": {
                        description:
                            "Returns a paginated list of revenue entries for the given venue within the given time frame.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: { $ref: "#/components/schemas/RevenueEntry" },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
            post: {
                operationId: "createRevenueEntry",
                summary: "Create a revenue entry",
                description: "Use this endpoint to create a new revenue entry.",
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    description: { type: "string" },
                                    date: { type: "string", format: "date" },
                                    amount: { type: "number", unit: "euros", minimum: 0 },
                                    // taxKey: { $ref: "#/components/schemas/TaxKey" },
                                    // postingKey: { $ref: "#/components/schemas/PostingKey" },
                                    // account: { type: "string", pattern: "[0-9]+" },
                                    // costCenter: { type: "string", pattern: "[0-9]+" },
                                    venueId: { type: "integer" },
                                    // receiptNumber: { type: "string" },
                                    // invoiceNumber: { type: "string" },
                                    groupId: { type: "integer" },
                                },
                                required: ["description", "date", "amount", "venueId", "groupId"],
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "201": {
                        description: "Returns the created RevenueEntry including the venue and revenue group ids.",
                        content: {
                            "application/json": {
                                schema: { $ref: "#/components/schemas/RevenueEntry" },
                            },
                        },
                    },
                },
            },
        },
        "/balances/time": {
            get: {
                operationId: "getTimeBalances",
                summary: "Get time balances",
                description: "Get the time balances within the time range",
                parameters: [
                    { $ref: "#/components/parameters/DateFrom" },
                    { $ref: "#/components/parameters/DateTo" },
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                    { $ref: "#/components/parameters/Resolution" },
                ],
                responses: {
                    "200": {
                        description: "Returns a paginated list of time balances.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: {
                                                $ref: "#/components/schemas/TimeBalance",
                                            },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        "/balances/vacation": {
            get: {
                operationId: "getVacationBalances",
                summary: "Get vacation balances",
                description: "Get the vacation balances within the vacation range",
                parameters: [
                    { $ref: "#/components/parameters/DateFrom" },
                    { $ref: "#/components/parameters/DateTo" },
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                    { $ref: "#/components/parameters/Resolution" },
                ],
                responses: {
                    "200": {
                        description: "Returns a paginated list of vacation balances.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: {
                                                $ref: "#/components/schemas/VacationBalance",
                                            },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        "/balances/bonuses": {
            get: {
                operationId: "getBonusesBalances",
                summary: "Get bonuses balances",
                description: "Get the bonuses balances within the bonuses range",
                parameters: [
                    { $ref: "#/components/parameters/DateFrom" },
                    { $ref: "#/components/parameters/DateTo" },
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                    { $ref: "#/components/parameters/Resolution" },
                ],
                responses: {
                    "200": {
                        description: "Returns a paginated list of bonuses balances.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: {
                                                $ref: "#/components/schemas/BonusesBalance",
                                            },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        "/balances": {
            get: {
                deprecated: true,
                operationId: "getBalances",
                summary: "Get time, vacation and bonuses balances",
                description: "Get the time, vacation and bonuses balances for a given employee and time range",
                parameters: [
                    { $ref: "#/components/parameters/DateFrom" },
                    { $ref: "#/components/parameters/DateTo" },
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                    { $ref: "#/components/parameters/Resolution" },
                ],
                responses: {
                    "200": {
                        description: "Returns a paginated list of time, vacation and bonuses balances.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: {
                                                $ref: "#/components/schemas/Balances",
                                            },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        "/balances/{employeeId}/{year}": {
            get: {
                deprecated: true,
                operationId: "getYearBalances",
                summary: "Get time, vacation and bonuses balances",
                description: "Get the time, vacation and bonuses balances for a given employee and time range",
                parameters: [
                    {
                        name: "employeeId",
                        in: "path",
                        schema: { type: "integer" },
                        required: true,
                        description: "The employee id.",
                    },
                    {
                        name: "year",
                        in: "path",
                        schema: { type: "string", pattern: "(19|20)\\d\\d" },
                        required: true,
                        description: "the year.",
                    },
                ],
                responses: {
                    "200": {
                        description: "Returns the time, vacation and bonus balances for the given employee and year.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Balances",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/balances/{employeeId}/{year}/{month}": {
            get: {
                deprecated: true,
                operationId: "getMonthBalances",
                summary: "Get time, vacation and bonuses balances",
                description: "Get the time, vacation and bonuses balances for a given employee and time range",
                parameters: [
                    {
                        name: "employeeId",
                        in: "path",
                        schema: { type: "integer" },
                        required: true,
                        description: "The employee id.",
                    },
                    {
                        name: "year",
                        in: "path",
                        schema: { type: "string", pattern: "(19|20)\\d\\d" },
                        required: true,
                        description: "the year.",
                    },
                    {
                        name: "month",
                        in: "path",
                        schema: { type: "string", pattern: "0[1-9]|1[0-2]" },
                        required: true,
                        description: "the month.",
                    },
                ],
                responses: {
                    "200": {
                        description: "Returns the time, vacation and bonus balances for the given employee and year.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Balances",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/balances/{employeeId}/{year}/{month}/{day}": {
            get: {
                deprecated: true,
                operationId: "getDayBalances",
                summary: "Get time, vacation and bonuses balances",
                description: "Get the time, vacation and bonuses balances for a given employee and time range",
                parameters: [
                    {
                        name: "employeeId",
                        in: "path",
                        schema: { type: "integer" },
                        required: true,
                        description: "The employee id.",
                    },
                    {
                        name: "year",
                        in: "path",
                        schema: { type: "string", pattern: "(19|20)\\d\\d" },
                        required: true,
                        description: "the year.",
                    },
                    {
                        name: "month",
                        in: "path",
                        schema: { type: "string", pattern: "0[1-9]|1[0-2]" },
                        required: true,
                        description: "the month.",
                    },
                    {
                        name: "day",
                        in: "path",
                        schema: { type: "string", pattern: "0[1-9]|[1-2][0-9]|3[0-1]" },
                        required: true,
                        description: "The day of the month.",
                    },
                ],
                responses: {
                    "200": {
                        description: "Returns the time, vacation and bonus balances for the given employee and year.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Balances",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/revenue/predictions": {
            get: {
                operationId: "getRevenuePredictions",
                summary: "Get revenue predictions",
                description:
                    "Use this endpoint to get a list of all revenue predictions for the given venue within the specified dates.",
                parameters: [
                    { $ref: "#/components/parameters/DateFrom" },
                    { $ref: "#/components/parameters/DateTo" },
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Venue" },
                ],
                responses: {
                    "200": {
                        description:
                            "Returns a paginated list of revenue predictions for the given venue within the given time frame.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: { $ref: "#/components/schemas/RevenuePrediction" },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        "/revenue/predictions/{groupId}/{date}": {
            put: {
                operationId: "setRevenuePrediction",
                summary: "Update a revenue prediction.",
                description:
                    "Use this endpoint to set the revenue prediction for the given revenue group on the given date.",
                parameters: [
                    {
                        name: "groupId",
                        in: "path",
                        schema: { type: "integer" },
                        required: true,
                        description: "The id of the revenue group.",
                    },
                    {
                        name: "date",
                        in: "path",
                        schema: { type: "string", format: "date" },
                        required: true,
                        description: "The date of the revenue prediction.",
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    amount: {
                                        type: "number",
                                        unit: "euros",
                                        description: "The predicted revenue in Euros.",
                                    },
                                },
                                required: ["amount"],
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "202": {
                        description: "The prediction was created or updated.",
                    },
                },
            },
        },
        "/notes": {
            get: {
                operationId: "getNotes",
                summary: "Get a list of notes for the given filters",
                description: "Get a list of notes for the given filters.",
                parameters: [
                    { $ref: "#/components/parameters/DateFrom" },
                    { $ref: "#/components/parameters/DateTo" },
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                ],
                responses: {
                    "200": {
                        description: "Returns a paginated list of notes.",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: {
                                                $ref: "#/components/schemas/Note",
                                            },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        "/venues": {
            get: {
                operationId: "getVenues",
                summary: "Get list of venues",
                description:
                    "This endpoint returns a list of all venues of the company associated with the " +
                    "current auth token.",
                parameters: [
                    { $ref: "#/components/parameters/ListOffset" },
                    { $ref: "#/components/parameters/ListLimit" },
                    { $ref: "#/components/parameters/Filters" },
                ],
                responses: {
                    "200": {
                        description: "Returns all venues of the company as a list",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        offset: { type: "integer" },
                                        limit: { type: "integer" },
                                        total: { type: "integer" },
                                        data: {
                                            type: "array",
                                            items: { $ref: "#/components/schemas/Venue" },
                                        },
                                    },
                                    required: ["offset", "limit", "total", "data"],
                                    additionalProperties: false,
                                },
                            },
                        },
                    },
                },
            },
            post: {
                operationId: "createVenue",
                summary: "Create a venue",
                description: "Use this endpoint to create a new venue.",
                parameters: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    name: { type: "string", minLength: 1 },
                                    venueNumber: { type: "integer" },
                                },
                                required: ["name"],
                                additionalProperties: false,
                                description: "An object representing a venue within a company.",
                            },
                        },
                    },
                },
                responses: {
                    "201": {
                        description: "Returns the successfully created venue.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Venue",
                                },
                            },
                        },
                    },
                },
            },
        },
        "/venues/{venueId}": {
            get: {
                operationId: "getVenue",
                summary: "Get a single venue",
                description: "Get the entire venue object for a single venue",
                parameters: [
                    {
                        name: "venueId",
                        in: "path",
                        schema: { type: "integer" },
                        required: true,
                        description: "The venue id.",
                    },
                ],
                responses: {
                    "200": {
                        description: "Returns the venue object for the given venue id.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Venue",
                                },
                            },
                        },
                    },
                },
            },
            patch: {
                operationId: "updateVenue",
                summary: "Update an existing venue",
                description: "Update an existing venue by supplying the information that should be updated.",
                parameters: [
                    {
                        name: "venueId",
                        in: "path",
                        required: true,
                        description: "The id of the venue to be updated.",
                        schema: {
                            type: "integer",
                        },
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    name: { type: "string", minLength: 1 },
                                },
                                additionalProperties: false,
                            },
                        },
                    },
                },
                responses: {
                    "200": {
                        description: "Returns the venue with successfully updated details.",
                        content: {
                            "application/json": {
                                schema: {
                                    $ref: "#/components/schemas/Venue",
                                },
                            },
                        },
                    },
                },
            },
        },
    },
} as const;
// } satisfies Spec;

export type PentacodeAPI = typeof api;

export type PentacodeAPIModels = Models<PentacodeAPI>;

export { api };
