import { ExportResponse } from "./api";
import {
    Absence,
    AbsenceStatus,
    Benefit,
    BonusCompType,
    Company,
    Contract,
    Employee,
    EmploymentType,
    MimeType,
    Position,
    RevenueEntry,
    Salary,
    TimeEntry,
    timeEntryAbsenceTypes,
    TimeEntryType,
} from "./model";
import {
    DateString,
    Days,
    Euros,
    Factor,
    Hours,
    Rate,
    add,
    getRate,
    multiplyWithFactor,
    round,
} from "@pentacode/openapi";
import {
    dateAdd,
    dateSub,
    formatDate,
    formatNumber,
    getRange,
    parseDateString,
    toDateString,
    toDOSLineEndings,
    toTimeString,
} from "./util";
import { Localized } from "./localized";
import { DateRange } from "./time";

export enum PayrollItemType {
    Salary = "salary",
    TimeWage = "time_wage",
    Commission = "commission",
    Absence = "absence",
    BonusTaxFree = "bonus_tax_free",
    BonusTaxed = "bonus_taxed",
    BonusAdvance = "bonus_advance",
    Benefit = "benefit",
    WorkDays = "work_days",
    VacationDays = "vacation_days",
    SickDays = "sick_days",
    HourAdjustment = "hour_adjustment",
    VacationAdjustment = "vacation_adjustment",
    PayAdvance = "pay_advance",
    Meals = "meals",
}

export type PayrollItem = {
    unit: "hours" | "days" | "euros" | "pieces";
    amount: number;
    description: string;
    wageType?: string;
    costCenter?: string;
    color?: string;
    icon?: string;
    wagePerUnit?: number;
    factor?: number;
    result?: number;
    type: PayrollItemType;
    exportOnly?: boolean;
};

export type PayrollReport = {
    employee: Employee;
    from: string;
    to: string;
    items: PayrollItem[];
    totalPay: number;
};

export type BMDAbsenceRecord = {
    client: string;
    employeeId: number;
    type: string;
    compHourBucket: "";
    handlingMark: "3";
    start: DateString;
    end: DateString;
    days: "";
    hours: "";
    notes: string;
};

type PaymentInterval = {
    from: DateString;
    to: DateString;
    amount: Euros;
};

export function getPayrollReport(
    company: Company,
    employee: Employee,
    timeEntries: TimeEntry[],
    advances: RevenueEntry[],
    from: DateString,
    to: DateString
): PayrollReport {
    const order: PayrollItemType[] = [
        PayrollItemType.TimeWage,
        PayrollItemType.Salary,
        PayrollItemType.Absence,
        PayrollItemType.Benefit,
        PayrollItemType.BonusTaxFree,
        PayrollItemType.BonusTaxed,
        PayrollItemType.BonusAdvance,
        PayrollItemType.Meals,
        PayrollItemType.Commission,
        PayrollItemType.HourAdjustment,
        PayrollItemType.VacationAdjustment,
        PayrollItemType.PayAdvance,
        // export only
        PayrollItemType.SickDays,
        PayrollItemType.VacationDays,
        PayrollItemType.WorkDays,
    ];

    const contracts = employee.getAllContractsForRange({ from, to }).filter((c) => !c.blocked);

    if (contracts.length === 0 || !contracts.some((contract) => contract.defaultSalary)) {
        return {
            employee,
            from,
            to,
            items: [],
            totalPay: 0,
        };
    }

    const items: PayrollItem[] = [];

    function addItem(item: PayrollItem) {
        const existing = items.find(
            (i) =>
                i.type === item.type &&
                i.wageType === item.wageType &&
                i.costCenter === item.costCenter &&
                i.unit === item.unit &&
                i.wagePerUnit === item.wagePerUnit &&
                i.factor === item.factor &&
                i.description === item.description &&
                i.exportOnly === item.exportOnly
        );

        if (existing) {
            existing.amount += item.amount;
            if (item.result) {
                existing.result = (existing.result || 0) + item.result;
            }
        } else {
            items.push(item);
        }
    }

    const date = { from, to };
    const defaultPosition = employee.positions[0];
    const defaultCostCenter = company.getCostCenter({ employee, date, position: defaultPosition })?.number;
    const defaultWageTypeSet = company.getWageTypeSet({ employee, date, position: defaultPosition });

    // const salaryCount = contracts.reduce((total, contract) => total + contract.salaries.length, 0);
    // const sfnAdvanceCount = contracts.reduce(
    //     (total, contract) => total + (contract.enableSFNLedger && contract.sfnAdvance ? 1 : 0),
    //     0
    // );

    // const formatIntervalForDescription = (interval: PaymentInterval) => {
    //     return `${formatDate(interval.from)} - ${formatDate(dateAdd(interval.to, { days: -1 }))}`;
    // };

    const benefitItems: {
        amount: Euros;
        result: Euros;
        wageType: string | undefined;
        benefitType: number;
        from: DateString;
        to: DateString;
        description: string;
    }[] = [];

    // Add payments
    for (const contract of contracts) {
        // monthly salaries
        for (const salary of contract.salaries.filter((s) => s.type === "monthly")) {
            for (const effectiveSalary of getProratedPaymentForRange(contract, date, salary).filter(
                (payment) => payment.amount !== 0
            )) {
                const description = `Gehalt`;
                // if (salaryCount > 1) {
                //     description += ` ${company.getPositionLabel(salary.positionId || defaultPosition)} (${formatIntervalForDescription(effectiveSalary)})`;
                // }

                addItem({
                    type: PayrollItemType.Salary,
                    description,
                    icon: "hands-holding-dollar",
                    unit: "euros",
                    amount: effectiveSalary.amount,
                    result: effectiveSalary.amount,
                    wageType: company.getWageTypeSet({ employee, position: salary.positionId || defaultPosition, date })
                        ?.salary,
                    costCenter: company.getCostCenter({
                        employee,
                        position: salary.positionId || defaultPosition,
                        date,
                    })?.number,
                });
            }
        }

        // SFN-Advance
        const sfnAdvance = contract.enableSFNLedger && contract.sfnAdvance;
        if (sfnAdvance) {
            for (const sfnAdvancePayment of getProratedPaymentForRange(contract, date, sfnAdvance).filter(
                (payment) => payment.amount !== 0
            )) {
                const description = "SFN-Pauschale";
                // if (sfnAdvanceCount > 1) {
                //     description += ` (${formatIntervalForDescription(sfnAdvancePayment)})`;
                // }
                addItem({
                    type: PayrollItemType.BonusAdvance,
                    description,
                    icon: "badge-percent",
                    unit: "euros",
                    amount: sfnAdvancePayment.amount,
                    result: sfnAdvancePayment.amount,
                    wageType: defaultWageTypeSet?.bonusAdvance,
                });
            }
        }

        // benefits
        for (const benefit of contract.benefits) {
            const type = company.benefitTypes.find((b) => b.id === benefit.typeId);
            if (!type) {
                continue;
            }

            // const benefitTypeCount = contracts.reduce(
            //     (total, contract) =>
            //         total + (contract.benefits.some((benefit) => benefit.typeId === benefit.typeId) ? 1 : 0),
            //     0
            // );

            for (const benefitPayment of getProratedPaymentForRange(contract, date, benefit).filter(
                (payment) => payment.amount !== 0
            )) {
                const description = type.name;
                // if (benefitTypeCount > 1) {
                //     description += ` (${formatIntervalForDescription(benefitPayment)})`;
                // }
                benefitItems.push({
                    amount: benefitPayment.amount,
                    result: benefitPayment.amount,
                    wageType: defaultWageTypeSet?.benefits.find((b) => b.benefitTypeId === benefit.typeId)
                        ?.wageTypeNumber,
                    benefitType: benefit.typeId,
                    from: benefitPayment.from,
                    to: benefitPayment.to,
                    description,
                });
            }
        }
    }

    for (const item of benefitItems.sort((a, b) =>
        `${a.benefitType}${a.from}`.localeCompare(`${b.benefitType}${b.from}`)
    )) {
        addItem({
            type: PayrollItemType.Benefit,
            icon: "hand-holding-box",
            unit: "euros",
            amount: item.amount,
            result: item.result,
            wageType: item.wageType,
            costCenter: defaultCostCenter,
            description: item.description,
        });
    }

    for (const timeEntry of timeEntries) {
        const result = timeEntry.result;

        if (!result) {
            continue;
        }

        const position = timeEntry.position;
        const contract = employee.getContractForDate(timeEntry.date);
        const salary = contract?.getSalary(position);
        if (!contract || !salary) {
            continue;
        }

        const wageTypeSet = company.getWageTypeSet({ employee, position: position || defaultPosition, date });
        const absenceWageTypes = salary.type === "hourly" ? wageTypeSet?.absenceHourly : wageTypeSet?.absenceMonthly;
        const absenceDaysWageTypes =
            salary.type === "hourly" ? wageTypeSet?.absenceHourlyDays : wageTypeSet?.absenceMonthlyDays;
        const costCenter = company.getCostCenter({ employee, position: position || defaultPosition, date })?.number;
        const paidDuration = round(add(result.base.duration, result.breaks.paidDuration), 2);

        if (position) {
            const positionLabel = company.getPositionLabel(position) || "Unbekannte Position";
            // Time wage
            if (salary?.type === "hourly") {
                addItem({
                    type: PayrollItemType.TimeWage,
                    description: positionLabel,
                    icon: "timer",
                    unit: "hours",
                    amount: paidDuration,
                    wagePerUnit: salary.amount,
                    result: salary.amount * paidDuration,
                    wageType: wageTypeSet?.workHourly,
                    costCenter,
                });
            } else {
                addItem({
                    type: PayrollItemType.TimeWage,
                    description: positionLabel,
                    icon: "timer",
                    unit: "hours",
                    amount: paidDuration,
                    wageType: wageTypeSet?.workMonthly,
                    costCenter,
                });
            }

            // Work days
            if (result.days) {
                addItem({
                    type: PayrollItemType.WorkDays,
                    description: `${positionLabel} (Tage)`,
                    icon: "calendar-day",
                    unit: "days",
                    amount: result.days,
                    wageType: salary.type === "hourly" ? wageTypeSet?.workHourlyDays : wageTypeSet?.workMonthlyDays,
                    costCenter,
                    exportOnly: true,
                });
            }

            // commission
            if (salary.commission && timeEntry.revenue) {
                addItem({
                    type: PayrollItemType.Commission,
                    description: positionLabel,
                    icon: "badge-dollar",
                    unit: "euros",
                    wagePerUnit: salary.commission,
                    amount: timeEntry.revenue,
                    result: (timeEntry.revenue * salary.commission) / 100,
                    wageType: wageTypeSet?.commission,
                    costCenter,
                });
            }

            // meals
            if (result.meals.breakfast && company.settings.mealValueBreakfast) {
                addItem({
                    type: PayrollItemType.Meals,
                    description: `Frühstück (${positionLabel})`,
                    icon: "utensils",
                    unit: "pieces",
                    wagePerUnit: result.meals.breakfast.value,
                    amount: result.meals.breakfast.count,
                    result: result.meals.breakfast.costs,
                    wageType: wageTypeSet?.mealsBreakfast,
                    costCenter,
                });
            }

            if (result.meals.lunch && company.settings.mealValueLunch) {
                addItem({
                    type: PayrollItemType.Meals,
                    description: `Mittagessen (${positionLabel})`,
                    icon: "utensils",
                    unit: "pieces",
                    wagePerUnit: result.meals.lunch.value,
                    amount: result.meals.lunch.count,
                    result: result.meals.lunch.costs,
                    wageType: wageTypeSet?.mealsLunch,
                    costCenter,
                });
            }

            if (result.meals.dinner && company.settings.mealValueDinner) {
                addItem({
                    type: PayrollItemType.Meals,
                    description: `Abendessen (${positionLabel})`,
                    icon: "utensils",
                    unit: "pieces",
                    wagePerUnit: result.meals.dinner.value,
                    amount: result.meals.dinner.count,
                    result: result.meals.dinner.costs,
                    wageType: wageTypeSet?.mealsDinner,
                    costCenter,
                });
            }

            const totalMealsValue = add(
                result.meals.breakfast?.costs || (0 as Euros),
                result.meals.lunch?.costs || (0 as Euros),
                result.meals.dinner?.costs || (0 as Euros)
            );

            if (totalMealsValue > 0) {
                addItem({
                    type: PayrollItemType.Benefit, // PayrollItemType.Meals is only for the number of means, PayrollItemType.Benefit is used for the euro value here
                    description: `Mitarbeiteressen (${positionLabel})`,
                    icon: "utensils",
                    unit: "euros",
                    amount: totalMealsValue,
                    result: totalMealsValue,
                    wageType: wageTypeSet?.mealsValue,
                    costCenter,
                    exportOnly: true,
                });
            }
        } else if (timeEntryAbsenceTypes.some((absenceType) => absenceType === timeEntry.type)) {
            const type = timeEntry.type as (typeof timeEntryAbsenceTypes)[number];

            // Absence hours
            addItem({
                type: PayrollItemType.Absence,
                description: Localized[company.country].timeEntryTypeLabel(type),
                icon: Localized[company.country].timeEntryTypeIcon(type),
                unit: "hours",
                amount: round(result.base.duration, 2),
                wagePerUnit: salary?.type === "hourly" ? result.base.hourlyRate : undefined,
                result: salary?.type === "hourly" ? result.base.wages : undefined,
                wageType: {
                    [TimeEntryType.Vacation]: absenceWageTypes?.vacation,
                    [TimeEntryType.Sick]: absenceWageTypes?.sick,
                    [TimeEntryType.ChildSick]: absenceWageTypes?.childSick,
                    [TimeEntryType.SickInKUG]: absenceWageTypes?.sickInKUG,
                    [TimeEntryType.CompDay]: absenceWageTypes?.compDay,
                }[type],
                costCenter,
            });

            // absence days
            if (result.days) {
                addItem({
                    type: type === TimeEntryType.Vacation ? PayrollItemType.VacationDays : PayrollItemType.SickDays,
                    description: `${Localized[company.country].timeEntryTypeLabel(type)} (Tage)`,
                    icon: Localized[company.country].timeEntryTypeIcon(type),
                    unit: "days",
                    amount: result.days,
                    wageType: {
                        [TimeEntryType.Vacation]: absenceDaysWageTypes?.vacation,
                        [TimeEntryType.Sick]: absenceDaysWageTypes?.sick,
                        [TimeEntryType.ChildSick]: absenceDaysWageTypes?.childSick,
                        [TimeEntryType.SickInKUG]: absenceDaysWageTypes?.sickInKUG,
                        [TimeEntryType.CompDay]: absenceDaysWageTypes?.compDay,
                    }[type],
                    costCenter,
                    exportOnly: true,
                });
            }
        } else if (timeEntry.type === TimeEntryType.HourAdjustment && result.base.duration) {
            if (!timeEntry.paid) {
                continue;
            }

            // Add Hour Adjustments
            addItem({
                type: PayrollItemType.HourAdjustment,
                description: timeEntry.comment || Localized[company.country].timeEntryTypeLabel(timeEntry.type),
                icon: "plus-minus",
                unit: "hours",
                wagePerUnit: result.base.hourlyRate,
                amount: round(Math.abs(result.base.duration), 2),
                result: Math.abs(result.base.wages),
                wageType: timeEntry.wageType || undefined,
                costCenter,
            });
        } else if (timeEntry.type === TimeEntryType.VacationAdjustment && result.base.duration) {
            // Add Vacation Adjustments
            addItem({
                type: PayrollItemType.VacationAdjustment,
                description: `${timeEntry.comment || Localized[company.country].timeEntryTypeLabel(timeEntry.type)} (${
                    result.days
                } T)`,
                icon: "plus-minus",
                unit: "hours",
                wagePerUnit: result.base.hourlyRate,
                amount: round(result.base.duration, 2),
                result: result.base.wages,
                wageType: timeEntry.wageType || undefined,
                costCenter,
            });
        }

        // Add bonuses
        for (const bonus of result.bonuses) {
            const type = company.bonusTypes.find((t) => t.id === bonus.type.id);
            if (!type || !bonus.duration) {
                continue;
            }
            const wageTypes = wageTypeSet?.bonuses.find((b) => b.bonusTypeId === bonus.type.id);
            const activeContract = contracts.find((c) => c.isActiveOn(timeEntry.date));

            addItem({
                type: bonus.taxFree ? PayrollItemType.BonusTaxFree : PayrollItemType.BonusTaxed,
                description: type.name,
                icon: "badge-percent",
                unit: type.compType === BonusCompType.FixedAmount ? "days" : "hours",
                amount: type.compType === BonusCompType.FixedAmount ? (1 as Days) : round(bonus.duration, 2),
                wagePerUnit: type.compType === BonusCompType.FixedAmount ? bonus.wages : bonus.hourlyRate,
                factor: bonus.percent,
                result: activeContract?.sfnAdvance && bonus.taxFree ? undefined : bonus.wages,
                wageType: wageTypes && (bonus.taxFree ? wageTypes.taxFree : wageTypes.taxed),
                costCenter,
            });
        }
    }

    // Add Pay Advance
    for (const entry of advances) {
        addItem({
            type: PayrollItemType.PayAdvance,
            description: `Vorschuss vom ${formatDate(entry.date)} (${entry.cashbook ? "bar" : "Überweisung"})`,
            icon: "hand-holding-dollar",
            unit: "euros",
            amount: -1 * entry.amount,
            result: entry.amount,
            wageType: defaultWageTypeSet?.payAdvance,
            costCenter: defaultCostCenter,
        });
    }

    return {
        employee,
        from,
        to,
        items: items.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type)),
        totalPay: items.filter((item) => !item.exportOnly).reduce((total, item) => total + (item.result || 0), 0),
    };
}

export function formatDatevLG(company: Company, reports: PayrollReport[], from: string) {
    const client = company.settings.accounting.client;
    const date = parseDateString(from)!;

    let content = `${company.settings.accounting.consultant};${company.settings.accounting.client};${
        date.getMonth() + 1
    }/${date.getFullYear()}\r\n`;

    const messages: ExportResponse["messages"] = [];

    for (const { employee, items } of reports) {
        if (!employee.staffNumber) {
            messages.push({
                type: "warning",
                content: `Fehlende Personalnummer für ${employee.firstName} ${employee.lastName}. Mitarbeiter wurde übersprungen.`,
            });
            continue;
        }

        const wageTypeValues = new Map<string, Partial<PayrollItem>>();
        const aggregatedItems = aggregatePayrollItems(items);

        for (const {
            type,
            wageType,
            costCenter,
            wagePerUnit,
            description,
            amount,
            result,
            factor,
        } of aggregatedItems) {
            if (!wageType) {
                messages.push({
                    type: "info",
                    content: `Übersprungen wegen fehlender Lohnart: ${employee.firstName}, ${employee.lastName} - ${description}`,
                });
                continue;
            }

            const wtValues = wageTypeValues.get(wageType);

            if (wtValues && (wtValues.wagePerUnit !== wagePerUnit || wtValues.factor !== factor)) {
                messages.push({
                    type: "warning",
                    content:
                        "Es wurden zwei oder mehrere Einträge mit unterschiedlichen Faktoren auf die gleiche Lohnart gebucht!",
                });
            } else {
                wageTypeValues.set(wageType, { wagePerUnit, factor });
            }

            switch (type) {
                case PayrollItemType.TimeWage:
                case PayrollItemType.Absence:
                case PayrollItemType.BonusTaxFree:
                case PayrollItemType.BonusTaxed:
                case PayrollItemType.WorkDays:
                case PayrollItemType.VacationDays:
                case PayrollItemType.SickDays:
                case PayrollItemType.PayAdvance:
                case PayrollItemType.Meals:
                case PayrollItemType.HourAdjustment:
                case PayrollItemType.VacationAdjustment:
                    content += `${employee.staffNumber};;;${wageType};;;${formatNumber(amount, 2, false)};;;${
                        costCenter || ""
                    };\r\n`;
                    break;
                case PayrollItemType.Salary:
                case PayrollItemType.Benefit:
                case PayrollItemType.Commission:
                case PayrollItemType.BonusAdvance:
                    content += `${employee.staffNumber};;;${wageType};;;${formatNumber(result || 0, 2, false)};;;${
                        costCenter || ""
                    };\r\n`;
                    break;
            }
        }
    }

    return {
        name: `Lohn_${client}_${date.getMonth() + 1}_${date.getFullYear()}_DATEV_LG`,
        content: Buffer.from(content, "latin1"),
        type: MimeType.TXT,
        messages,
    };
}

export function formatDatevLodas(company: Company, reports: PayrollReport[], from: string) {
    const { client, consultant } = company.settings.accounting;
    const date = parseDateString(from)!;
    let content = `[Allgemein]
Ziel=LODAS
Version_SST=1.0
Version_DB=12.72
BeraterNr=${consultant}
MandantenNr=${client}
Datumsformat=JJJJ-MM-TT
Stringbegrenzer="
StammdatenGueltigAb=${from}

[Satzbeschreibung]
1;u_lod_bwd_buchung_standard;pnr#bwd;abrechnung_zeitraum#bwd;bs_wert_butab#bwd;bs_nr#bwd;la_eigene#bwd;kostenstelle#bwd;

[Bewegungsdaten]
`;

    const messages: ExportResponse["messages"] = [];

    for (const { employee, items } of reports) {
        if (!employee.staffNumber) {
            messages.push({
                type: "warning",
                content: `Fehlende Personalnummer für ${employee.firstName} ${employee.lastName}. Mitarbeiter wurde übersprungen.`,
            });
            continue;
        }

        const wageTypeValues = new Map<string, Partial<PayrollItem>>();
        const aggregatedItems = aggregatePayrollItems(items);

        for (const {
            type,
            wageType,
            costCenter,
            wagePerUnit,
            description,
            result,
            amount,
            factor,
        } of aggregatedItems) {
            if (!wageType) {
                messages.push({
                    type: "info",
                    content: `Übersprungen wegen fehlender Lohnart: ${employee.firstName}, ${employee.lastName} - ${description}`,
                });
                continue;
            }

            const wtValues = wageTypeValues.get(wageType);

            if (wtValues && (wtValues.wagePerUnit !== wagePerUnit || wtValues.factor !== factor)) {
                messages.push({
                    type: "warning",
                    content:
                        "Es wurden zwei oder mehrere Einträge mit unterschiedlichen Faktoren auf die gleiche Lohnart gebucht!",
                });
            } else {
                wageTypeValues.set(wageType, { wagePerUnit, factor });
            }

            switch (type) {
                case PayrollItemType.WorkDays:
                case PayrollItemType.VacationDays:
                case PayrollItemType.SickDays:
                    content += `1;${employee.staffNumber};${from};${formatNumber(amount, 2, false)};10;${wageType};${
                        costCenter || ""
                    }\n`;
                    break;
                case PayrollItemType.TimeWage:
                case PayrollItemType.Absence:
                case PayrollItemType.BonusTaxFree:
                case PayrollItemType.BonusTaxed:
                case PayrollItemType.Meals:
                case PayrollItemType.HourAdjustment:
                case PayrollItemType.VacationAdjustment:
                    content += `1;${employee.staffNumber};${from};${formatNumber(amount, 2, false)};1;${wageType};${
                        costCenter || ""
                    }\n`;
                    break;
                case PayrollItemType.PayAdvance:
                    content += `1;${employee.staffNumber};${from};${formatNumber(
                        Math.abs(amount),
                        2,
                        false
                    )};3;${wageType};${costCenter || ""}\n`;
                    break;
                case PayrollItemType.Salary:
                case PayrollItemType.Benefit:
                case PayrollItemType.Commission:
                case PayrollItemType.BonusAdvance:
                    content += `1;${employee.staffNumber};${from};${formatNumber(
                        result || 0,
                        2,
                        false
                    )};2;${wageType};${costCenter || ""}\n`;
                    break;
            }
        }
    }

    return {
        name: `Lohn_${client}_${date.getMonth() + 1}_${date.getFullYear()}_LODAS`,
        content: Buffer.from(toDOSLineEndings(content), "latin1"),
        type: MimeType.TXT,
        messages,
    };
}

export function formatAgenda(company: Company, reports: PayrollReport[], from: string) {
    const client = company.settings.accounting.client;
    const date = parseDateString(from)!;

    let content = `Personalnr.;Lohnart;Lohnsatz;Wert;Kostenstelle\r\n`;

    const messages: ExportResponse["messages"] = [];

    for (const { employee, items } of reports) {
        if (!employee.staffNumber) {
            messages.push({
                type: "warning",
                content: `Fehlende Personalnummer für ${employee.firstName} ${employee.lastName}. Mitarbeiter wurde übersprungen.`,
            });
            continue;
        }

        const wageTypeValues = new Map<string, Partial<PayrollItem>>();
        const aggregatedItems = aggregatePayrollItems(items);

        for (const {
            type,
            wageType,
            costCenter,
            wagePerUnit,
            description,
            amount,
            result,
            factor,
        } of aggregatedItems) {
            if (!wageType) {
                messages.push({
                    type: "info",
                    content: `Übersprungen wegen fehlender Lohnart: ${employee.firstName}, ${employee.lastName} - ${description}`,
                });
                continue;
            }

            const wtValues = wageTypeValues.get(wageType);

            if (wtValues && (wtValues.wagePerUnit !== wagePerUnit || wtValues.factor !== factor)) {
                messages.push({
                    type: "warning",
                    content:
                        "Es wurden zwei oder mehrere Einträge mit unterschiedlichen Faktoren auf die gleiche Lohnart gebucht!",
                });
            } else {
                wageTypeValues.set(wageType, { wagePerUnit, factor });
            }

            switch (type) {
                case PayrollItemType.TimeWage:
                case PayrollItemType.Absence:
                case PayrollItemType.BonusTaxFree:
                case PayrollItemType.BonusTaxed:
                case PayrollItemType.WorkDays:
                case PayrollItemType.VacationDays:
                case PayrollItemType.SickDays:
                case PayrollItemType.PayAdvance:
                case PayrollItemType.Meals:
                case PayrollItemType.HourAdjustment:
                case PayrollItemType.VacationAdjustment:
                    content += `${employee.staffNumber};${wageType};;${formatNumber(amount, 2, false)};${
                        costCenter || ""
                    }\r\n`;
                    break;
                case PayrollItemType.Salary:
                case PayrollItemType.Benefit:
                case PayrollItemType.Commission:
                case PayrollItemType.BonusAdvance:
                    content += `${employee.staffNumber};${wageType};;${formatNumber(result || 0, 2, false)};${
                        costCenter || ""
                    }\r\n`;
                    break;
            }
        }
    }

    return {
        name: `Lohn32_${client}_${(date.getMonth() + 1).toString().padStart(2, "0")}_${date.getFullYear()}`,
        content: Buffer.from(content, "latin1"),
        type: MimeType.CSV,
        messages,
    };
}

export function formatLexware(company: Company, reports: PayrollReport[], from: string) {
    const client = company.settings.accounting.client;
    const date = parseDateString(from)!;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let content = `PersonalNr;Monat;Jahr;Datum;LohnArt;AnzahlStunden;AnzahlTage;Wert\r\n`;

    const messages: ExportResponse["messages"] = [];

    for (const { employee, items } of reports) {
        if (!employee.staffNumber) {
            messages.push({
                type: "warning",
                content: `Fehlende Personalnummer für ${employee.firstName} ${employee.lastName}. Mitarbeiter wurde übersprungen.`,
            });
            continue;
        }

        const wageTypeValues = new Map<string, Partial<PayrollItem>>();
        const aggregatedItems = aggregatePayrollItems(items);

        for (const { type, wageType, wagePerUnit, description, amount, result, factor } of aggregatedItems) {
            if (!wageType) {
                messages.push({
                    type: "info",
                    content: `Übersprungen wegen fehlender Lohnart: ${employee.firstName}, ${employee.lastName} - ${description}`,
                });
                continue;
            }

            const wtValues = wageTypeValues.get(wageType);

            if (wtValues && (wtValues.wagePerUnit !== wagePerUnit || wtValues.factor !== factor)) {
                messages.push({
                    type: "warning",
                    content:
                        "Es wurden zwei oder mehrere Einträge mit unterschiedlichen Faktoren auf die gleiche Lohnart gebucht!",
                });
            } else {
                wageTypeValues.set(wageType, { wagePerUnit, factor });
            }

            switch (type) {
                case PayrollItemType.TimeWage:
                case PayrollItemType.Absence:
                case PayrollItemType.BonusTaxFree:
                case PayrollItemType.BonusTaxed:
                case PayrollItemType.Meals:
                case PayrollItemType.HourAdjustment:
                case PayrollItemType.VacationAdjustment:
                    content += `${employee.staffNumber};${month};${year};;${wageType};${formatNumber(
                        amount,
                        2,
                        false
                    )};;\r\n`;
                    break;
                case PayrollItemType.WorkDays:
                case PayrollItemType.VacationDays:
                case PayrollItemType.SickDays:
                    content += `${employee.staffNumber};${month};${year};;${wageType};;${formatNumber(
                        amount,
                        2,
                        false
                    )};\r\n`;
                    break;
                case PayrollItemType.Salary:
                case PayrollItemType.Benefit:
                case PayrollItemType.Commission:
                case PayrollItemType.BonusAdvance:
                    content += `${employee.staffNumber};${month};${year};;${wageType};;;${formatNumber(
                        result || 0,
                        2,
                        false
                    )}\r\n`;
                    break;
                case PayrollItemType.PayAdvance:
                    content += `${employee.staffNumber};${month};${year};;${wageType};;;${formatNumber(
                        amount,
                        2,
                        false
                    )}\r\n`;
                    break;
            }
        }
    }

    return {
        name: `Lohn_${client}_${date.getMonth() + 1}_${date.getFullYear()}_LEXWARE`,
        content: Buffer.from(content, "latin1"),
        type: MimeType.CSV,
        messages,
    };
}

export function formatAddison(company: Company, reports: PayrollReport[], from: string) {
    const client = company.settings.accounting.client;
    const date = parseDateString(from)!;

    let content = "";

    const messages: ExportResponse["messages"] = [];

    for (const { employee, items } of reports) {
        if (!employee.staffNumber) {
            messages.push({
                type: "warning",
                content: `Fehlende Personalnummer für ${employee.firstName} ${employee.lastName}. Mitarbeiter wurde übersprungen.`,
            });
            continue;
        }

        const wageTypeValues = new Map<string, Partial<PayrollItem>>();
        const aggregatedItems = aggregatePayrollItems(items);

        for (const {
            type,
            wageType,
            costCenter,
            wagePerUnit,
            description,
            amount,
            result,
            factor,
        } of aggregatedItems) {
            if (!wageType) {
                messages.push({
                    type: "info",
                    content: `Übersprungen wegen fehlender Lohnart: ${employee.firstName}, ${employee.lastName} - ${description}`,
                });
                continue;
            }

            const wtValues = wageTypeValues.get(wageType);

            if (wtValues && (wtValues.wagePerUnit !== wagePerUnit || wtValues.factor !== factor)) {
                messages.push({
                    type: "warning",
                    content:
                        "Es wurden zwei oder mehrere Einträge mit unterschiedlichen Faktoren auf die gleiche Lohnart gebucht!",
                });
            } else {
                wageTypeValues.set(wageType, { wagePerUnit, factor });
            }

            switch (type) {
                case PayrollItemType.TimeWage:
                case PayrollItemType.Absence:
                case PayrollItemType.BonusTaxFree:
                case PayrollItemType.BonusTaxed:
                case PayrollItemType.Meals:
                case PayrollItemType.HourAdjustment:
                case PayrollItemType.VacationAdjustment:
                    content += `${client};${employee.staffNumber};${wageType};${
                        costCenter || ""
                    };;${date.getDate()};${formatDate(from)};;;;${formatNumber(amount, 2, false)};\r\n`;
                    break;
                case PayrollItemType.WorkDays:
                case PayrollItemType.VacationDays:
                case PayrollItemType.SickDays:
                    content += `${client};${employee.staffNumber};${wageType};${
                        costCenter || ""
                    };;${date.getDate()};${formatDate(from)};;;${formatNumber(amount, 2, false)};;\r\n`;
                    break;
                case PayrollItemType.Salary:
                case PayrollItemType.Benefit:
                case PayrollItemType.Commission:
                case PayrollItemType.BonusAdvance:
                    content += `${client};${employee.staffNumber};${wageType};${
                        costCenter || ""
                    };;${date.getDate()};${formatDate(from)};;;;;${formatNumber(result || 0, 2, false)}\r\n`;
                    break;
                case PayrollItemType.PayAdvance:
                    content += `${client};${employee.staffNumber};${wageType};${
                        costCenter || ""
                    };;${date.getDate()};${formatDate(from)};;;;;${formatNumber(amount, 2, false)}\r\n`;
                    break;
            }
        }
    }

    return {
        name: `Lohn_${client}_${date.getMonth() + 1}_${date.getFullYear()}_Addison`,
        content: Buffer.from(content, "latin1"),
        type: MimeType.CSV,
        messages,
    };
}

function getLohnAGShortCode(entry: TimeEntry) {
    if (entry.position && entry.position.name === "Schule") {
        return "s";
    }

    switch (entry.type) {
        case TimeEntryType.Work:
            return "";
        case TimeEntryType.Sick:
            return "k";
        case TimeEntryType.Vacation:
            return "u";
        case TimeEntryType.ChildSick:
            return "i";
        case TimeEntryType.SickInKUG:
            return "r";
        case TimeEntryType.CompDay:
            return "g";
        default:
            return null;
    }
}

export function formatLohnAG(company: Company, timeEntries: TimeEntry[], from: string, to: string) {
    const client = company.settings.accounting.client;
    const date = parseDateString(from)!;

    let content = "PersonalNr;Datum;KommenZeit;GehenZeit;Status\r\n";

    const messages: ExportResponse["messages"] = [];

    for (const timeEntry of timeEntries.filter(
        (entry) =>
            entry.date >= from &&
            entry.date < to &&
            (timeEntryAbsenceTypes.some((absenceType) => absenceType === entry.type) ||
                (entry.startFinal && entry.endFinal))
    )) {
        if (!timeEntry.employeeId) {
            continue;
        }

        const employee = company.getEmployee(timeEntry.employeeId)!;

        if (!employee.staffNumber) {
            messages.push({
                type: "warning",
                content: `Fehlende Personalnummer für ${employee.firstName} ${employee.lastName}. Mitarbeiter wurde übersprungen.`,
            });
            continue;
        }

        content += `${employee.staffNumber};${formatDate(timeEntry.date)};${toTimeString(timeEntry.startFinal) || ""};${
            toTimeString(timeEntry.endFinal) || ""
        };${getLohnAGShortCode(timeEntry)}\r\n`;
    }

    return {
        name: `Lohn_${client}_${date.getMonth() + 1}_${date.getFullYear()}_LohnAG`,
        content: Buffer.from(content, "latin1"),
        type: MimeType.CSV,
        messages,
    };
}

export function formatBMDWages(company: Company, reports: PayrollReport[], from: string) {
    const client = company.settings.accounting.client;
    const date = parseDateString(from)!;

    let content = "Monat;Firma;Mitarbeiter;Lohnart;Stunden;Betrag\r\n";

    const messages: ExportResponse["messages"] = [];

    for (const { employee, items } of reports) {
        if (!employee.staffNumber) {
            messages.push({
                type: "warning",
                content: `Fehlende Personalnummer für ${employee.firstName} ${employee.lastName}. Mitarbeiter wurde übersprungen.`,
            });
            continue;
        }

        const aggregatedItems = aggregatePayrollItems(items);

        for (const item of aggregatedItems) {
            const { wageType, unit, description, type } = item;
            let amount = item.amount;

            if (!wageType) {
                messages.push({
                    type: "info",
                    content: `Übersprungen wegen fehlender Lohnart: ${employee.firstName}, ${employee.lastName} - ${description}`,
                });
                continue;
            }

            // Pay advances have to be negative
            if (type === PayrollItemType.PayAdvance) {
                amount *= -1;
            }

            switch (unit) {
                case "hours":
                    content += `${date.getMonth() + 1};${company.settings.accounting.client};${
                        employee.staffNumber
                    };${wageType};${formatNumber(amount, 2, false)};\r\n`;
                    break;
                case "euros":
                    content += `${date.getMonth() + 1};${company.settings.accounting.client};${
                        employee.staffNumber
                    };${wageType};;${formatNumber(amount, 2, false)}\r\n`;
                    break;
            }
        }
    }

    return {
        name: `BMD_Lohn_${client}_${date.getMonth() + 1}_${date.getFullYear()}`,
        content: Buffer.from(content, "latin1"),
        type: MimeType.CSV,
        messages,
    };
}

export function formatBMDAbsences(client: string, employees: Employee[], absences: Absence[], from: string) {
    const date = parseDateString(from)!;

    //                1         2     3         4                5         6   7    8     9        10
    let content = "DV-Firma;Mitarb-Nr;Art;Gutstundentopf;Verarbeitungs-KZ;Von;Bis;Tage;Stunden;Anmerkung\r\n";

    const messages: ExportResponse["messages"] = [];

    for (const employee of employees) {
        if (!employee.staffNumber) {
            messages.push({
                type: "warning",
                content: `Fehlende Personalnummer für ${employee.firstName} ${employee.lastName}. Mitarbeiter wurde übersprungen.`,
            });
            continue;
        }

        const employeeAbsences = absences.filter(
            (a) => a.employeeId === employee.id && a.status === AbsenceStatus.Approved
        );

        for (const absence of employeeAbsences) {
            const bmdAbsenceData = createBMDAbsenceRecord(absence, employee.id, client);
            content += createCSVRecord(bmdAbsenceData);
        }
    }

    return {
        name: `BMD_Nichtleistungszeiten_${client}_${date.getMonth() + 1}_${date.getFullYear()}`,
        content: Buffer.from(content, "latin1"),
        type: MimeType.CSV,
        messages,
    };
}

export function createBMDAbsenceRecord(absence: Absence, employeeId: number, client: string): BMDAbsenceRecord {
    return {
        client,
        employeeId,
        compHourBucket: "",
        type: getBMDAbsenceLabel(absence.type),
        handlingMark: "3",
        start: absence.start,
        end: dateAdd(absence.end, { days: -1 }),
        days: "",
        hours: "",
        notes: absence.notes,
    };
}

export function createCSVRecord(record: BMDAbsenceRecord) {
    return `${record.client};${record.employeeId};${record.type};${record.compHourBucket};${record.handlingMark};${record.start};${record.end};${record.days};${record.hours};${record.notes}\r\n`;
}

export function getBMDAbsenceLabel(type: TimeEntryType) {
    switch (type) {
        case TimeEntryType.SickInKUG:
            return "Krank";
        default:
            // This export is only relevant for AT customers, that's why the country is hard-coded
            return Localized["AT"].timeEntryTypeLabel(type);
    }
}

/**
 * Get hourly rate in euro cents for a given contract and position
 */
export function getHourlyRate(
    company: Company,
    contract: Contract,
    obj?: TimeEntry | Position | number | null | undefined
): Rate<Euros, Hours> {
    const weekFactor = company.settings.weekFactor || (4.35 as Factor);
    const comp = contract.getSalary(obj);

    if (!comp) {
        return 0 as Rate<Euros, Hours>;
    }

    if (comp.type === "hourly") {
        return getRate(comp.amount, 1 as Hours);
    }

    const salaryTotal = add(
        comp.amount,
        ...contract.benefits
            .filter((b) => company.benefitTypes?.find((t) => t.id === b.typeId)?.includeInBonusPayments)
            .map((benefit) => benefit.amount)
    );

    const averageHoursPerMonth = multiplyWithFactor(contract.nominalWeeklyHours, weekFactor);

    return round(getRate(salaryTotal, averageHoursPerMonth), 2);
}

export function getAncillaryCostFactor(type: EmploymentType): [Factor, Factor] {
    const ANCILLARY_COST_FACTORS: { [type: number]: [Factor, Factor] } = {
        [EmploymentType.ShortTerm]: [0 as Factor, 0 as Factor],
        [EmploymentType.Independent]: [0 as Factor, 0 as Factor],
        [EmploymentType.Marginal]: [0.348 as Factor, 0 as Factor],
        [EmploymentType.Regular]: [0.19825 as Factor, 0.19825 as Factor],
        [EmploymentType.Trainee]: [0.19825 as Factor, 0.19825 as Factor],
        [EmploymentType.MidiJob]: [0.19825 as Factor, 0.19825 as Factor],
        [EmploymentType.WorkingStudent]: [0.0935 as Factor, 0.0935 as Factor],
        [EmploymentType.DualStudent]: [0.19825 as Factor, 0.19825 as Factor],
        [EmploymentType.Intern]: [0 as Factor, 0 as Factor],
    };
    return ANCILLARY_COST_FACTORS[type] || [0 as Factor, 0 as Factor];
}

export function aggregatePayrollItems(items: PayrollItem[]) {
    return items.reduce((aggregatedItems, item) => {
        const existing = aggregatedItems.find((i) => i.wageType === item.wageType && i.costCenter === item.costCenter);

        if (existing) {
            existing.amount += item.amount;
            if (item.result) {
                existing.result = (existing.result || 0) + item.result;
            }
        } else {
            aggregatedItems = [...aggregatedItems, { ...item }];
        }

        return aggregatedItems;
    }, [] as PayrollItem[]);
}

/* 
    Definition: A Payment is either: 
    - a Salary
    - a Benefit
    - a SFN Advance

    A Contract is basically a DateRange as is the range we are looking at.
    Since payment calculations are always done on a monthly basis, we need to split the supplied range into month-long intervals.
    For each interval, we calculate the number of days the contract is active and then calculate the prorated payment for that period.
    To calculate the prorated payment correctly we need to consider the following cases:

    1. contract is active whole month --> entire payment
    |----- month -----|
    |------ con. -----|

    2. contract begins and ends within month --> payment for days with contract
    |----- month -----|
       |-- con. --|

    3. contract begins in month --> payment for days with contract until end of month
    |----- month -----|
           |-- con. ---------|
           
    4. contract ends in month --> payment for days with contract starting at beginning of month
          |----- month -----|
    |------- con. ---|

*/

export function calculateProratedPaymentForMonth(
    contract: Contract,
    range: DateRange,
    baseValue: Euros
): PaymentInterval {
    const [year, monthDigit] = range.from.split("-");
    const monthStart = `${year}-${monthDigit.padStart(2, "0")}-01` as DateString;
    // We work with exclusive end dates, so the end of the month is the first day of the next month
    const monthEnd = dateAdd(monthStart, { months: 1 });

    const latestStart = monthStart < range.from ? range.from : monthStart;
    const earliestEnd = monthEnd < range.to ? monthEnd : range.to;

    const effectiveRange = getEffectiveIntervalForContract(contract, { from: latestStart, to: earliestEnd });

    if (!effectiveRange) {
        return {
            from: range.from,
            to: range.to,
            amount: 0 as Euros,
        };
    }

    const { from, to } = effectiveRange;

    // Calculate the number of days in the month and the number of days the contract is active
    const totalDaysInMonth = dateSub(monthStart, monthEnd);
    const activeDays = dateSub(from, to);

    // Calculate the prorated payment
    const proratedPayment = round((activeDays / totalDaysInMonth) * baseValue, 2) as Euros;

    return {
        from: effectiveRange.from,
        to: effectiveRange.to,
        amount: proratedPayment,
    };
}

export function calculateProratedPaymentForRange(
    contract: Contract,
    range: DateRange,
    baseValue: Euros
): PaymentInterval {
    const effectiveRange = getEffectiveIntervalForContract(contract, range);

    if (!effectiveRange) {
        return {
            from: range.from,
            to: range.to,
            amount: 0 as Euros,
        };
    }

    const { from, to } = effectiveRange;

    // Calculate the number of days in the month and the number of days the contract is active
    const totalDaysInMonth = dateSub(range.from, range.to);
    const activeDays = dateSub(from, to);

    // Calculate the prorated payment
    const proratedPayment = round((activeDays / totalDaysInMonth) * baseValue, 2) as Euros;

    return {
        from: effectiveRange.from,
        to: effectiveRange.to,
        amount: proratedPayment,
    };
}

function getDaysInMonth(month: DateString): number {
    const { from, to } = getRange(month, "month");
    return dateSub(from, to);
}

function isExactlyOneMonth(range: DateRange): boolean {
    return dateAdd(range.from, { months: 1 }) === range.to;
}

export function getProratedPaymentForRange(
    contract: Contract,
    range: DateRange,
    payment: Salary | Benefit | Euros
): PaymentInterval[] {
    const baseValue = typeof payment === "number" ? payment : payment.amount;

    const effectiveRange = getEffectiveIntervalForContract(contract, range);

    if (!effectiveRange) {
        return [];
    }

    const monthIntervals = transformToMonthIntervals(effectiveRange);

    // contract has changes within the range
    if (!contract.isActiveOn(range.from) || !contract.isActiveOn(range.to)) {
        return calculateProratedPaymentsForContractWithChanges(contract, monthIntervals, baseValue, range);
    }

    // range is exactly one month, therefore we can return the entire payment
    if (isExactlyOneMonth(range)) {
        return [{ ...effectiveRange, amount: baseValue }];
    }

    return monthIntervals.map((interval) => {
        return calculateProratedPaymentForMonth(contract, interval, baseValue);
    });
}

function calculateProratedPaymentsForContractWithChanges(
    contract: Contract,
    monthIntervals: DateRange[],
    baseValue: Euros,
    range: DateRange
): PaymentInterval[] {
    let rangeDays = dateSub(range.from, range.to);

    const daysInMonths = transformToMonthIntervals(range).map((interval) => getDaysInMonth(interval.from));

    const { fullSalaries, remainingDays } = calculateFullSalariesAndRemainingDays(rangeDays, daysInMonths);

    const additionalFactor = calculateAdditionalFactor(remainingDays, daysInMonths);

    const multiplier = fullSalaries + additionalFactor;

    let totalSalary = baseValue * multiplier;

    return monthIntervals.reduce((payments, interval) => {
        const effectiveRange = getEffectiveRangeForInterval(contract, interval);
        if (effectiveRange) {
            const activeDays = dateSub(effectiveRange.from, effectiveRange.to);
            let amount = 0 as Euros;
            if (isExactlyOneMonth(interval)) {
                amount = baseValue;
            } else {
                amount = round(((totalSalary * activeDays) / rangeDays) as Euros, 2);
            }
            totalSalary -= amount;
            rangeDays -= activeDays;
            payments.push({ ...effectiveRange, amount });
        }
        return payments;
    }, [] as PaymentInterval[]);
}

function calculateFullSalariesAndRemainingDays(
    rangeDays: number,
    daysInMonths: number[]
): { fullSalaries: number; remainingDays: number } {
    let fullSalaries = 0;
    let remainingDays = rangeDays;

    for (const daysInMonth of daysInMonths) {
        if (remainingDays - daysInMonth < 0) break;
        fullSalaries++;
        remainingDays -= daysInMonth;
    }

    return { fullSalaries, remainingDays };
}

function calculateAdditionalFactor(remainingDays: number, daysInMonths: number[]): number {
    return remainingDays > 0 && daysInMonths.length > 0 ? remainingDays / daysInMonths[0] : 0;
}

function getEffectiveRangeForInterval(contract: Contract, interval: DateRange): DateRange | null {
    const [year, monthDigit] = interval.from.split("-");
    const monthStart = `${year}-${monthDigit.padStart(2, "0")}-01` as DateString;
    const monthEnd = dateAdd(monthStart, { months: 1 });

    const latestStart = monthStart < interval.from ? interval.from : monthStart;
    const earliestEnd = monthEnd < interval.to ? monthEnd : interval.to;

    return getEffectiveIntervalForContract(contract, { from: latestStart, to: earliestEnd });
}

export function getEffectiveIntervalForContract(contract: Contract, range: DateRange): DateRange | null {
    const contractEnd = contract.end ? contract.end : toDateString(new Date(Number.MAX_SAFE_INTEGER));

    const effectiveStart = contract.start > range.from ? contract.start : range.from;
    const effectiveEnd = contractEnd < range.to ? contractEnd : range.to;

    if (effectiveStart >= effectiveEnd) {
        return null;
    }

    return { from: effectiveStart, to: effectiveEnd };
}

export function transformToMonthIntervals(dateRange: DateRange): DateRange[] {
    const { from, to } = dateRange;

    const intervals: DateRange[] = [];

    let currentStart = from;

    while (currentStart < to) {
        // Get first day of next month
        const lastDayOfMonth = getRange(currentStart, "month").to;

        // Determine the end date of the current interval
        const currentEndDate = lastDayOfMonth < to ? lastDayOfMonth : to;

        // Push the interval into the array
        intervals.push({
            from: currentStart,
            to: currentEndDate,
        });

        // Move to the start of the next month
        currentStart = currentEndDate;
    }

    return intervals;
}
