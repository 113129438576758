import { css } from "lit";
import { reset } from "./reset";
import { base } from "./base";
import { layout } from "./layout";
import { icons } from "./icons";
import { scroll, ellipsis, fullbleed, customScrollbar } from "./mixins";

export const shared = css`
    ${reset}
    ${base}
    ${layout}
    ${icons}

    header h1 {
        margin: 0 8px;
    }

    .tabs {
        display: flex;
        flex-wrap: nowrap;
        ${scroll()};
    }

    .tabs.vertical {
        flex-direction: column;
    }

    .tabs.vertical > button {
        text-align: left;
    }

    .tabs.vertical > button:not(:last-child) {
        margin-bottom: 0.25em;
    }

    .tabs.horizontal {
        grid-gap: 0.5em;
    }

    .tabs > button > .detail {
        margin-top: 5px;
        font-size: var(--font-size-small);
    }

    .tabs > button {
        flex: none;
        margin: 0;
        text-align: left;
        padding: 0.5em;
        background: transparent;
    }

    .tabs > button[active] {
        background: transparent;
        color: var(--color-primary);
        font-weight: bold;
    }

    .tiny {
        font-size: var(--font-size-tiny);
    }

    .tinier {
        font-size: var(--font-size-tinier);
    }

    .small {
        font-size: var(--font-size-small);
    }

    .smaller {
        font-size: var(--font-size-smaller);
    }

    .medium {
        font-size: var(--font-size-medium);
    }

    .mediumer {
        font-size: var(--font-size-mediumer);
    }

    .default {
        font-size: var(--font-size-default);
    }

    .large {
        font-size: var(--font-size-large);
    }

    .larger {
        font-size: var(--font-size-larger);
    }

    .big {
        font-size: var(--font-size-big);
    }

    .bigger {
        font-size: var(--font-size-bigger);
    }

    .huge {
        font-size: var(--font-size-huge);
    }

    .huger {
        font-size: var(--font-size-huger);
    }

    .massive {
        font-size: var(--font-size-massive);
    }

    .enormous {
        font-size: var(--font-size-enormous);
    }

    .giant {
        font-size: var(--font-size-giant);
    }

    .thin,
    .light {
        font-weight: 300 !important;
    }

    .normal-weight {
        font-weight: 400;
    }

    .bold {
        font-weight: bold !important;
    }

    .semibold {
        font-weight: 600 !important;
    }

    .subtle {
        opacity: 0.75;
    }

    .faded {
        opacity: 0.5;
    }

    .nowrap {
        white-space: nowrap;
    }

    .regular-style {
        font-variant: normal;
        text-transform: none;
        letter-spacing: unset;
    }

    .small-caps {
        font-variant: small-caps;
        letter-spacing: 0.1em;
    }

    .all-small-caps {
        font-variant: all-small-caps;
        letter-spacing: 0.1em;
    }

    .uppercase {
        text-transform: uppercase;
        letter-spacing: 0.1em;
    }

    .line-through {
        text-decoration: line-through;
    }

    .underlined {
        text-decoration: underline;
    }

    .pills {
        margin: 0 -2px -6px 0;
    }

    .pill {
        display: inline-block;
        border-radius: var(--border-radius);
        color: var(--color-highlight, rgba(0, 0, 0, 0.5));
        border: solid 1px var(--color-highlight, var(--shade-1));
        box-sizing: border-box;
        padding: 0.25em 0.5em;
        font-weight: 600;
    }

    .pills > .pill {
        margin: 0 2px 6px 0;
    }

    .pill .detail {
        display: inline;
        opacity: 0.5;
    }

    .pill.slim {
        padding: 0.1em 0.3em;
    }

    .pill.inverted {
        background: var(--color-highlight, var(--color-gray));
        color: var(--color-bg);
    }

    .pill > i {
        display: inline;
    }

    .divider {
        display: flex;
        align-items: center;
        margin: 0.5em 0;
        opacity: 0.7;
    }

    .divider::before,
    .divider::after {
        content: "";
        display: block;
        margin: 1em;
        flex: 1;
        height: 1px;
        background: currentColor;
        opacity: 0.7;
    }

    .divider:empty::before {
        margin-right: 0;
    }

    .divider:empty::after {
        margin-left: 0;
    }

    .message {
        color: var(--color-highlight, rbga(0, 0, 0, 0.5));
        border-radius: var(--border-radius);
        background: var(--color-highlight-bg, var(--shade-1));
        border: solid 1px;
        padding: 0.8em 1em;
        position: relative;
        margin: 1em;
    }

    .message.primary {
        background: var(--color-primary-bg);
        color: var(--color-primary);
    }

    .message.positive {
        background: var(--color-positive-bg);
        color: var(--color-positive);
    }

    .message.negative {
        background: var(--color-negative-bg);
        color: var(--color-negative);
    }

    .message .close-icon {
        position: absolute;
        top: 0.6em;
        right: 0.6em;
    }

    .box {
        color: var(--color-highlight);
        border-radius: 0.5em;
        border: solid 1px var(--color-highlight, var(--shade-2));
        position: relative;
        overflow: hidden;
    }

    .box.inverted {
        background: var(--color-highlight);
        color: var(--color-bg);
    }

    .ellipsis {
        ${ellipsis()};
    }

    .rounded {
        border-radius: 0.5em;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .fullbleed {
        ${fullbleed()};
    }

    .scrim {
        background: rgba(255, 255, 255, 0.7);
        z-index: 10;
    }

    .sub-menu {
        padding: 0.5em;
        border-right: solid 1px var(--shade-1);
    }

    .popover-menu {
        display: flex;
        flex-direction: column;
        padding: 0.5em;
    }

    .popover-menu button {
        background: transparent;
        display: block;
        text-align: left;
        flex: none;
        width: 100%;
    }

    .padded-full {
        padding: 1em;
    }

    .padded-medium {
        padding: 0.7em;
    }

    .padded-light {
        padding: 0.5em;
    }

    .pad-children > :not(:last-child) {
        margin-right: 0.5em;
    }

    .border-top {
        border-top: solid 1px var(--shade-2);
    }

    .border-bottom {
        border-bottom: solid 1px var(--shade-2);
    }

    .border-left {
        border-left: solid 1px var(--shade-2);
    }

    .border-right {
        border-right: solid 1px var(--shade-2);
    }

    .input-group > .input:not(:last-child) input,
    .input-group > input:not(:last-child),
    .input-group > select:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group > .input:not(:first-child) input,
    .input-group > input:not(:first-child),
    .input-group > select:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .focus-container {
        border: solid 1px var(--shade-2);
        border-radius: 0.5em;
    }

    .focus-container > input {
        border: none;
    }

    .focus-container:focus-within {
        border-color: var(--color-primary);
    }

    .poster > i {
        font-size: 5rem;
        color: var(--color-primary);
    }

    .poster > div {
        width: 23em;
        font-size: var(--font-size-medium);
        margin: 1em 1em 2em 1em;
        text-align: center;
    }

    .poster > button {
        background: var(--color-primary);
        color: var(--color-bg);
        font-size: var(--font-size-medium);
        border-radius: calc(2 * var(--border-radius));
    }

    .changelog {
        font-size: var(--font-size-small);
        padding: 1em;
    }

    .changelog h2 {
        border-bottom: none;
        font-weight: 400
        padding: 0;
        font-size: 1.3em;
        margin: 0.5em 0;
    }

    .changelog h3 {
        font-size: 1.2em;
        font-weight: 600;
        margin: 0.5em 0;
    }

    .changelog h4 {
        font-size: 1.1em;
        font-weight: 700;
        margin: 0.5em 0;
    }

    .changelog ul {
        list-style: outside;
        padding-left: 2em;
    }

    .changelog li {
        padding: 0;
        margin: 0.5em 0;
        border: none !important;
    }

    ol.numbered {
        list-style: outside;
        list-style-type: decimal;
        padding-left: 1em;
    }

    .check-group {
        display: block;
        margin: 0.5em;
        border-radius: 0.5em;
        border: solid 1px var(--shade-2);
    }

    .check-group > .title {
        padding: 0.8em 1em 1em 1em;
        font-weight: 600;
        margin-bottom: -0.8em;
    }

    .check-group > .children {
        font-size: 0.9em;
    }

    .check-group > .description {
        font-size: 0.9em;
        margin: 0.2em 0.9em 0.9em 0.9em;
    }

    .check-group .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
        margin: 0.5em;
        grid-gap: 0.5em;
    }

    .check-group > .grid.wide {
        grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
    }

    .check-group .grid > .check-group {
        margin: 0;
    }

    .card {
        border-radius: calc(2 * var(--border-radius));
        box-shadow: rgba(0, 0, 0, 0.3) 0 1px 10px -5px;
        background: var(--color-bg);
        position: relative;
        overflow: hidden;
    }

    button .badge {
        background: var(--color-highlight);
        color: var(--color-bg);
        border-radius: 1em;
        line-height: 1.5em;
        padding: 0px 0.3em;
        min-width: 1em;
        text-align: center;
        position: absolute;
        bottom: 0px;
        right: 0px;
        transform: translate(25%, 25%);
        font-size: var(--font-size-smaller);
        z-index: 1;
    }

    .select-wrapper {
        position: relative;
    }

    .select-wrapper select {
        padding-right: 1.5em;
    }

    .select-wrapper i:last-child {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        pointer-events: none;
    }

    .strike-through {
        background: linear-gradient(
            to top left,
            transparent 0%,
            transparent 47%,
            currentColor 48%,
            currentColor 52%,
            transparent 53%,
            transparent 100%
        );
    }

    .non-interactive {
        pointer-events: none;
    }

    .link:not(i) {
        text-decoration: underline;
        cursor: pointer;
    }

    .hover-highlight:hover {
        color: var(--color-highlight, var(--color-primary));
        cursor: pointer;
    }

    :not(:hover) > .reveal-on-parent-hover:not(:focus-within) {
        opacity: 0;
    }

    .key {
        display: inline-block;
        height: 1.5em;
        line-height: 1.5em;
        padding: 0 0.5em;
        text-align: center;
        border-radius: 0.5em;
        background: var(--shade-1);
        border: solid 1px var(--shade-2);
        border-bottom-width: 3px;
        color: var(--shade-5);
        font-weight: 600;
        font-size: var(--font-size-smaller);
    }

    table.simple {
        border-collapse: separate;
    }

    table.simple th,
    table.simple td {
        padding: 0.5em;
        vertical-align: top;
    }

    table.simple thead th {
        font-weight: 600;
        background: var(--color-bg);
        text-align: center;
        vertical-align: bottom;
    }

    table.simple tbody > tr > * {
        border-top: solid 1px var(--shade-2);
    }

    table.rounded {
        border-collapse: separate;
    }

    table.rounded thead {
        position: sticky;
        top: 0;
        z-index: 2;
    }

    table.rounded th,
    table.rounded td {
        padding: 0.5em;
        vertical-align: middle;
    }

    table.rounded thead th {
        font-weight: bold;
        background: var(--color-bg);
        text-align: center;
    }

    table.rounded thead th[scope="col"] {
        text-align: left;
    }

    table.rounded tbody::after {
        content: "";
        display: block;
        height: 1em;
    }

    table.rounded tbody > tr > * {
        border-bottom: solid 1px var(--shade-2);
    }

    table.rounded tbody > tr:first-of-type > * {
        border-top: solid 1px var(--shade-2);
    }

    table.rounded tbody > tr > :first-child {
        border-left: solid 1px var(--shade-2);
    }

    table.rounded tbody > tr > :last-child {
        border-right: solid 1px var(--shade-2);
    }

    table.rounded tbody > tr:first-of-type > :first-child {
        border-top-left-radius: 0.5em;
    }

    table.rounded tbody > tr:first-of-type > :last-child {
        border-top-right-radius: 0.5em;
    }

    table.rounded tbody > tr:last-of-type > :first-child {
        border-bottom-left-radius: 0.5em;
    }

    table.rounded tbody > tr:last-of-type > :last-child {
        border-bottom-right-radius: 0.5em;
    }

    @media not print {
        .printonly {
            position: absolute;
            visibility: hidden;
            pointer-events: none;
        }
    }

    @media print {
        .noprint {
            display: none !important;
        }

        * {
            box-shadow: none !important;
        }
    }

    ${customScrollbar()}
`;
