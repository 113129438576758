import { Err, ErrorCode } from "@pentacode/core/src/error";
import { Marshaler, JSONMarshaler } from "@pentacode/core/src/encoding";
import { Request, Response, Sender } from "@pentacode/core/src/transport";
import { version } from "@pentacode/core/src/version";

export type Method = "GET" | "POST" | "PUT" | "DELETE";

export async function request(
    url: string,
    {
        method = "GET",
        body,
        headers,
        responseType = "text",
    }: {
        method?: Method;
        body?: string;
        headers?: Map<string, string>;
        responseType?: XMLHttpRequestResponseType;
    }
): Promise<XMLHttpRequest> {
    const req = new XMLHttpRequest();
    req.responseType = responseType;

    return new Promise<XMLHttpRequest>((resolve, reject) => {
        req.onreadystatechange = () => {
            if (req.readyState === 4) {
                if (!req.status) {
                    reject(
                        new Err(
                            ErrorCode.FAILED_CONNECTION,
                            "Die Applikation konnte keine Verbindung zum Pentacode Server herstellen. Bitte prüfen Sie Ihre Internetverbindung!"
                        )
                    );
                } else {
                    resolve(req);
                }
            }
        };

        try {
            req.open(method, url, true);
            if (headers) {
                headers.forEach((value, key) => req.setRequestHeader(key, value));
            }
            req.send(body);
        } catch (e) {
            reject(
                new Err(
                    ErrorCode.FAILED_CONNECTION,
                    "Die Applikation konnte keine Verbindung zum Pentacode Server herstellen. Bitte prüfen Sie Ihre Internetverbindung!"
                )
            );
        }
    });
}

export class AjaxSender implements Sender {
    constructor(
        public url: string,
        public marshaler: Marshaler = new JSONMarshaler()
    ) {}

    async send(req: Request): Promise<Response> {
        if (!req.clientInfo) {
            req.clientInfo = {
                version,
                userAgent: navigator.userAgent,
                currentUrl: window.location.href,
                screenWidth: window.screen.width,
                screenHeight: window.screen.height,
            };
        }
        const body = this.marshaler.marshal(req);
        const res = await request(this.url, {
            method: "POST",
            body,
            headers: new Map<string, string>([
                ["Content-Type", this.marshaler.mimeType],
                ["Accept", this.marshaler.mimeType],
            ]),
        });
        try {
            return this.marshaler.unmarshal(res.responseText);
        } catch (e) {
            throw new Err(ErrorCode.SERVER_ERROR);
        }
    }
}
