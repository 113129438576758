import "./fonts/fonts.css";
import { css } from "lit";

export { colors } from "@pentacode/core/src/colors";

export const config = css`
    :host {
        --red: #db2828;
        --orange: #f2711c;
        --yellow: #fbbd08;
        --olive: #b5cc18;
        --green: #21ba45;
        --teal: #00b5ad;
        --blue: #2185d0;
        --violet: #6435c9;
        --purple: #a333c8;
        --pink: #e03997;
        --brown: #a5673f;
        --grey: #767676;
        --black: #1b1c1d;
        --aqua: #1cd0bf;
        --white: #ffffff;

        --red-bg: #ffe8e6;
        --orange-bg: #ffedde;
        --yellow-bg: #fff8db;
        --olive-bg: #fbfdef;
        --green-bg: #e5f9e7;
        --teal-bg: #e1f7f7;
        --blue-bg: #dff0ff;
        --violet-bg: #eae7ff;
        --purple-bg: #f6e7ff;
        --pink-bg: #ffe3fb;
        --brown-bg: #f1e2d3;
        --aqua-bg: #c8fdf8;
        --grey-bg: #f1f1f1;

        --shade-1: rgba(0, 0, 0, 0.05);
        --shade-2: rgba(0, 0, 0, 0.1);
        --shade-3: rgba(0, 0, 0, 0.15);
        --shade-4: rgba(0, 0, 0, 0.2);
        --shade-5: rgba(0, 0, 0, 0.25);

        --color-bg-light: #fff;
        --color-bg-dark: #333;
        --color-fg-light: rgba(0, 0, 0, 0.8);
        --color-fg-dark: rgba(255, 255, 255, 1);
        --color-bg: var(--color-bg-light);
        --color-fg: var(--color-fg-light);

        --color-primary: var(--blue);
        --color-primary-bg: var(--blue-bg);
        --color-positive: var(--green);
        --color-positive-bg: var(--green-bg);
        --color-negative: var(--red);
        --color-negative-bg: var(--red-bg);

        --border-radius: 0.5em;

        --font-family: "Nunito", sans-serif;
        /*
        --font-family: "Lato", sans-serif;
        --font-family: "Roboto", sans-serif;
        --font-family: "Montserrat", sans-serif;
        --font-family: "Montserrat Alternates", sans-serif;
        */

        --font-size-base: 16px;

        --font-size-tiny: 0.6rem;
        --font-size-small: 0.8rem;
        --font-size-default: 0.875rem;
        --font-size-mediumer: 1em;
        --font-size-medium: 1rem;
        --font-size-large: 1.1rem;
        --font-size-big: 1.2rem;
        --font-size-huge: 1.5rem;
        --font-size-massive: 2rem;
        --font-size-enormous: 2.5rem;
        --font-size-giant: 4rem;

        --font-size-tinier: 0.6em;
        --font-size-smaller: 0.8em;
        --font-size-larger: 1.1em;
        --font-size-bigger: 1.2em;
        --font-size-huger: 1.5em;
        --font-size-massiver: 2em;
        --font-size-enormouser: 2.5em;
        --font-size-gianter: 4em;

        --gap-size: 0.7em;

        --spacing: 0.5em;
    }
`;
