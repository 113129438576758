import { Dialog } from "@pentacode/app/src/elements/dialog";
import { html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { fullbleed } from "@pentacode/app/src/styles/mixins";
import { PentacodeAPIModels } from "@pentacode/core/src/rest/api";
import "@pentacode/app/src/elements/avatar";
import { toDurationString, toTimeString } from "@pentacode/core/src/util";
import { TimeEntry } from "@pentacode/core/src/model";

type CompletionDialogParams = {
    timeEntry: TimeEntry;
    employee: PentacodeAPIModels["Employee"];
};

@customElement("ptc-time-log-completion-dialog")
export class CompletionDialog extends Dialog<CompletionDialogParams, void> {
    @state()
    private _entry: CompletionDialogParams["timeEntry"];
    @state()
    private _employee: CompletionDialogParams["employee"];

    preventDismiss = true;

    show({ timeEntry, employee }: CompletionDialogParams) {
        this._entry = timeEntry;
        this._employee = employee;
        return super.show();
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                ${fullbleed()};
                --dialog-max-width: none;
            }

            .time-display {
                margin-bottom: -0.2em;
            }
        `,
    ];

    private _renderTimeEntry(timeEntry: CompletionDialogParams["timeEntry"]) {
        const start = timeEntry.startPlanned || timeEntry.startFinal!;
        const end = timeEntry.endPlanned || timeEntry.endFinal;

        return html`
            <div class="spacing vertical layout" style="min-width:20em;">
                <div class="blue colored-text horizontally-padded semibold text-centering small-caps">
                    <i class="check"></i> Schicht Abgeschlossen
                </div>

                <div class="padded box horizontal layout" style="--color-highlight: ${timeEntry.position!.color}">
                    <div class="stretch right-margined">
                        <div class="smaller ellipsis">${timeEntry.position!.name}</div>
                        <div class="flex wrapping layout center-aligning">
                            <span class="larger time-display"> ${toTimeString(start)} - ${toTimeString(end)} </span>
                        </div>
                        ${timeEntry.comment
                            ? html`
                                  <div class="smaller">
                                      <pre><i class="comment"></i> ${timeEntry.comment}</pre>
                                  </div>
                              `
                            : ""}
                    </div>
                </div>

                <div class="vertical spacing layout center-justifying double-padded">
                    <table>
                        <tr class="grey colored-text">
                            <td class=""><i class="stopwatch"></i> Gestempelt:</td>
                            <td class="text-right-aligning">
                                ${toTimeString(timeEntry.startFinal)} - ${toTimeString(timeEntry.endFinal)}
                            </td>
                        </tr>
                        <tr class="green colored-text">
                            <td class=""><i class="check"></i> Arbeitszeit:</td>
                            <td class="text-right-aligning">
                                ${toDurationString(timeEntry.durationFinal - (timeEntry.break || 0))}
                            </td>
                        </tr>
                        <tr class="orange colored-text">
                            <td><i class="coffee"></i> Pause:</td>
                            <td class="text-right-aligning">${toDurationString(timeEntry.break || 0)}</td>
                        </tr>
                    </table>
                </div>

                <input type="hidden" name="timeEntryId" value=${timeEntry.id} />
            </div>
        `;
    }

    renderContent() {
        if (!this._employee) {
            return html``;
        }

        return html`
            <div class="fullbleed vertical layout">
                <button class="double-margined absolute top right subtle icon" @click=${() => this.done()}>
                    <i class="times" style="line-height: 1.5em"></i>
                </button>
                <div class="stretch"></div>

                <div class="padded vertical layout" style="max-width: 35em; margin: 0 auto;">
                    <div class="vertical centering layout fit-vertically">
                        <ptc-avatar class="huge" .employee=${this._employee}></ptc-avatar>
                        <div class="large semibold vertically-margined">
                            ${this._employee.firstName} ${this._employee.lastName}
                        </div>
                    </div>
                    <div class="double-padded">
                        ${this._renderTimeEntry(this._entry)}
                        <div class="vertical layout">
                            <button class="primary" @click=${this.done}>Schließen</button>
                        </div>
                    </div>
                </div>
                <div class="stretch"></div>
            </div>
        `;
    }
}
