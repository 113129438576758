import { Marshaler, JSONMarshaler, serialize, deserialize } from "./encoding";
import { Err, ErrorCode } from "./error";

type ClassType<T> = {
    new (...args: unknown[]): T;
};

export abstract class Storage {
    constructor(private marshaler: Marshaler = new JSONMarshaler()) {}

    protected abstract _set(key: string, val: string): Promise<void>;
    protected abstract _get(key: string): Promise<string>;
    protected abstract _delete(key: string): Promise<void>;

    async set<T>(key: string, obj: T): Promise<void> {
        // @ts-ignore
        return this._set(`${obj.constructor.name}_${key}`, this.marshaler.marshal(serialize(obj)));
    }

    async get<T>(cl: ClassType<T>, key: string): Promise<T> {
        return deserialize(cl, this.marshaler.unmarshal(await this._get(`${cl.name}_${key}`)));
    }

    async delete<T>(cl: ClassType<T>, key: string): Promise<void> {
        return this._delete(`${cl.name}_${key}`);
    }

    abstract clear(): Promise<void>;
}

export class MemoryStorage extends Storage {
    private _storage = new Map<string, string>();

    async _set(key: string, obj: string) {
        this._storage.set(key, obj);
    }

    async _get(key: string) {
        if (!this._storage.has(key)) {
            throw new Err(ErrorCode.NOT_FOUND);
        }
        return this._storage.get(key)!;
    }

    async _delete(key: string) {
        this._storage.delete(key);
    }

    async clear() {
        this._storage = new Map<string, string>();
    }
}
