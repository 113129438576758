import { Employee, CompanySettings } from "./model";
import { Days, Factor, add, multiplyWithFactor } from "@pentacode/openapi";
import { getRange, dateSub } from "./util";
import { DateRange } from "./time";

export function calcVacationEntitlement(
    emp: Employee,
    { from, to }: DateRange,
    { startLedgers }: CompanySettings
): Days {
    const contracts = emp.contracts.filter((c) => c.start <= to && (!c.end || c.end > from));

    let total = 0 as Days;

    for (const contract of contracts) {
        let start = from >= contract.start ? from : contract.start;
        if (startLedgers && start < startLedgers) {
            start = startLedgers;
        }
        const end = !contract.end || contract.end > to ? to : contract.end;

        while (start < end) {
            const { from, to } = getRange(start, "month");
            const subEnd = to < end ? to : end;

            const fraction = (dateSub(start, subEnd) / dateSub(from, to) / 12) as Factor;

            total = add(total, multiplyWithFactor(contract.vacationDays, fraction));

            start = subEnd;
        }
    }

    return total;
}
