import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { shared } from "../styles";
import type { Popover } from "./popover";
import "./popover";
import { DateInput } from "./date-input";
import { dateAdd, dateSub, formatRange, getRange, inferRangeType, toDateString } from "@pentacode/core/src/util";
import { DateRange } from "packages/core/src/time";

@customElement("ptc-date-range-picker")
export class DateRangePicker extends LitElement {
    @property({ attribute: false })
    range: DateRange | null = null;

    @property({ attribute: false })
    rangeType: "year" | "month" | "week" | "day" | "custom";

    @property()
    display: "popover" | "inline" = "popover";

    @property({ type: Number })
    maxDays?: number;

    @state()
    private _rangeSuggestions: (DateRange & { label: string })[] = [];

    @query("ptc-date-input[name='from']")
    private _fromInput: DateInput;

    @query("ptc-date-input[name='to']")
    private _toInput: DateInput;

    @query("ptc-popover")
    private _popover: Popover;

    @query("select[name='rangeType'")
    private _rangeTypeSelect: HTMLSelectElement;

    @query("select[name='rangeSuggestions'")
    private _rangeSuggestionsSelect: HTMLSelectElement;

    private get _selectedRange(): DateRange | null {
        return this._fromInput?.value && this._toInput?.value
            ? {
                  from: this._fromInput.value,
                  to: dateAdd(this._toInput.value, { days: 1 }),
              }
            : null;
    }

    private get _rangeLabel() {
        if (!this.range) {
            return "Zeitraum Wählen...";
        }

        return this._formatRange(this.range, inferRangeType(this.range));
    }

    private get _presentRangeLabel() {
        switch (this.rangeType) {
            case "day":
                return "Heute";
            case "week":
                return "Aktuelle Woche";
            case "month":
                return "Aktueller Monat";
            case "year":
                return "Aktuelles Jahr";
            default:
                return "";
        }
    }

    private _getRangeSuggestions(rangeType: typeof this.rangeType = this.rangeType) {
        if (rangeType === "custom") {
            return [];
        }
        const rangeSuggestions: (DateRange & { label: string })[] = [];
        const date = toDateString(new Date());
        for (let i = -23; i < 4; i++) {
            const range = getRange(dateAdd(date, { [`${rangeType}s`]: i }), rangeType);
            rangeSuggestions.push({
                label: this._formatRange(range, rangeType),
                ...range,
            });
        }

        const currentRange = this._selectedRange;

        if (
            currentRange &&
            inferRangeType(currentRange) === rangeType &&
            !rangeSuggestions.some((sug) => sug.from === currentRange.from && sug.to === currentRange.to)
        ) {
            rangeSuggestions.push({
                label: this._formatRange(currentRange, rangeType),
                ...currentRange,
            });
        }

        return rangeSuggestions;
    }

    private _formatRange(range: DateRange, rangeType: typeof this.rangeType = this.rangeType) {
        return formatRange(range, rangeType);
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("range") && this.range) {
            this.rangeType = inferRangeType(this.range);
        }

        if (changes.has("rangeType")) {
            this._rangeSuggestions = this._getRangeSuggestions(this.rangeType);
        }
    }

    previousRange() {
        if (!this.range) {
            return;
        }

        switch (this.rangeType) {
            case "year":
                this._selectRange(getRange(dateAdd(this.range.from, { years: -1 }), "year"));
                break;
            case "month":
                this._selectRange(getRange(dateAdd(this.range.from, { months: -1 }), "month"));
                break;
            case "week":
                this._selectRange(getRange(dateAdd(this.range.from, { days: -7 }), "week"));
                break;
            default: {
                const days = dateSub(this.range.to, this.range.from);
                this._selectRange({
                    to: this.range.from,
                    from: dateAdd(this.range.from, { days }),
                });
            }
        }

        this._applyRange();
    }

    nextRange() {
        if (!this.range) {
            return;
        }

        switch (this.rangeType) {
            case "year":
                this._selectRange(getRange(dateAdd(this.range.from, { years: 1 }), "year"));
                break;
            case "month":
                this._selectRange(getRange(dateAdd(this.range.from, { months: 1 }), "month"));
                break;
            case "week":
                this._selectRange(getRange(dateAdd(this.range.from, { days: 7 }), "week"));
                break;
            default: {
                const days = dateSub(this.range.from, this.range.to);
                this._selectRange({
                    from: this.range.to,
                    to: dateAdd(this.range.to, { days }),
                });
            }
        }

        this._applyRange();
    }

    private _selectRange({ from, to }: DateRange) {
        this._fromInput.value = from;
        this._toInput.value = to ? dateAdd(to, { days: -1 }) : null;
        this.requestUpdate();
    }

    private _applyRange() {
        this.range = this._selectedRange;
        this.dispatchEvent(new CustomEvent("range-selected", { detail: this.range }));
        this._popover.hide();
    }

    private _rangeTypeSelected() {
        const rangeType = (this.rangeType = this._rangeTypeSelect.value as typeof this.rangeType);

        if (rangeType && rangeType !== "custom") {
            this._selectRange(getRange(this.range?.from || toDateString(new Date()), rangeType));
            this.requestUpdate();
        }
    }

    private _rangeSelected() {
        const range = this._rangeSuggestions[this._rangeSuggestionsSelect.selectedIndex];
        if (range) {
            this._selectRange(range);
        }
    }

    private _updateSuggestions() {
        this._rangeSuggestions = (this.rangeType && this._getRangeSuggestions(this.rangeType)) || [];
    }

    private _datePicked() {
        this.rangeType = this._selectedRange ? inferRangeType(this._selectedRange) : "custom";
        this.requestUpdate("range");
    }

    private async _reset() {
        if (!this._fromInput || !this._toInput) {
            await this.updateComplete;
        }

        this._fromInput.value = this.range?.from || null;
        this._toInput.value = this.range?.to ? dateAdd(this.range.to, { days: -1 }) : null;

        this.rangeType = (this.range && inferRangeType(this.range)) || "custom";
        this._updateSuggestions();

        this.requestUpdate();
    }

    static styles = [shared, DateInput.styles, css``];

    private _renderPickers() {
        const rangeSuggestions = this._rangeSuggestions;

        const range = this._selectedRange || this.range;
        const rangeType = this.rangeType;
        const maxDaysExceeded = this.maxDays && range && dateSub(range.from, range.to) > this.maxDays;

        return html`
            <div class="spacing vertical layout" style="min-width: 26em">
                <div class="small spacing horizontal layout">
                    <select
                        name="rangeType"
                        @change=${this._rangeTypeSelected}
                        .value=${this.rangeType}
                        style="flex: 1"
                    >
                        <option value="week" ?disabled=${!!this.maxDays && this.maxDays < 7}>Woche</option>
                        <option value="month" ?disabled=${!!this.maxDays && this.maxDays < 31}>Monat</option>
                        <option value="year" ?disabled=${!!this.maxDays && this.maxDays < 365}>Jahr</option>
                        <option value="custom">Beliebig</option>
                    </select>
                    <select
                        name="rangeSuggestions"
                        @change=${this._rangeSelected}
                        style="flex: 3"
                        ?hidden=${this.rangeType === "custom"}
                    >
                        ${rangeSuggestions.map(
                            (sug) => html`
                                <option ?selected=${range?.from === sug.from && range?.to === sug.to}>
                                    <i class="calendar-range"></i>
                                    ${sug.label}
                                </option>
                            `
                        )}
                    </select>
                    ${rangeType !== "custom"
                        ? html`
                              <button
                                  class="slim ghost"
                                  title="${this._presentRangeLabel}"
                                  @click=${() => this._selectRange(getRange(new Date(), rangeType))}
                              >
                                  <i class="calendar-clock"></i>
                              </button>
                          `
                        : ""}
                </div>

                <div class="stretching spacing horizontal layout">
                    <ptc-date-input
                        datePicker="inline"
                        name="from"
                        @input=${this._datePicked}
                        .displayRangeWith=${(range && dateAdd(range?.to, { days: -1 })) || undefined}
                        .max=${(range && dateAdd(range?.to, { days: -1 })) || undefined}
                    ></ptc-date-input>
                    <ptc-date-input
                        datePicker="inline"
                        name="to"
                        @input=${this._datePicked}
                        .displayRangeWith=${range?.from}
                        .min=${range?.from}
                    ></ptc-date-input>
                </div>

                <div class="top-padded">
                    ${maxDaysExceeded
                        ? html`
                              <div class="smaller bottom-margined orange padded box">
                                  Es kann maximal ein Zeitraum von ${this.maxDays} Tagen gewählt werden.
                              </div>
                          `
                        : ""}
                    <button
                        class="slim primary fill-horizontally"
                        @click=${this._applyRange}
                        ?disabled=${!this._fromInput?.value || !this._toInput?.value || maxDaysExceeded}
                    >
                        Anwenden
                    </button>
                </div>
            </div>
        `;
    }

    render() {
        return html`
            <div class="horizontal layout fill-horizontally">
                <button class="slim transparent noprint" @click=${this.previousRange} ?disabled=${!this.range}>
                    <i class="angle-left"></i>
                </button>
                <button class="slim transparent stretch"><i class="calendar-range"></i> ${this._rangeLabel}</button>
                ${this.display === "popover"
                    ? html`
                          <ptc-popover @popover-show=${this._reset} @popover-hide=${this._reset}>
                              ${this._renderPickers()}
                          </ptc-popover>
                      `
                    : ""}

                <button class="slim transparent noprint" @click=${this.nextRange} ?disabled=${!this.range}>
                    <i class="angle-right"></i>
                </button>
            </div>
            ${this.display !== "popover" ? html` <div>${this._renderPickers()}</div> ` : ""}
        `;
    }
}
