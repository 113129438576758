import { App } from "@pentacode/core/src/app";
import { AjaxSender } from "./lib/ajax";
import { LocalStorage } from "./lib/storage";
import { Router } from "./lib/router";
import { WebCryptoProvider } from "./lib/crypto";

import * as Sentry from "@sentry/browser";

Sentry.init({
    release: "v1.29.10",
    dsn: "https://dbc681fc0cc678ba1f86f99585d2eec1@o4507944962621440.ingest.de.sentry.io/4507945168994384",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/.*\.pentacode\.app/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    allowUrls: [/^https:\/\/(manage|admin|api|jobs|time2|perso)\.(pentacode\.app)/],
    ignoreErrors: [
        "Die Applikation konnte keine Verbindung zum Pentacode Server herstellen.",
        "Sie haben keine ausreichenden Berechtigungen für diese Aktion!",
        "Failed to execute 'define' on 'CustomElementRegistry': the name \"ptc-update-notification\" has already been used with this registry",
        "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",
        "IDBDatabase.transaction: Can't start a transaction on a closed database",
    ],
    beforeSend(event) {
        // here you can modify or filter events before they are sent to Sentry, see:  https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-before-send
        return event;
    },
    beforeSendTransaction(event) {
        // here you can modify or filter transaction events before they are sent to Sentry, see: https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-beforesendtransaction
        return event;
    },
    beforeSendSpan(span) {
        // here you can modify or filter spans before they are sent to Sentry, see: https://docs.sentry.io/platforms/javascript/configuration/filtering/#filtering-spans
        return span;
    },
});

export const app = (window.app = new App(
    new LocalStorage(),
    new AjaxSender(process.env.PTC_SERVER_URL!),
    new WebCryptoProvider()
));

export const router = (window.router = new Router());
